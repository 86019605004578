import { graphData, outputLine, sensor } from "../../interface/reducer/sensor";

const initalstate = {
  sensorList: [],
  selectedSensor: {},
  sensorIconList: [],
  sensorHistory: [],
  SensorSettingSuccessMessage: "",
  SensorSettingErrorMessage: "",
  outputLinesSetting: {},
  exportFileUrl: "",
  graphData: {
    alerts: [],
    data: [],
    y_axis_label: "",
    is_binary: false,
    unit: "",
    last_updated: "",
  },
  temperaturePreference: "C",
  sensorCurrentPage: 1,
  allSensorCount: 0,
};

interface updateSensorList {
  type: "UPDATE_SENSOR_LIST";
  payload: Array<any>;
}
interface updateSelectedSensor {
  type: "UPDATE_SELECTED_SENSOR";
  payload: sensor;
}
interface updateSensorIconList {
  type: "UPDATE_SENSOR_ICON_LIST";
  payload: Array<any>;
}

interface updateSensorSettingSuccessMessage {
  type: "UPDATE_SENSOR_SETTING_SUCCESS_MESSAGE";
  payload: string;
}
interface updateSensorSettingErrorMessage {
  type: "UPDATE_SENSOR_SETTING_ERROR_MESSAGE";
  payload: string;
}
interface updateSensorHistoryList {
  type: "UPDATE_SENSOR_HISTORY_LIST";
  payload: Array<any>;
}
interface updateSensorOutputLinesData {
  type: "UPDATE_SENSOR_OUTPUT_LINES_DATA";
  payload: outputLine;
}
interface updateExportFileUrl {
  type: "UPDATE_EXPORT_FILE_URL";
  payload: string;
}
interface updateGraphData {
  type: "UPDATE_GRAPH_DATA";
  payload: graphData;
}
interface updateTempraturePreference {
  type: "UPDATE_TEMPERATURE_PREFERENCE";
  payload: string;
}
interface updateSensorCurrentPage {
  type: "UPDATE_SENSOR_CURRENT_PAGE";
  payload: number;
}
interface updateAllSensorCount {
  type: "UPDATE_ALL_SENSOR_COUNT";
  payload: number;
}

type action =
  | updateSensorList
  | updateSelectedSensor
  | updateSensorIconList
  | updateSensorSettingSuccessMessage
  | updateSensorSettingErrorMessage
  | updateSensorHistoryList
  | updateSensorOutputLinesData
  | updateExportFileUrl
  | updateGraphData
  | updateTempraturePreference
  | updateSensorCurrentPage
  | updateAllSensorCount;

const SensorReducer = (state = initalstate, action: action) => {
  switch (action.type) {
    case "UPDATE_SENSOR_LIST": {
      return {
        ...state,
        sensorList: [...action.payload],
      };
    }
    case "UPDATE_SELECTED_SENSOR": {
      return {
        ...state,
        selectedSensor: {
          ...action.payload,
        },
      };
    }
    case "UPDATE_SENSOR_ICON_LIST": {
      return {
        ...state,
        sensorIconList: [...action.payload],
      };
    }
    case "UPDATE_SENSOR_SETTING_SUCCESS_MESSAGE": {
      return {
        ...state,
        SensorSettingSuccessMessage: action.payload,
      };
    }
    case "UPDATE_SENSOR_SETTING_ERROR_MESSAGE": {
      return {
        ...state,
        SensorSettingErrorMessage: action.payload,
      };
    }
    case "UPDATE_SENSOR_HISTORY_LIST": {
      return {
        ...state,
        sensorHistory: [...action.payload],
      };
    }
    case "UPDATE_SENSOR_OUTPUT_LINES_DATA": {
      return {
        ...state,
        outputLinesSetting: { ...action.payload },
      };
    }
    case "UPDATE_EXPORT_FILE_URL": {
      return {
        ...state,
        exportFileUrl: action.payload,
      };
    }
    case "UPDATE_GRAPH_DATA": {
      return {
        ...state,
        graphData: { ...action.payload },
      };
    }
    case "UPDATE_TEMPERATURE_PREFERENCE": {
      return {
        ...state,
        temperaturePreference: action.payload,
      };
    }
    case "UPDATE_SENSOR_CURRENT_PAGE": {
      return {
        ...state,
        sensorCurrentPage: action.payload,
      };
    }
    case "UPDATE_ALL_SENSOR_COUNT": {
      return {
        ...state,
        allSensorCount: action.payload,
      };
    }
    default:
      return state;
  }
};

export default SensorReducer;

export const UpdateSensorList = (sensorList: Array<any>): updateSensorList => {
  return {
    type: "UPDATE_SENSOR_LIST",
    payload: sensorList,
  };
};

export const UpdateSelectedSensor = (sensor: sensor): updateSelectedSensor => {
  return {
    type: "UPDATE_SELECTED_SENSOR",
    payload: sensor,
  };
};

export const UpdateSensorIconList = (
  sensorIconList: Array<any>
): updateSensorIconList => {
  return {
    type: "UPDATE_SENSOR_ICON_LIST",
    payload: sensorIconList,
  };
};

export const UpdateSensorSettingSuccessMessage = (
  SensorSettingSuccessMessage: string
): updateSensorSettingSuccessMessage => {
  return {
    type: "UPDATE_SENSOR_SETTING_SUCCESS_MESSAGE",
    payload: SensorSettingSuccessMessage,
  };
};
export const UpdateSensorSettingErrorMessage = (
  SensorSettingErrorMessage: string
): updateSensorSettingErrorMessage => {
  return {
    type: "UPDATE_SENSOR_SETTING_ERROR_MESSAGE",
    payload: SensorSettingErrorMessage,
  };
};
export const UpdateSensorHistoryList = (
  sensorHistory: Array<any>
): updateSensorHistoryList => {
  return {
    type: "UPDATE_SENSOR_HISTORY_LIST",
    payload: sensorHistory,
  };
};

export const UpdateSensorOutputLines = (
  outputLinesSetting: outputLine
): updateSensorOutputLinesData => {
  return {
    type: "UPDATE_SENSOR_OUTPUT_LINES_DATA",
    payload: outputLinesSetting,
  };
};
export const UpdateExportFileUrl = (
  exportFileUrl: string
): updateExportFileUrl => {
  return {
    type: "UPDATE_EXPORT_FILE_URL",
    payload: exportFileUrl,
  };
};
export const UpdateGraphData = (graphData: graphData): updateGraphData => {
  return {
    type: "UPDATE_GRAPH_DATA",
    payload: graphData,
  };
};
export const UpdateTemperaturePreference = (
  temperaturePreference: string
): updateTempraturePreference => {
  return {
    type: "UPDATE_TEMPERATURE_PREFERENCE",
    payload: temperaturePreference,
  };
};
export const UpdateSensorCurrentPage = (
  sensorCurrentPage: number
): updateSensorCurrentPage => {
  return {
    type: "UPDATE_SENSOR_CURRENT_PAGE",
    payload: sensorCurrentPage,
  };
};
export const UpdateAllSensorCount = (
  allSensorCount: number
): updateAllSensorCount => {
  return {
    type: "UPDATE_ALL_SENSOR_COUNT",
    payload: allSensorCount,
  };
};
