import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sensor } from "../../interface/reducer/sensor";
import {
  UpdateDrawerExpand,
  UpdateModalName,
  UpdateModalOpenStatus,
} from "../../redux/reducer/commonReducer";
import { UpdateSelectedSensor } from "../../redux/reducer/sensors";

export default function SensorDetailHeaderSection({ sensor }) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store: any) => store.user);

  const handleSensorDetailModal = (modalName: string, data: sensor) => {
    dispatch(UpdateDrawerExpand(false));
    dispatch(UpdateModalOpenStatus(true));
    dispatch(UpdateModalName(modalName));
    dispatch(UpdateSelectedSensor(data));
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex gap-5 items-center">
          <div className="sensorDetailIcon">
            <img src={sensor?.image_url} alt={sensor?.name} />
          </div>
          <div>
            <p className="sensorDetailTitle mb-2">{sensor?.name}</p>
            <p className="sensorCordinate">
              {sensor?.type} / {sensor?.esn}
            </p>
          </div>
        </div>
        {(userInfo.role === "Super Admin" || userInfo.role === "Admin") && (
          <div className="flex gap-3">
            <button
              className="primaryButtonFilled"
              onClick={() => handleSensorDetailModal("deleteSensor", sensor)}
            >
              Delete Sensor
            </button>
            <button
              className="primaryButtonFilled"
              onClick={() =>
                handleSensorDetailModal("deleteSensorData", sensor)
              }
            >
              Clear Sensor Data
            </button>
          </div>
        )}
      </div>
    </>
  );
}
