import { notification } from "../../interface/reducer/notification";

const initalstate = {
  notificationList: [],
  notificationUnreadCount: "",
};

interface updateNotificationList {
  type: "UPDATE_NOTIFICATION_LIST";
  payload: Array<notification>;
}
interface updateNotificationUnreadCount {
  type: "UPDATE_NOTIFICATION_UNREAD_COUNT";
  payload: string;
}

type action = updateNotificationList | updateNotificationUnreadCount;

const NotificationReducer = (state = initalstate, action: action) => {
  switch (action.type) {
    case "UPDATE_NOTIFICATION_LIST":
      return {
        ...state,
        notificationList: [...action.payload],
      };
    case "UPDATE_NOTIFICATION_UNREAD_COUNT":
      return {
        ...state,
        notificationUnreadCount: action.payload,
      };
    default:
      return state;
  }
};
export default NotificationReducer;

export const UpdateNotificationData = (
  notificationList: Array<notification>
): updateNotificationList => {
  return {
    type: "UPDATE_NOTIFICATION_LIST",
    payload: notificationList,
  };
};

export const UpdateNotificationUnreadCount = (
  notificationUnreadCount: string
): updateNotificationUnreadCount => {
  return {
    type: "UPDATE_NOTIFICATION_UNREAD_COUNT",
    payload: notificationUnreadCount,
  };
};
