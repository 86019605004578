// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerContainer {
  width: 100%;
  padding: 20px 20px;
  margin-top: auto;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* height: 100px; */
}
.copyright-text,
.privacy-policy-text p {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: var(--blue);
  text-align: center;
}

@media screen and (min-width: 768px) {
  .copyright-text,
  .privacy-policy-text p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}
@media screen and (min-width: 1024px) {
  .footerContainer {
    flex-direction: row;
    justify-content: space-between;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/footer/footer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,8BAA8B;EAC9B,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;AACA;;EAEE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE;;IAEE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;EACnB;AACF;AACA;EACE;IACE,mBAAmB;IACnB,8BAA8B;EAChC;AACF","sourcesContent":[".footerContainer {\n  width: 100%;\n  padding: 20px 20px;\n  margin-top: auto;\n  background-color: var(--white);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  /* height: 100px; */\n}\n.copyright-text,\n.privacy-policy-text p {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 14px;\n  color: var(--blue);\n  text-align: center;\n}\n\n@media screen and (min-width: 768px) {\n  .copyright-text,\n  .privacy-policy-text p {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 18px;\n  }\n}\n@media screen and (min-width: 1024px) {\n  .footerContainer {\n    flex-direction: row;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
