import React, { useEffect, useState } from "react";
import "../assets/css/dashboard.css";
import noRecordFound from "../assets/images/no-record-found.png";
import DeviceCard from "../components/dashboard/DeviceCard";
import useDevicesServiceHandler from "../hooks/serviceHandler/devices";
import { useDispatch, useSelector } from "react-redux";
import { device } from "../interface/reducer/device";
import { Link } from "react-router-dom";
import {
  UpdateModalName,
  UpdateModalOpenStatus,
} from "../redux/reducer/commonReducer";
import RegisterDeviceInputModal from "../components/common/modal/RegisterDeviceInputModal";
import ModalComponent from "../components/common/modal/Modal";
import MapView from "../components/dashboard/MapView";
import PageBreadcrumbs from "../components/common/PageBreadcrumbs";

export default function DevicesPage() {
  const dispatch = useDispatch();

  const { GetDevicesListServiceHandler } = useDevicesServiceHandler();
  const { devicesList } = useSelector((store: any) => store.device);
  const { modalIsOpen, modalName } = useSelector((store: any) => store.common);
  const { userInfo } = useSelector((store: any) => store.user);
  const [mapKey, setMapKey] = useState(1);

  const handleSensorDetailModal = (modalName: string) => {
    dispatch(UpdateModalName(modalName));
    dispatch(UpdateModalOpenStatus(true));
  };

  useEffect(() => {
    GetDevicesListServiceHandler();
  }, []);

  console.log('devicesList',devicesList)

  return (
    <>
      <div className="screenContainerPadding dashboardContainer">
        <div>
          <PageBreadcrumbs
            time={60}
            autoRefresh
            onRefresh={GetDevicesListServiceHandler}
          >
            <Link to="/">Dashboard</Link>
          </PageBreadcrumbs>

          <div className="allDeviceCardContainer cardBoxShadow">
            <div className="flex justify-between items-center mb-4">
              <p className="deviceCardHeading">Your Active devices</p>
              {userInfo.role === "Admin" && (
                <button
                  className="borderedButton"
                  onClick={() => handleSensorDetailModal("registerDevice")}
                >
                  Register Device
                </button>
              )}
            </div>
            {!devicesList?.filter((item: device) => item.active).length ? (
              <div className="no_record_found_image">
                <img src={noRecordFound} alt="no-record" />
              </div>
            ) : (
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 pb-5">
                {devicesList
                  ?.filter((item: device) => item.active)
                  ?.map((item: device, index: number) => {
                    return (
                      <div key={index}>
                        <DeviceCard data={item} />
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
        <div className="allDeviceCardContainer cardBoxShadow">
          <div className="flex justify-between items-center mb-4">
            <p className="deviceCardHeading">Device Locations</p>
            <button
              className="borderedButton"
              onClick={() => setMapKey(mapKey + 1)}
            >
              Reset
            </button>
          </div>
          <div style={{ height: "300px", width: "100%" }}>
            <MapView
              key={mapKey}
              deviceList={devicesList?.filter((item: device) => item.active)}
            />
          </div>
        </div>
      </div>

      {modalIsOpen && modalName === "registerDevice" ? (
        <ModalComponent heading="Register New Device">
          <RegisterDeviceInputModal />
        </ModalComponent>
      ) : (
        ""
      )}
    </>
  );
}
