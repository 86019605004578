import component from "../../constants/urls";
import { ResetUserInfo, UpdateIsLoginState } from "../../redux/reducer/user";

export function runLogoutTimer(dispatch: any, timer: any) {
  if (!isNaN(timer)) {
    setTimeout(() => {
      alert("Token Expired, Login again !");
      dispatch(UpdateIsLoginState(false));
      window.location.href = component.login.url;
      dispatch(ResetUserInfo());
    }, timer);
  }
}
