import { useDispatch, useSelector } from "react-redux";
import {
  ChangePasswordService,
  ForgetPasswordService,
  LoginService,
  LogoutService,
  RegisterUserService,
  UpdateUserProfileService,
  UserInfoService,
} from "../../service/Auth";
import {
  ResetChangePassword,
  ResetPasswordFormError,
  ResetUserInfo,
  UpdateIsLoginState,
  UpdateUserErrorMessage,
  UpdateUserInfo,
  UpdateUserSuccessMessage,
} from "../../redux/reducer/user";
import {
  ResetForgetPassowrdFormError,
  ResetForgetPasswordInputs,
  ResetLoginFormError,
  ResetLoginValue,
  ResetRegisterFormError,
  ResetRegisterValue,
} from "../../redux/reducer/auth";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import {
  ResetRegisterErrorMessage,
  UpdateChangePasswordErrorMessage,
  UpdateChangePasswordSuccessMessage,
  UpdateForgetPasswordErrorMessage,
  UpdateForgetPasswordSuccessMessage,
  UpdateIsLoadingState,
  UpdateLoginErrorMessage,
  UpdateLoginSuccessMessage,
  UpdateRegisterErrorMessage,
  UpdateRegisterSuccessMessage,
} from "../../redux/reducer/commonReducer";
import { UserData } from "../../interface/reducer/user";
import {
  ResetNewDeviceInfo,
  UpdateDeviceList,
} from "../../redux/reducer/devices";
import { ResetNewUserManagementInfo } from "../../redux/reducer/userManagement";
import { runLogoutTimer } from "../common/AuthService";
import { UpdateSensorList } from "../../redux/reducer/sensors";
import { useState } from "react";
import { UpdateLogsList } from "../../redux/reducer/logs";
import { setStorage } from "../../constants/constant";
import swal from "sweetalert";

const AUTH_LOCAL_STORAGE_KEY = "auth";

const HEADERS = {
  accept: "application/json",
  "content-type": "application/json",
};
const savedTokens = window.localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);

let tokens = savedTokens ? JSON.parse(savedTokens) : {};

const setTokens = (response: any) => {
  const { access_token: accessToken, refresh_token: refreshToken } = response;
  const expiresIn = new Date(
    Date.now() + Math.floor(response.expires_in * 1000 * 0.9)
  );
  tokens = { accessToken, refreshToken, expiresIn };
  window.localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(tokens));
  return accessToken;
};

const useAuthServiceHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, login, forgetPassword } = useSelector(
    (store: any) => store.auth
  );

  const { changePassword, userInfo } = useSelector((store: any) => store.user);

  const [isAuthenticated, setIsAuthenticated] = useState(
    typeof tokens.accessToken === "string" &&
      new Date(tokens.expiresIn) > new Date()
  );

  const RegisterUserServiceHandler = async () => {
    try {
      dispatch(UpdateIsLoadingState(true));
      const { name, email, password, phone, deviceId } = register;
      const res = await RegisterUserService({
        name,
        email,
        password,
        phone,
        deviceId,
      });
      const { result } = res?.data;
      navigate(component.dashboard.url);
      dispatch(UpdateIsLoadingState(false));
      if (result?.message) {
        dispatch(UpdateRegisterSuccessMessage(result?.message));
      } else {
        const { id, role, permissions, expires_in } = result;
        runLogoutTimer(dispatch, expires_in * 1000);
        const userData = {
          name,
          email,
          access_token: result?.access_token,
          id,
          role,
          permissions,
          customerId: "",
          phone,
          expires_in,
        };
        setStorage("token", userData?.access_token);
        dispatch(ResetRegisterFormError());
        dispatch(ResetRegisterValue());
        dispatch(UpdateUserInfo(userData));
        dispatch(UpdateIsLoginState(true));
      }
    } catch (error: any) {
      dispatch(
        UpdateRegisterErrorMessage(error?.response?.data?.error?.message)
      );
      setTimeout(() => {
        dispatch(ResetRegisterErrorMessage());
      }, 1000);
      dispatch(UpdateIsLoadingState(false));
    }
  };

  const LoginServiceHandler = async () => {
    try {
      dispatch(UpdateIsLoadingState(true));
      const { email, password } = login;
      const res = await LoginService({ email, password });
      const { result } = res?.data;
      dispatch(UpdateIsLoadingState(false));
      if (result?.message) {
        dispatch(UpdateLoginSuccessMessage(result?.message));
      } else {
        const {
          name,
          email,
          access_token,
          id,
          role,
          permissions,
          customerId,
          phone,
          expires_in,
        } = result;
        setStorage("token", access_token);
        runLogoutTimer(dispatch, expires_in * 1000);
        dispatch(
          UpdateUserInfo({
            name,
            email,
            access_token,
            id,
            role,
            permissions,
            customerId,
            phone,
            expires_in,
          })
        );
        dispatch(ResetLoginFormError());
        dispatch(ResetLoginValue());
        dispatch(UpdateIsLoginState(true));
        navigate(component.dashboard.url);
        dispatch(UpdateLoginSuccessMessage(""));
      }
    } catch (error: any) {
      dispatch(UpdateIsLoadingState(false));
      dispatch(UpdateLoginErrorMessage(error?.response?.data?.error?.message));
      setTimeout(() => {
        dispatch(UpdateLoginErrorMessage(""));
      }, 2000);
    }
  };
  const ForgetPasswordServiceHandler = async () => {
    try {
      // dispatch(UpdateIsLoadingState(true));
      const { email } = forgetPassword;
      const res = await ForgetPasswordService({ email });
      const { result, msg } = res?.data;
      // dispatch(UpdateIsLoadingState(false));
      if (msg) {
        dispatch(UpdateForgetPasswordSuccessMessage(msg));
        setTimeout(() => {
          dispatch(UpdateForgetPasswordSuccessMessage(""));
        }, 1000);
        dispatch(ResetForgetPassowrdFormError());
        dispatch(ResetForgetPasswordInputs());
      } else {
        dispatch(ResetForgetPassowrdFormError());
        dispatch(ResetForgetPasswordInputs());
        dispatch(UpdateForgetPasswordSuccessMessage(""));
      }
    } catch (error: any) {
      dispatch(
        UpdateForgetPasswordErrorMessage(error?.response?.data?.error?.message)
      );
      dispatch(ResetForgetPasswordInputs());
      setTimeout(() => {
        dispatch(UpdateForgetPasswordErrorMessage(""));
      }, 2000);
    }
  };

  const ChangePasswordServiceHandler = async () => {
    try {
      const { newPassword, currentPassword, confirmPassword } = changePassword;
      const res = await ChangePasswordService(userInfo?.id, {
        currentPassword,
        newPassword,
        confirmPassword,
      });
      const { result, msg } = res?.data;
      if (msg) {
        dispatch(UpdateChangePasswordSuccessMessage(msg));
        setTimeout(() => {
          dispatch(UpdateChangePasswordSuccessMessage(""));
          dispatch(ResetChangePassword());
          dispatch(ResetPasswordFormError());
        }, 1000);
      } else {
        dispatch(UpdateChangePasswordSuccessMessage(""));
        dispatch(ResetChangePassword());
        dispatch(ResetPasswordFormError());
      }
    } catch (error: any) {
      dispatch(
        UpdateChangePasswordErrorMessage(error?.response?.data?.error?.message)
      );
      setTimeout(() => {
        dispatch(UpdateChangePasswordErrorMessage(""));
        dispatch(ResetChangePassword());
      }, 1000);
    }
  };
  const GetUserInfoByIdServiceHandler = async () => {
    try {
      const res = await UserInfoService(userInfo?.id);
      const { result } = res.data;
      if (result) {
        const { id, name, email, role, phone, customerId } = result;
        const newUserInfo = {
          ...userInfo,
          id,
          name,
          email,
          role,
          phone,
          customerId,
        };
        dispatch(UpdateUserInfo({ ...newUserInfo }));
      }
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  const UpdateProfileServiceHandler = async (data: any) => {
    try {
      const res = await UpdateUserProfileService(userInfo?.id, data);
      const { result, msg } = res?.data;
      await GetUserInfoByIdServiceHandler();
      dispatch(UpdateUserSuccessMessage(msg));
      setTimeout(() => {
        // navigate(component.dashboard.url);
        dispatch(UpdateUserSuccessMessage(""));
      }, 1000);
    } catch (error: any) {
      dispatch(UpdateUserErrorMessage(error?.response?.data?.error?.message));
      setTimeout(() => {
        dispatch(UpdateUserErrorMessage(""));
      }, 1000);
    }
  };
  const LogoutServiceHandler = async () => {
    const data = {
      email: userInfo?.email,
    };
    try {
      await LogoutService(data);
      dispatch(UpdateIsLoginState(false));
      navigate(component.home.url);
      dispatch(ResetUserInfo());
      dispatch(UpdateDeviceList([]));
      dispatch(UpdateSensorList([]));
      dispatch(UpdateLogsList([]));
      dispatch(ResetNewUserManagementInfo());
      dispatch(ResetNewDeviceInfo());
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  return {
    RegisterUserServiceHandler,
    LoginServiceHandler,
    ForgetPasswordServiceHandler,
    ChangePasswordServiceHandler,
    GetUserInfoByIdServiceHandler,
    UpdateProfileServiceHandler,
    LogoutServiceHandler,
  };
};

export default useAuthServiceHandler;
