import axios from "axios";
import { UserManagement } from "../interface/services/User";
import { userManagement } from "../interface/reducer/userManagementList";
import { getAuthHeader } from "../constants/constant";

export const GetAllUserListService = async (id: string) => {
  const url = `${process.env.REACT_APP_URL}web/v1/roleUser/${id}`;
  return axios.get(url, getAuthHeader());
};
export const userManagementService = async (data: UserManagement) => {
  const url = `${process.env.REACT_APP_URL}web/v1/addUser`;
  return axios.post(url, data);
};
export const DeleteUserService = (userId: string, createdById: string) => {
  const url = `${process.env.REACT_APP_URL}v1/user/${userId}/${createdById}`;
  return axios.delete(url);
};

export const UpdateUserService = (user: userManagement) => {
  const url = `${process.env.REACT_APP_URL}v1/user/${user.id}`;
  console.log(url, user);
  
  return axios.patch(url, user);
};
