import React, { createContext, useContext, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/globalTheme.css";
import "./assets/css/style.css";
import Navigation from "./routes/Navigation";
import { useSelector } from "react-redux";
import { useLoadScript } from "@react-google-maps/api";
export const ThemeContext = createContext(null);
function App() {
  const { isLogin } = useSelector((store: any) => store.user);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCDZyV7pcOZONcSbgQYA8UTe7AY_Zid2po",
  });

  return (
    <ThemeContext.Provider value={isLoaded}>
      <div className="screen-width">
        <BrowserRouter>
          <Navigation />
        </BrowserRouter>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
