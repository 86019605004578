// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clipBoardContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.firmUploadCancelBtn {
    display: flex;
    align-items: center;
    gap: 20px;
}
.uploadImageContainer{
    padding: 20px;
    padding-left: 10px;
}
.uploadHeading {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding-bottom: 10px;
}

.uploadImageContainer {
    
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/firmware.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;;AAEA","sourcesContent":[".clipBoardContainer {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n}\n\n.firmUploadCancelBtn {\n    display: flex;\n    align-items: center;\n    gap: 20px;\n}\n.uploadImageContainer{\n    padding: 20px;\n    padding-left: 10px;\n}\n.uploadHeading {\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 20px;\n    padding-bottom: 10px;\n}\n\n.uploadImageContainer {\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
