import React, { useEffect, useState } from "react";
import SensorDetailHeaderSection from "./SensorDetailHeaderSection";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useSensorServiceHandler from "../../hooks/serviceHandler/sensor";
import {
  GetDeviceSensorDataByIdService,
  GetSensorGraphData,
} from "../../service/sensors";
import { sensor } from "../../interface/reducer/sensor";
import moment from "moment";
import SensorCurrentValueSection from "./SensorCurrentValueSection";
import SensorCardGraphData from "../cards/SensorCardGraphData";
import { ClipLoader } from "react-spinners";
import SensorSetting from "../cards/SensorSetting";
import { UpdateSelectedSensor } from "../../redux/reducer/sensors";
import SensorRawDataSection from "./SensorRawDataSection";

export default function SensorDetailPage() {
  const params = useParams();
  

  

  const [sensors, setSensors] = useState<sensor>();
  const [timeseriesData, setTimeseriesData] = React.useState<any>();
  const [offset, setOffset] = React.useState(sensors?.manual_offset);

  useEffect(() => {
    GetDeviceSensorDataByIdService(
      params?.deviceId,
      params?.sensorType,
      params?.sensorEsn
    )
      .then((res) => {
        const result = res.data.result;
        if (result) {
          setSensors({ ...result });
        }
      })
      .catch((error: any) => {
        console.log(error, "error");
      });
  }, []);

  useEffect(() => {
    // console.log('params', params?.deviceId);
    GetSensorGraphData(
      params?.deviceId,
      params?.sensorType,
      params?.sensorEsn
    ).then((res) => {
      const { result } = res?.data;
      if (result) {
        setTimeseriesData({...result});
      }
    });
  }, []);

  return (
    <div className="p-8">
      <div className="allDeviceCardContainer cardBoxShadow flex flex-col gap-10">
        {sensors && <SensorDetailHeaderSection sensor={sensors} />}
        {sensors && shouldShowCurrentValue(sensors) && (
          <SensorCurrentValueSection
            sensor={sensors}
            lastValue={sensors?.last_value}
            units={sensors?.units}
            time={moment(sensors?.updatedAt).format("YYYY-MM-DD hh:mm:ss")}
            offset={offset}
          />
        )}
        <div className="registerFormContainer">
          {timeseriesData ? (
            <>
              {shouldShowData(sensors) && (
                <SensorCardGraphData
                  deviceId={sensors?.device_id}
                  sensorType={sensors?.type}
                  sensorEsn={sensors?.esn}
                  sensor={sensors}
                  offset={sensors?.manual_offset}
                  timeSeries={timeseriesData}
                  graphOnCardHeading={false}
                  gpsLocation
                />
              )}
            </>
          ) : (
            <div className="w-full flex items-center justify-center">
              <ClipLoader
                color={"#2047ab"}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
        </div>
        <div className="registerFormContainer">
          {sensors && shouldShowData(sensors) && (
            <SensorRawDataSection
              sensor={sensors}
              deviceId={params?.deviceId}
              sensorType={sensors?.type}
              sensorEsn={sensors?.esn}
              offset={sensors?.manual_offset}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function shouldShowCurrentValue(sensor: sensor) {
  return sensor.type !== "31";
}

function shouldShowData(sensor: sensor) {
  return sensor.type !== "31";
}