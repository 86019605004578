import axios from "axios";
import { getAuthHeader } from "../constants/constant";

export const GetNotificationAllDataService = (userId: string) => {
  const url = `${process.env.REACT_APP_URL}v1/getAll/notifications/${userId}`;
  return axios.get(url, getAuthHeader());
};
export const DeleteNotificationService = (id: string) => {
  const url = `${process.env.REACT_APP_URL}v1/delete/notification/${id}`;
  return axios.delete(url, getAuthHeader());
};
export const ChangeNotificationStatusService = (id: string) => {
  const url = `${process.env.REACT_APP_URL}v1/update/notification/${id}`;
  return axios.patch(url, "", getAuthHeader());
};
