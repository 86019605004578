import { useDispatch, useSelector } from "react-redux";
import {
  ClearSensorDataService,
  DeleteSensorService,
  ExportSensorCSVDataService,
  GetDeviceSensorDataByIdService,
  GetDeviceSensorListService,
  GetOutputLineService,
  GetSensorGraphData,
  GetSensorHistoryData,
  UpdateOutputLineService,
  UpdateSensorDetailsSerivce,
  sensorIconListService,
} from "../../service/sensors";
import {
  UpdateAllSensorCount,
  UpdateExportFileUrl,
  UpdateGraphData,
  UpdateSelectedSensor,
  UpdateSensorHistoryList,
  UpdateSensorIconList,
  UpdateSensorList,
  UpdateSensorOutputLines,
} from "../../redux/reducer/sensors";
import {
  UpdateDrawerExpand,
  UpdateIsLoadingState,
  UpdateModalOpenStatus,
  UpdateRawSensorLoader,
} from "../../redux/reducer/commonReducer";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import { toast } from "react-toastify";
import swal from "sweetalert";

const useSensorServiceHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedDevice } = useSelector((state: any) => state.device);
  const { selectedSensor, outputLinesSetting, sensorCurrentPage } = useSelector(
    (state: any) => state.sensor
  );

  const GetDeviceSensorListServiceHandler = async (
    deviceId: string,
    page: number
  ) => {
    try {
      dispatch(UpdateIsLoadingState(true))
      const res = await GetDeviceSensorListService(deviceId, page);
      const { rows, count } = res?.data.result;
     
      if (count) {
        dispatch(UpdateAllSensorCount(count));
      }
      if (rows?.length) {
        dispatch(UpdateSensorList(rows));
      } else {
        dispatch(UpdateSensorList([]));
      }
      dispatch(UpdateIsLoadingState(false))
    } catch (error) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  const GetDeviceSensorDetailDataByIdServiceHandler = async (
    device_id: string,
    type: string,
    esn: string
  ) => {
    try {
      const res = await GetDeviceSensorDataByIdService(device_id, type, esn);
      const { result } = res.data;
      if (result) {
        dispatch(UpdateSelectedSensor(result));
      }
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  const UpdateSensorSettingServiceHandler = async (
    device_id: string,
    type: string,
    esn: string,
    sensorData: any
  ) => {
    try {
      await UpdateSensorDetailsSerivce(device_id, type, esn, sensorData);
      toast.success("Sensor Setting Updated Successfully !");

      GetDeviceSensorListServiceHandler(selectedDevice?.id, sensorCurrentPage);
      setTimeout(() => {
        dispatch(UpdateModalOpenStatus(false));
      }, 1000);
    } catch (error: any) {
      toast.success(error?.response?.data?.error?.message);
    }
  };

  const sensorIconListServiceHandler = async () => {
    try {
      const res = await sensorIconListService();
      const { result } = res?.data;
      if (result?.length) {
        dispatch(UpdateSensorIconList(result));
      } else {
        dispatch(UpdateSensorIconList([]));
      }
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  const SensorHistoryServiceHandler = async (
    deviceId: string,
    type: string,
    esn: string
  ) => {
    try {
      dispatch(UpdateRawSensorLoader(true));
      const res = await GetSensorHistoryData(deviceId, type, esn);
      dispatch(UpdateRawSensorLoader(false));
      const { result } = res?.data;
      if (result) {
        dispatch(UpdateSensorHistoryList(result));
      } else {
        dispatch(UpdateSensorHistoryList([]));
      }
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
      dispatch(UpdateRawSensorLoader(false));
    }
  };
  const GetOutputLineServiceHandler = async () => {
    try {
      // dispatch(UpdateIsLoadingState(true));
      const deviceId = selectedDevice?.id;
      const res = await GetOutputLineService(deviceId);
      const { result } = res.data;
      // dispatch(UpdateIsLoadingState(false));
      if (result) {
        dispatch(UpdateSensorOutputLines(result));
      } else {
        return {};
      }
    } catch (error: any) {
      // dispatch(UpdateIsLoadingState(false));
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };
  const UpdateOutputLineServiceHandler = async () => {
    try {
      // dispatch(UpdateIsLoadingState(true));
      const deviceId = selectedDevice?.id;
      const res = await UpdateOutputLineService(outputLinesSetting, deviceId);
      const { result } = res.data;
      // dispatch(UpdateIsLoadingState(false));
      if (result) {
        // dispatch(UpdateSensorOutputLines({ ...result }));
      }
    } catch (error: any) {
      // dispatch(UpdateIsLoadingState(false));
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  const ExportSensorCSVDataServiceHandler = async (
    sensorName: string,
    deviceId: string
  ) => {
    try {
      dispatch(UpdateIsLoadingState(true));
      const res = await ExportSensorCSVDataService(sensorName, deviceId);
      dispatch(UpdateIsLoadingState(false));
      const { result } = res?.data;
      console.log('result---->', result);
      
      if (result) {
        return result
      } else {
        return 
      }
    } catch (error: any) {
      dispatch(UpdateIsLoadingState(false));
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  const DeleteSensorServiceHandler = async (
    deviceId: string,
    type: string,
    esn: string
  ) => {
    try {
      // dispatch(UpdateIsLoadingState(true));
      await DeleteSensorService(deviceId, type, esn);
      navigate(component.sensorListing.url);
      dispatch(UpdateModalOpenStatus(false));
      dispatch(UpdateModalOpenStatus(false));
      // dispatch(UpdateIsLoadingState(false));
      await GetDeviceSensorListServiceHandler(deviceId, sensorCurrentPage);
    } catch (error: any) {
      // dispatch(UpdateIsLoadingState(false));
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };
  const GetSensorGraphDataServiceHandler = async (
    deviceId: string,
    type: string,
    esn: string
  ) => {
    try {
      const res = await GetSensorGraphData(deviceId, type, esn);
      const { result } = res?.data;
      if (result) {
        dispatch(UpdateGraphData(result));
      }
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };
  const DeleteSensorDataServiceHandler = async (
    deviceId: string,
    type: string,
    esn: string
  ) => {
    try {
      await ClearSensorDataService(deviceId, type, esn);
      navigate(component.sensorListing.url);
      dispatch(UpdateDrawerExpand(false));
      dispatch(UpdateModalOpenStatus(false));

      await GetDeviceSensorListServiceHandler(deviceId, sensorCurrentPage);
    } catch (error) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  return {
    GetDeviceSensorListServiceHandler,
    GetDeviceSensorDetailDataByIdServiceHandler,
    UpdateSensorSettingServiceHandler,
    sensorIconListServiceHandler,
    SensorHistoryServiceHandler,
    GetOutputLineServiceHandler,
    UpdateOutputLineServiceHandler,
    ExportSensorCSVDataServiceHandler,
    DeleteSensorServiceHandler,
    GetSensorGraphDataServiceHandler,
    DeleteSensorDataServiceHandler,
  };
};

export default useSensorServiceHandler;
