import React from "react";
import SensorRawDataSection from "../../sensor/SensorRawDataSection";
import { useDispatch, useSelector } from "react-redux";
import { sensor } from "../../../interface/reducer/sensor";
import { UpdateTemperaturePreference } from "../../../redux/reducer/sensors";

export default function SensorHistory() {
  const dispatch = useDispatch();
  const { selectedSensor, temperaturePreference } = useSelector(
    (store: any) => store.sensor
  );
  const { selectedDevice } = useSelector((store: any) => store.device);
  const [offset, setOffset] = React.useState(selectedSensor?.manual_offset);

  return (
    <div className="flex flex-col gap-10 p-8 drawerWidth">
      <div className="flex gap-5 items-center">
        <div className="sensorDetailIcon">
          <img src={selectedSensor?.image_url} alt={selectedSensor?.name} />
        </div>
        <div>
          <p className="sensorDetailTitle mb-2">{selectedSensor?.name}</p>
          <p className="sensorCordinate">
            {selectedSensor?.type} / {selectedSensor?.esn}
          </p>
        </div>
      </div>
      {/* <p className="deviceCardHeading">{selectedSensor?.name} History Data</p> */}
      <div>
        {selectedSensor?.type === "01" && (
          <div className="button_group">
            <button
              className={
                temperaturePreference === "F" ? "btn_active" : "btn_inactive"
              }
              onClick={() => dispatch(UpdateTemperaturePreference("F"))}
            >
              F&deg;
            </button>
            <button
              className={
                temperaturePreference === "C" ? "btn_active" : "btn_inactive"
              }
              onClick={() => dispatch(UpdateTemperaturePreference("C"))}
            >
              C&deg;
            </button>
          </div>
        )}
      </div>
      {shouldShowData(selectedSensor) && (
        <SensorRawDataSection
          sensor={selectedSensor}
          deviceId={selectedDevice?.id}
          sensorType={selectedSensor?.type}
          sensorEsn={selectedSensor?.esn}
          offset={offset}
        />
      )}
    </div>
  );
}
function shouldShowData(sensor: sensor) {
  return sensor.type !== "31";
}
