import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import Label from "../common/formInput/Label";
import { useDispatch, useSelector } from "react-redux";
import {
  ResetNewUserManagementErrorMessage,
  ResetNewUserManagementFormError,
  ResetNewUserManagementInfo,
  ResetNewUserManagementSucessMessage,
  UpdateAssignedDeviceUser,
  UpdateNewUserManagementInfo,
} from "../../redux/reducer/userManagement";
import useUserManagementValidation from "../../hooks/validations/UserManagement";
import useUserManagementServiceHandler from "../../hooks/serviceHandler/userManagement";
import { role } from "../../interface/reducer/role";
import SuccessMessage from "../common/alert/SuccessMessage";
import ErrorMessage from "../common/alert/ErrorMessage";
import useRoleServiceHandler from "../../hooks/serviceHandler/roles";
import { device } from "../../interface/reducer/device";
import AddUserCheckbox from "../common/formInput/AddUserCheckBox";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { store } from "../../redux/store";

export default function Add() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    OnChangeUserManagementFormValueValidation,
    UserManagementFormValidation,
  } = useUserManagementValidation();

  const { CreateUserServiceHandler, UpdateUserManagementServiceHandler } =
    useUserManagementServiceHandler();
  const { GetAllRoleListServiceHandler } = useRoleServiceHandler();

  const {
    newUser,
    newUserError,
    userManagementSuccessMessage,
    userManagementErrorMessage,
  } = useSelector((state: any) => state.userManagement);
  const { roleList } = useSelector((state: any) => state.role);
  

  const { devicesList } = useSelector((state: any) => state.device);
  const { userInfo } = useSelector((store: any) => store.user);
  const [unassignedDeviceList, setUnassignedDeviceList] = useState<any>([]);
  const [showSelectInput, setShowSelectInput] = useState(false);
  const [assignedDeviceName, setAssignedDeviceName] = useState<any>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleRoute = (url: string) => {
    navigate(url);
  };
  const handleChange = (
    key: "name" | "email" | "password" | "phone" | "role",
    value: string
  ) => {
    dispatch(UpdateNewUserManagementInfo({ key, value }));
    OnChangeUserManagementFormValueValidation({ id: key, value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const isValid = UserManagementFormValidation();
    if (isValid) {
      if (isEdit) {
        UpdateUserManagementServiceHandler();
      } else {
        CreateUserServiceHandler();
      }
    }
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    navigate(component.userListing.url);
    dispatch(ResetNewUserManagementSucessMessage());
    dispatch(ResetNewUserManagementErrorMessage());
    dispatch(ResetNewUserManagementFormError());
    dispatch(ResetNewUserManagementInfo());
  };

  const handleSelectedDevice = (deviceData: device) => {
    if (!newUser?.assignedDevices?.length) {
      dispatch(UpdateAssignedDeviceUser([deviceData?.id]));
      setAssignedDeviceName([deviceData?.name]);
    } else {
      const isPresent = newUser?.assignedDevices?.findIndex((item: any) => {
        return item === deviceData?.id;
      });
      if (isPresent !== -1) {
        const newAssignedDeviceList = newUser?.assignedDevices?.filter(
          (item: any) => item !== deviceData?.id
        );
        const newAssignedDeviceName = newAssignedDeviceList?.map(
          (deviceId: string) => {
            const index = devicesList?.findIndex(
              (device: device) => device?.id === deviceId
            );
            return devicesList[index]?.name;
          }
        );
        dispatch(UpdateAssignedDeviceUser([...newAssignedDeviceList]));
        setAssignedDeviceName([newAssignedDeviceName]);
      } else {
        dispatch(
          UpdateAssignedDeviceUser([
            ...newUser?.assignedDevices,
            deviceData?.id,
          ])
        );

        setAssignedDeviceName([...assignedDeviceName, deviceData?.name]);
      }
    }
  };

  const isChecked = (deviceId: any) => {
    if (!newUser?.assignedDevices?.length) {
      return false;
    } else {
      const index = newUser?.assignedDevices?.findIndex(
        (ele: any, index: number) => ele === deviceId
      );
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    }
  };
  const newAssignedDeviceList = () => {
    if (userInfo?.role === "Super Admin") {
      const newListData = devicesList?.filter(
        (item: device) => !item?.purchasedStatus && item?.assignAdmin === null
      );

      setUnassignedDeviceList([...newListData]);
    } else {
      const newListData = devicesList?.filter(
        (item: device) => item?.purchasedStatus
      );

      setUnassignedDeviceList([...newListData]);
    }
  };

  const setHideAssignedList = (e: any) => {
    e.stopPropagation();
    setShowSelectInput(false);
  };

  const toggleShowAssignedList = (e: any) => {
    e.stopPropagation();
    setShowSelectInput(!showSelectInput);
  };

  useEffect(() => {
    // GetAllRoleListServiceHandler();

    if (newUser?.id) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, []);

  useEffect(() => {
    const newAssignedDeviceName = newUser?.assignedDevices?.map(
      (deviceId: string) => {
        const index = devicesList?.findIndex(
          (device: device) => device?.id === deviceId
        );
        return devicesList[index]?.name;
      }
    );
    setAssignedDeviceName([newAssignedDeviceName]);
  }, []);

  useEffect(() => {
    newAssignedDeviceList();
  }, []);

  const notifiationData = [
    {
      label: "Push Notifiation",
      value: newUser?.sendNotification,
      id: "sendNotification",
    },
    {
      label: "Email Notification",
      value: newUser?.sendEmail,
      id: "sendEmail",
    },
    {
      label: "Text Notification",
      value: newUser?.sendSMS,
      id: "sendSMS",
    },
  ];
  // useEffect(() => {
  //   const unsubscribe = store.subscribe(() => {

  //   });
  //   dispatch(ResetNewUserManagementInfo());
  //   return unsubscribe;
  // }, []);
console.log('isEdit',isEdit,newUser?.role)
  return (
    <div className="screenContainerPadding" onClick={setHideAssignedList}>
      <p className="breadcrum">
        <span onClick={() => handleRoute(component.userListing.url)}>
          User listing
        </span>{" "}
        / <span>{isEdit ? "Edit User" : "Add user"}</span>
      </p>
      <div className="formContainerBorder">
        <div className="mb-2">
          {userManagementSuccessMessage ? (
            <SuccessMessage msg={userManagementSuccessMessage} />
          ) : userManagementErrorMessage ? (
            <ErrorMessage msg={userManagementErrorMessage} />
          ) : (
            ""
          )}
        </div>
        <form action="">
          <p className="formHeading">
            {isEdit ? "Update User" : " Add New User"}
          </p>
          <div className="grid gap-5">
            <div className="flex flex-col">
              <Label label=" Name" required={true} />
              <input
                className="registerFormInput"
                type="text"
                name="name"
                id="name"
                placeholder="Enter  Name"
                value={newUser?.name}
                onChange={(e) => handleChange("name", e.target.value)}
              />
              <p className="errorMessage">{newUserError?.name}</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div className="flex flex-col">
                <Label label="E-MAIL" required={true} />
                <input
                  className="registerFormInput"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Enter E-MAIL"
                  value={newUser?.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                <p className="errorMessage">{newUserError?.email}</p>
              </div>
              <div className="flex flex-col">
                <Label label="Password" required={newUser?.id ? false : true} />
                <div className="relative">
                  <input
                    className="registerFormInput"
                    type={showPassword ? "type" : "password"}
                    name="password"
                    id="password"
                    placeholder="Enter Password"
                    value={newUser?.password}
                    onChange={(e) => handleChange("password", e.target.value)}
                  />
                  <div
                    className="absolute top-0 right-0 flex justify-center items-center cursor-pointer"
                    style={{
                      height: "40px",
                      width: "40px",
                      border: "1px solid #ced4da",
                    }}
                  >
                    {!showPassword ? (
                      <BiSolidHide
                        fontSize={20}
                        color="#495057"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <BiSolidShow
                        fontSize={20}
                        color="#495057"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                </div>
                <p className="errorMessage">{newUserError.password}</p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div className="flex flex-col">
                <Label label="Phone Number" required={true} />
                <PhoneInput
                  country={"us"}
                  value={newUser?.phone}
                  onChange={(phone) => handleChange("phone", `+${phone}`)}
                  inputStyle={{
                    height: "40px",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: "400",
                    border: "1px solid #ced4da",
                    width: "100%",
                    borderRadius: "0",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                />
                <p className="errorMessage">{newUserError.phone}</p>
              </div>

              {/* <div className="flex flex-col">
                <Label label="Role" required={true} />
                {newUser?.role === "Admin" ? (
                  <select
                    name="role"
                    id="role"
                    className="registerFormSelectInput"
                    // value={newUser?.role}
                    onChange={(e) => handleChange("role", e.target.value)}
                    defaultValue={3}
                  >
                    <option value="">Select the Role</option>
                    {roleList?.map((item: role) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <select
                    name="role"
                    id="role"
                    className="registerFormSelectInput"
                    // value={newUser?.role}
                    onChange={(e) => handleChange("role", e.target.value)}
                    defaultValue={6}
                  >
                    <option value="">Select the Role</option>
                    {roleList?.map((item: role) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                )}
                <p className="errorMessage">{newUserError.role}</p>
              </div> */}
              {isEdit ? (
                <div className="flex flex-col">
                  <Label label="Role" required={true} />
                  {newUser?.role === "Admin" ? (
                    <select
                      name="role"
                      id="role"
                      className="registerFormSelectInput"
                      // value={newUser?.role}
                      onChange={(e) => handleChange("role", e.target.value)}
                      defaultValue={3}
                    >
                      <option value="">Select the Role</option>
                      {roleList?.map((item: role) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <select
                      name="role"
                      id="role"
                      className="registerFormSelectInput"
                      // value={newUser?.role}
                      onChange={(e) => handleChange("role", e.target.value)}
                      defaultValue={6}
                    >
                      <option value="">Select the Role</option>
                      {roleList?.map((item: role) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                  <p className="errorMessage">{newUserError.role}</p>
                </div>
              ) : (
                <div className="flex flex-col">
                  <Label label="Role" required={true} />
                  <select
                    name="role"
                    id="role"
                    className="registerFormSelectInput"
                    value={newUser?.role}
                    onChange={(e) => handleChange("role", e.target.value)}
                  >
                    <option value="">Select the Role</option>
                    {roleList?.map((item: role) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>

                  <p className="errorMessage">{newUserError.role}</p>
                </div>
              )}

              {userInfo?.role !== "Super Admin" && (
                <div className="flex flex-col">
                  <Label label="Assign Device" required={true} />
                  <div className="relative">
                    <input
                      type="text"
                      name=""
                      id=""
                      defaultValue={assignedDeviceName}
                      className="registerFormInput cursor-pointer"
                      placeholder="Select Devices"
                      onClick={toggleShowAssignedList}
                    />
                    {!showSelectInput ? (
                      <BsChevronDown className="downImage" fontSize={12} />
                    ) : (
                      <BsChevronUp className="downImage" fontSize={12} />
                    )}

                    {showSelectInput && (
                      <ul className="device_multi_select">
                        {unassignedDeviceList?.map((item: device) => {
                          return (
                            <li key={item?.id}>
                              <div>
                                <input
                                  type="checkbox"
                                  name=""
                                  value={assignedDeviceName}
                                  id={item.id}
                                  className="inputCheckbox"
                                  checked={isChecked(item?.id)}
                                  onChange={() => handleSelectedDevice(item)}
                                />
                                <label
                                  htmlFor={item.id}
                                  className="checkboxLabel"
                                >
                                  {item?.name ? item?.name : "Unnamed Device"}
                                </label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                  <p className="errorMessage">
                    {newUserError?.assignedDevices}
                  </p>
                </div>
              )}
            </div>
            <div>
              <Label label="Notification Preference Setting" required={true} />
              <div className="flex flex-col md:flex-row gap-2">
                {notifiationData?.map((item, index) => {
                  return (
                    <AddUserCheckbox
                      key={index}
                      label={item.label}
                      value={item.value}
                      id={item.id}
                    />
                  );
                })}
              </div>
              <p className="errorMessage">{newUserError?.sendAlert}</p>
            </div>

            <div className="flex gap-5 justify-end items-center">
              <button className="primaryButtonFilled" onClick={handleSubmit}>
                {isEdit ? "Save Changes" : "Add User"}
              </button>
              <button className="borderedButton" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
