import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import component from "../../constants/urls";
import { useNavigate } from "react-router-dom";
import {
  ResetChangePassword,
  ResetPasswordFormError,
  ResetProfileFormError,
  UpdateChangePassword,
  UpdateUserErrorMessage,
  UpdateUserProfileFormInput,
  UpdateUserSuccessMessage,
} from "../../redux/reducer/user";
import Label from "../common/formInput/Label";
import useUserValidation from "../../hooks/validations/User";
import useAuthServiceHandler from "../../hooks/serviceHandler/Auth";
import SuccessMessage from "../common/alert/SuccessMessage";
import ErrorMessage from "../common/alert/ErrorMessage";
import {
  UpdateChangePasswordErrorMessage,
  UpdateChangePasswordSuccessMessage,
} from "../../redux/reducer/commonReducer";
import PhoneInput from "react-phone-input-2";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";

export default function Account() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    OnChangeUserChangePasswordFormValueValidation,
    UserChangePasswordFormValidation,
    UserProfileFormValidation,
    OnChangeUserProfileFormValidation,
  } = useUserValidation();

  const { ChangePasswordServiceHandler, UpdateProfileServiceHandler } =
    useAuthServiceHandler();
  const [error, setError] = useState(false);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    phone: "",
    webhook_url: "",
  });
  const [validEmail, setValidEmail] = useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const {
    userInfo,
    changePassword,
    changePasswordFromError,
    userInfoFormError,
    updateUserErrorMessage,
    updateUserSuccessMessage,
  } = useSelector((store: any) => store.user);

  const { changePasswordSuccessMessage, changePasswordErrorMessage } =
    useSelector((store: any) => store.common);

  const validateEmail = (email: string) => {
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };
  const handleProfileDataChange = (name: string, value: any) => {
    // const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const saveProfile = (e: any) => {
    e.preventDefault();
    if (
      profileData?.name?.length === 0 ||
      profileData?.email?.length === 0 ||
      profileData?.phone?.length === 0
    ) {
      setError(true);
    } else {
      if (validEmail) {
        setError(false);
        UpdateProfileServiceHandler({ ...profileData });
      }
    }
  };

  const handleCancelUpdateUserProfile = () => {
    // navigate(component.dashboard.url);
    dispatch(ResetProfileFormError());
    dispatch(UpdateUserSuccessMessage(""));
    dispatch(UpdateUserErrorMessage(""));
  };
  const handleChange = (
    key: "currentPassword" | "newPassword" | "confirmPassword",
    value: string
  ) => {
    dispatch(UpdateChangePassword({ key, value }));
    OnChangeUserChangePasswordFormValueValidation({ id: key, value });
  };

  const handleChangePassword = (e: any) => {
    e.preventDefault();
    const isValid = UserChangePasswordFormValidation();
    if (isValid) {
      ChangePasswordServiceHandler();
    }
  };
  const cancelPasswordChange = (e: any) => {
    e.preventDefault();
    // navigate(component.dashboard.url);
    dispatch(ResetChangePassword());
    dispatch(ResetPasswordFormError());
    dispatch(UpdateChangePasswordErrorMessage(""));
    dispatch(UpdateChangePasswordSuccessMessage(""));
  };

  useEffect(() => {
    setProfileData({
      name: userInfo?.name,
      email: userInfo?.email,
      phone: userInfo?.phone,
      webhook_url: userInfo?.webhook_url,
    });
  }, []);

  return (
    <>
      <div className="screenContainerPadding">
        <p className="breadcrum">
          <span>Account</span>
        </p>
        <div className="registerFormContainer mb-5">
          <p className="formHeading">Edit Profile</p>
          <form action="">
            {(updateUserSuccessMessage || updateUserErrorMessage) && (
              <div className="mb-4">
                {updateUserSuccessMessage ? (
                  <SuccessMessage msg={updateUserSuccessMessage} />
                ) : updateUserErrorMessage ? (
                  <ErrorMessage msg={updateUserErrorMessage} />
                ) : (
                  ""
                )}
              </div>
            )}
            <div className="grid gap-5">
              <div className="flex flex-col">
                <Label label="Full Name" required={true} />
                <input
                  className="registerFormInput"
                  type="text"
                  name="name"
                  id="name"
                  value={profileData?.name}
                  placeholder="Enter Full Name"
                  onChange={(e: any) =>
                    handleProfileDataChange("name", e.target.value)
                  }
                />
                {error && !profileData?.name && (
                  <p className="errorMessage">Required !</p>
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div className="flex flex-col">
                  <Label label="Email" required={true} />
                  <input
                    className="registerFormInput"
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Enter Email"
                    value={profileData?.email}
                    onChange={(e: any) => {
                      handleProfileDataChange("email", e.target.value);
                      validateEmail(e.target.value);
                    }}
                  />
                  {error && profileData?.email?.length === 0 ? (
                    <p className="errorMessage">Required !</p>
                  ) : !validEmail ? (
                    <p className="errorMessage">Please enter a valid email.</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex flex-col">
                  <Label label="Contact Number" required={false} />
                  <PhoneInput
                    country={"us"}
                    value={profileData?.phone}
                    onChange={(phone) =>
                      handleProfileDataChange("phone", `+${phone}`)
                    }
                    inputStyle={{
                      height: "40px",
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontWeight: "400",
                      border: "1px solid #ced4da",
                      width: "100%",
                      borderRadius: "0",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  />
                  {error && !profileData?.phone && (
                    <p className="errorMessage">Required !</p>
                  )}
                </div>
                <div className="flex flex-col">
                  <Label label="Webhook URL" required={false} />
                  <input
                    className="registerFormInput"
                    type="text"
                    name="webhook_url"
                    id="webhook_url"
                    placeholder="Enter Webhook URL"
                    value={profileData?.webhook_url}
                    onChange={(e: any) =>
                      handleProfileDataChange("webhook_url", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="flex gap-5 justify-end items-center">
                <button className="primaryButtonFilled" onClick={saveProfile}>
                  Save Changes
                </button>
                <button
                  className="borderedButton"
                  onClick={handleCancelUpdateUserProfile}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="registerFormContainer mb-5">
          <p className="formHeading">Change Password</p>
          <form action="">
            <div className="mb-5">
              {changePasswordSuccessMessage ? (
                <SuccessMessage msg={changePasswordSuccessMessage} />
              ) : changePasswordErrorMessage ? (
                <ErrorMessage msg={changePasswordErrorMessage} />
              ) : (
                ""
              )}
            </div>
            <div className="grid gap-5">
              <div className="flex flex-col">
                <Label label="Current Password" required={true} />
                <div className="relative">
                  <input
                    className="registerFormInput"
                    type={showPassword ? "type" : "password"}
                    name="currentPassword"
                    id="currentPassword"
                    placeholder="Enter Current Password"
                    value={changePassword?.currentPassword}
                    onChange={(e) =>
                      handleChange("currentPassword", e.target.value)
                    }
                  />{" "}
                  <div
                    className="absolute top-0 right-0 flex justify-center items-center cursor-pointer"
                    style={{
                      height: "40px",
                      width: "40px",
                      border: "1px solid #ced4da",
                    }}
                  >
                    {!showPassword ? (
                      <BiSolidHide
                        fontSize={20}
                        color="#495057"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <BiSolidShow
                        fontSize={20}
                        color="#495057"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                </div>

                <p className="errorMessage mb-2">
                  {changePasswordFromError?.currentPassword}
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div className="flex flex-col">
                  <Label label="New Password" required={true} />
                  <div className="relative">
                    <input
                      className="registerFormInput"
                      type={showNewPassword ? "type" : "password"}
                      name="newPassword"
                      id="newPassword"
                      placeholder="Enter New Password"
                      value={changePassword?.newPassword}
                      onChange={(e) =>
                        handleChange("newPassword", e.target.value)
                      }
                    />
                    <div
                      className="absolute top-0 right-0 flex justify-center items-center cursor-pointer"
                      style={{
                        height: "40px",
                        width: "40px",
                        border: "1px solid #ced4da",
                      }}
                    >
                      {!showNewPassword ? (
                        <BiSolidHide
                          fontSize={20}
                          color="#495057"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        />
                      ) : (
                        <BiSolidShow
                          fontSize={20}
                          color="#495057"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        />
                      )}
                    </div>
                  </div>

                  <p className="errorMessage mb-2">
                    {changePasswordFromError?.newPassword}
                  </p>
                </div>
                <div className="flex flex-col">
                  <Label label="Confirm Password" required={true} />
                  <div className="relative">
                    <input
                      className="registerFormInput"
                      type={showConfirmPassword ? "type" : "password"}
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Enter New Password"
                      value={changePassword?.confirmPassword}
                      onChange={(e) =>
                        handleChange("confirmPassword", e.target.value)
                      }
                    />
                    <div
                      className="absolute top-0 right-0 flex justify-center items-center cursor-pointer"
                      style={{
                        height: "40px",
                        width: "40px",
                        border: "1px solid #ced4da",
                      }}
                    >
                      {!showConfirmPassword ? (
                        <BiSolidHide
                          fontSize={20}
                          color="#495057"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        />
                      ) : (
                        <BiSolidShow
                          fontSize={20}
                          color="#495057"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        />
                      )}
                    </div>
                  </div>

                  <p className="errorMessage mb-2">
                    {changePasswordFromError?.confirmPassword}
                  </p>
                </div>
              </div>
              <div className="flex gap-5 justify-end items-center">
                <button
                  className="primaryButtonFilled"
                  onClick={handleChangePassword}
                >
                  Change Password
                </button>
                <button
                  className="borderedButton"
                  onClick={cancelPasswordChange}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
