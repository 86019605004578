const initalstate = {
  firmwareData: {
    id: "",
    firmware_version: "",
  },
  firmwareList: {},
  firmwareSuccessMessage: "",
  firmwareErrorMessage: "",
  selectedFolder: {},
};

export interface firmwareData {
  key: "id" | "firmware_version";
  value: string;
}
interface updateFirmware {
  type: "UPDATE_FIRMWARE";
  payload: firmwareData;
}
interface getFirmwareData {
  type: "GET_FIRMWARE_DATA";
  payload: any;
}
interface updateFirmwareSuccessMessage {
  type: "UPDATE_FIRMWARE_SUCCESS_MESSAGE";
  payload: string;
}
interface updateFirmwareErrorMessage {
  type: "UPDATE_FIRMWARE_ERROR_MESSAGE";
  payload: string;
}
interface updateSelectedFolder {
  type: "UPDATE_SELECTED_FOLDER";
  payload: any;
}
type action =
  | updateFirmware
  | getFirmwareData
  | updateFirmwareSuccessMessage
  | updateFirmwareErrorMessage
  | updateSelectedFolder;

const FirmwareReducer = (state = initalstate, action: action) => {
  switch (action.type) {
    case "UPDATE_FIRMWARE":
      return {
        ...state,
        firmwareData: {
          ...state.firmwareData,
          [action.payload.key]: action.payload.value,
        },
      };
    case "GET_FIRMWARE_DATA": {
      return {
        ...state,
        firmwareList: {
          ...action.payload,
        },
      };
    }
    case "UPDATE_FIRMWARE_SUCCESS_MESSAGE": {
      return {
        ...state,
        firmwareSuccessMessage: action.payload,
      };
    }
    case "UPDATE_FIRMWARE_ERROR_MESSAGE": {
      return {
        ...state,
        firmwareErrorMessage: action.payload,
      };
    }
    case "UPDATE_SELECTED_FOLDER": {
      return {
        ...state,
        selectedFolder: {
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};
export default FirmwareReducer;

export const UpdateFirmwareData = (
  firmwareData: firmwareData
): updateFirmware => {
  return {
    type: "UPDATE_FIRMWARE",
    payload: firmwareData,
  };
};
export const UpdateFirmwareDataList = (firmwareList: any): getFirmwareData => {
  return {
    type: "GET_FIRMWARE_DATA",
    payload: firmwareList,
  };
};
export const UpdateFirmwareSuccessMessage = (
  firmwareSuccessMessage: string
): updateFirmwareSuccessMessage => {
  return {
    type: "UPDATE_FIRMWARE_SUCCESS_MESSAGE",
    payload: firmwareSuccessMessage,
  };
};
export const UpdateFirmwareErrorMessage = (
  firmwareErrorMessage: string
): updateFirmwareErrorMessage => {
  return {
    type: "UPDATE_FIRMWARE_ERROR_MESSAGE",
    payload: firmwareErrorMessage,
  };
};
export const UpdateSelectedFolder = (
  selectedFolder: any
): updateSelectedFolder => {
  return {
    type: "UPDATE_SELECTED_FOLDER",
    payload: selectedFolder,
  };
};
