import { InvitedUser, device } from "../../interface/reducer/device";

const initialState = {
  newDevice: {
    deviceId: "",
    model_definition: "",
    subscriptionMonthly: "",
    subscriptionYearly: "",
  },
  newDeviceError: {
    deviceId: "",
    model_definition: "",
    subscriptionMonthly: "",
    subscriptionYearly: "",
  },
  devicesList: [],
  archiveDeviceList: [],
  delegatedDeviceList: [],
  selectedDevice: {},
  selectedDeviceState: "all",
  invitedUserList: [],
  addDeviceSuccessMessage: "",
  addDeviceErrorMessage: "",
  deviceInfo: {},
  assignedDeviceInfo: {},
};

export interface newDeviceData {
  key:
    | "deviceId"
    | "model_definition"
    | "subscriptionMonthly"
    | "subscriptionYearly";
  value: string;
}

interface updateNewDeviceInfo {
  type: "UPDATE_NEW_DEVICE_INFO";
  payload: newDeviceData;
}

interface updateNewDeviceFormError {
  type: "UPDATE_NEW_DEVICE_FORM_ERROR";
  payload: newDeviceData;
}

interface resetNewDeviceInfo {
  type: "RESET_NEW_DEVICE_INFO";
}

interface resetNewDeviceFormError {
  type: "RESET_NEW_DEVICE_FORM_ERROR";
}

interface updateDeviceList {
  type: "UPDATE_DEVICE_LIST";
  payload: Array<any>;
}

interface updateDelegatedDeviceList {
  type: "UPDATE_DELEGATED_DEVICE_LIST";
  payload: Array<any>;
}

interface updateSelectedDevice {
  type: "UPDATE_SELECTED_DEVICE";
  payload: device;
}

interface updateArchiveList {
  type: "UPDATE_ARCHIVE_LIST";
  payload: Array<any>;
}

interface updateSelectedDeviceState {
  type: "SELECTED_DEVICE_STATE";
  payload: string;
}
interface updateInvitedUserList {
  type: "UPDATE_INVITED_USER_LIST";
  payload: Array<InvitedUser>;
}
interface updateAddDeviceSuccessMessage {
  type: "UPDATE_ADD_DEVICE_SUCCESS_MESSAGE";
  payload: string;
}
interface updateAddDeviceErrorMessage {
  type: "UPDATE_ADD_DEVICE_ERROR_MESSAGE";
  payload: string;
}
interface updateDeviceInfoById {
  type: "UPDATE_DEVICE_INFO_BY_ID";
  payload: device;
}
interface updateAssignedDeviceInfoData {
  type: "UPDATE_ASSIGNED_DEVICE_INFO_DATA";
  payload: any;
}

type action =
  | updateNewDeviceInfo
  | updateNewDeviceFormError
  | resetNewDeviceFormError
  | resetNewDeviceInfo
  | updateDeviceList
  | updateDelegatedDeviceList
  | updateSelectedDevice
  | updateArchiveList
  | updateSelectedDeviceState
  | updateInvitedUserList
  | updateAddDeviceSuccessMessage
  | updateAddDeviceErrorMessage
  | updateDeviceInfoById
  | updateAssignedDeviceInfoData;

const DeviceReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case "UPDATE_NEW_DEVICE_INFO": {
      return {
        ...state,
        newDevice: {
          ...state.newDevice,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case "RESET_NEW_DEVICE_INFO": {
      return {
        ...state,
        newDevice: {
          deviceId: "",
          model_definition: "",
          subscriptionMonthly: "",
          subscriptionYearly: "",
        },
      };
    }

    case "UPDATE_NEW_DEVICE_FORM_ERROR": {
      return {
        ...state,
        newDeviceError: {
          ...state.newDeviceError,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case "RESET_NEW_DEVICE_FORM_ERROR": {
      return {
        ...state,
        newDeviceError: {
          deviceId: "",
          model_definition: "",
          subscriptionMonthly: "",
          subscriptionYearly: "",
        },
      };
    }

    case "UPDATE_DEVICE_LIST": {
      return {
        ...state,
        devicesList: [...action.payload],
      };
    }

    case "UPDATE_DELEGATED_DEVICE_LIST": {
      return {
        ...state,
        delegatedDeviceList: [...action.payload],
      };
    }

    case "UPDATE_SELECTED_DEVICE": {
      return {
        ...state,
        selectedDevice: {
          ...action.payload,
        },
      };
    }

    case "UPDATE_ARCHIVE_LIST": {
      return {
        ...state,
        archiveDeviceList: [...action.payload],
      };
    }

    case "SELECTED_DEVICE_STATE": {
      return {
        ...state,
        selectedDeviceState: action.payload,
      };
    }
    case "UPDATE_INVITED_USER_LIST": {
      return {
        ...state,
        invitedUserList: [...action.payload],
      };
    }
    case "UPDATE_ADD_DEVICE_SUCCESS_MESSAGE": {
      return {
        ...state,
        addDeviceSuccessMessage: action.payload,
      };
    }
    case "UPDATE_ADD_DEVICE_ERROR_MESSAGE": {
      return {
        ...state,
        addDeviceErrorMessage: action.payload,
      };
    }
    case "UPDATE_DEVICE_INFO_BY_ID": {
      return {
        ...state,
        deviceInfo: { ...action.payload },
      };
    }
    case "UPDATE_ASSIGNED_DEVICE_INFO_DATA": {
      return {
        ...state,
        assignedDeviceInfo: { ...action.payload },
      };
    }

    default:
      return state;
  }
};

export default DeviceReducer;

export const UpdateNewDeviceInfo = (
  data: newDeviceData
): updateNewDeviceInfo => {
  return {
    type: "UPDATE_NEW_DEVICE_INFO",
    payload: data,
  };
};

export const ResetNewDeviceInfo = () => {
  return {
    type: "RESET_NEW_DEVICE_INFO",
  };
};

export const UpdateNewDeviceFormError = (
  data: newDeviceData
): updateNewDeviceFormError => {
  return {
    type: "UPDATE_NEW_DEVICE_FORM_ERROR",
    payload: data,
  };
};

export const ResetUpdateNewDeviceFormError = (): resetNewDeviceFormError => {
  return {
    type: "RESET_NEW_DEVICE_FORM_ERROR",
  };
};

export const UpdateDeviceList = (deviceList: Array<any>): updateDeviceList => {
  return {
    type: "UPDATE_DEVICE_LIST",
    payload: deviceList,
  };
};

export const UpdateDelegatedDeviceList = (
  data: Array<any>
): updateDelegatedDeviceList => {
  return {
    type: "UPDATE_DELEGATED_DEVICE_LIST",
    payload: data,
  };
};

export const UpdateSelectedDevice = (device: device): updateSelectedDevice => {
  return {
    type: "UPDATE_SELECTED_DEVICE",
    payload: device,
  };
};

export const UpdateArchiveDeviceList = (
  archiveDeviceList: Array<any>
): updateArchiveList => {
  return {
    type: "UPDATE_ARCHIVE_LIST",
    payload: archiveDeviceList,
  };
};

export const UpdateSelectedDeviceState = (
  state: string
): updateSelectedDeviceState => {
  return {
    type: "SELECTED_DEVICE_STATE",
    payload: state,
  };
};

export const UpdateInvitedUserList = (
  list: Array<InvitedUser>
): updateInvitedUserList => {
  return {
    type: "UPDATE_INVITED_USER_LIST",
    payload: list,
  };
};

export const UpdateAddDeviceSuccessMessage = (
  addDeviceSuccessMessage: string
): updateAddDeviceSuccessMessage => {
  return {
    type: "UPDATE_ADD_DEVICE_SUCCESS_MESSAGE",
    payload: addDeviceSuccessMessage,
  };
};
export const UpdateAddDeviceErrorMessage = (
  addDeviceErrorMessage: string
): updateAddDeviceErrorMessage => {
  return {
    type: "UPDATE_ADD_DEVICE_ERROR_MESSAGE",
    payload: addDeviceErrorMessage,
  };
};
export const UpdateDeviceInfoById = (
  deviceInfo: device
): updateDeviceInfoById => {
  return {
    type: "UPDATE_DEVICE_INFO_BY_ID",
    payload: deviceInfo,
  };
};
export const UpdateAssignedDeviceInfo = (
  assignedDeviceInfo: any
): updateAssignedDeviceInfoData => {
  return {
    type: "UPDATE_ASSIGNED_DEVICE_INFO_DATA",
    payload: assignedDeviceInfo,
  };
};
