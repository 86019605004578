import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import React from "react";

export default function SensorEnabledSetting({
  enabled,
  armed,
  onEnabledChange = (arg1: any, arg2: any) => {},
  onArmedChange = (arg1: any, arg2: any) => {},
}) {
  return (
    <FormGroup row style={{ userSelect: "none" }}>
      <FormControlLabel
        style={{ minWidth: "200px" }}
        control={
          <Switch
            checked={enabled}
            onChange={onEnabledChange}
            name="checkedB"
            color="primary"
          />
        }
        label={
          enabled ? (
            <>
              Sensor is <b>Enabled</b>
            </>
          ) : (
            <>
              Sensor is <b>Disabled</b>
            </>
          )
        }
      />
      <FormControlLabel
        disabled={!enabled}
        control={
          <Switch
            checked={armed}
            onChange={onArmedChange}
            name="checkedB"
            color="primary"
          />
        }
        label={
          armed ? (
            <>
              Sensor is <b>Armed</b>
            </>
          ) : (
            <>
              Sensor is <b>Disarmed</b>
            </>
          )
        }
      />
    </FormGroup>
  );
}
