import { userManagement } from "../../interface/reducer/userManagementList";

const initialState = {
  newUser: {
    name: "",
    email: "",
    password: "",
    role: "",
    phone: "",
    createdBy: "",
    sendNotification: true,
    sendEmail: true,
    sendSMS: true,
    assignedDevices: [],
  },
  newUserError: {
    name: "",
    email: "",
    password: "",
    role: "",
    phone: "",
    createdBy: "",
    sendAlert: "",
    assignedDevices: "",
  },
  userManagementList: [],
  userManagementSuccessMessage: "",
  userManagementErrorMessage: "",
};
interface updateAssignedDeviceUser {
  type: "UPDATE_ASSIGNED_DEVICE_USER";
  payload: Array<string>;
}

export interface newUserManagementData {
  key:
    | "name"
    | "email"
    | "password"
    | "role"
    | "createdBy"
    | "phone"
    | "sendNotification"
    | "sendEmail"
    | "sendSMS"
    | "assignedDevices"
    | "sendAlert";
  value: string | boolean;
}

interface updateUserManagementInfo {
  type: "UPDATE_NEW_USER_MANAGEMENT_INFO";
  payload: newUserManagementData;
}

interface resetUserManagementInfo {
  type: "RESET_USER_MANAGEMENT_INFO";
}

interface updateUserList {
  type: "UPDATE_USER_MANEGEMENT_LIST";
  payload: Array<any>;
}
interface updateUserManagementFormError {
  type: "UPDATE_USER_MANAGEMENT_FORM_ERROR";
  payload: newUserManagementData;
}
interface resetUserManagementFormError {
  type: "RESET_USER_MANAGEMENT_FORM_ERROR";
}

interface updateNewUserSuccessMessage {
  type: "UPDATE_NEW_USER_MANAGEMENT_SUCCESS_MESSAGE";
  payload: string;
}
interface resetNewUserManagementSuccessMessage {
  type: "RESET_NEW_USER_MANAGEMENT_SUCCESS_MESSAGE";
}

interface updateNewUserManagementErrorMessage {
  type: "UPDATE_NEW_USER_MANAGEMENT_ERROR_MESSAGE";
  payload: string;
}

interface resetNewUserManagementErrorMessage {
  type: "RESET_NEW_USER_MANAGEMENT_ERROR_MESSAGE";
}

interface editNewUserManagementInfo {
  type: "EDIT_NEW_USER_MANAGEMENT_INFO";
  payload: newUserManagementData;
}
interface resetUpdatedUserManagement {
  type: "RESET_UPDATED_USER_MANAGEMENT";
}

type action =
  | updateUserManagementInfo
  | resetUserManagementInfo
  | updateUserList
  | updateUserManagementFormError
  | resetUserManagementFormError
  | updateNewUserSuccessMessage
  | resetNewUserManagementSuccessMessage
  | updateNewUserManagementErrorMessage
  | resetNewUserManagementErrorMessage
  | updateAssignedDeviceUser
  | editNewUserManagementInfo
  | resetUpdatedUserManagement;

const UserManagement = (state = initialState, action: action) => {
  switch (action.type) {
    case "UPDATE_NEW_USER_MANAGEMENT_INFO": {
      return {
        ...state,
        newUser: {
          ...state.newUser,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case "RESET_USER_MANAGEMENT_INFO": {
      return {
        ...state,
        newUser: {
          name: "",
          email: "",
          password: "",
          role: "",
          phone: "",
          createdBy: "",
          sendNotification: false,
          sendEmail: false,
          sendSMS: false,
          assignedDevices: [],
        },
      };
    }
    case "UPDATE_USER_MANEGEMENT_LIST": {
      return {
        ...state,
        userManagementList: [...action.payload],
      };
    }
    case "UPDATE_USER_MANAGEMENT_FORM_ERROR": {
      return {
        ...state,
        newUserError: {
          ...state.newUserError,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case "RESET_USER_MANAGEMENT_FORM_ERROR": {
      return {
        ...state,
        newUserError: {
          name: "",
          email: "",
          password: "",
          role: "",
          phone: "",
          createdBy: "",
          sendAlert: "",
          assignedDevices: "",
        },
      };
    }
    case "UPDATE_NEW_USER_MANAGEMENT_SUCCESS_MESSAGE": {
      return {
        ...state,
        userManagementSuccessMessage: action.payload,
      };
    }

    case "RESET_NEW_USER_MANAGEMENT_SUCCESS_MESSAGE": {
      return {
        ...state,
        userManagementSuccessMessage: "",
      };
    }
    case "UPDATE_NEW_USER_MANAGEMENT_ERROR_MESSAGE": {
      return {
        ...state,
        userManagementErrorMessage: action.payload,
      };
    }

    case "RESET_NEW_USER_MANAGEMENT_ERROR_MESSAGE": {
      return {
        ...state,
        userManagementErrorMessage: "",
      };
    }
    case "UPDATE_ASSIGNED_DEVICE_USER": {
      return {
        ...state,
        newUser: {
          ...state.newUser,
          assignedDevices: [...action.payload],
        },
      };
    }
    case "EDIT_NEW_USER_MANAGEMENT_INFO": {
      return {
        ...state,
        newUser: {
          ...action.payload,
        },
      };
    }
    case "RESET_UPDATED_USER_MANAGEMENT": {
      return {
        ...state,
        newUser: {
          name: "",
          email: "",
          password: "",
          role: "",
          phone: "",
          createdBy: "",
          sendNotification: false,
          sendEmail: false,
          sendSMS: false,
          assignedDevices: [],
          id: "",
        },
      };
    }
    default:
      return state;
  }
};
export default UserManagement;

export const UpdateNewUserManagementInfo = (
  data: newUserManagementData
): updateUserManagementInfo => {
  return {
    type: "UPDATE_NEW_USER_MANAGEMENT_INFO",
    payload: data,
  };
};
export const ResetNewUserManagementInfo = (): resetUserManagementInfo => {
  return {
    type: "RESET_USER_MANAGEMENT_INFO",
  };
};
export const UpdateUserList = (
  userManagementList: Array<any>
): updateUserList => {
  return {
    type: "UPDATE_USER_MANEGEMENT_LIST",
    payload: userManagementList,
  };
};
export const UpdateNewUserManagementFormError = (
  data: newUserManagementData
): updateUserManagementFormError => {
  return {
    type: "UPDATE_USER_MANAGEMENT_FORM_ERROR",
    payload: data,
  };
};

export const ResetNewUserManagementFormError =
  (): resetUserManagementFormError => {
    return {
      type: "RESET_USER_MANAGEMENT_FORM_ERROR",
    };
  };

export const UpdateNewUserManagementSuccessMessage = (
  userManagementSuccessMessage: string
): updateNewUserSuccessMessage => {
  return {
    type: "UPDATE_NEW_USER_MANAGEMENT_SUCCESS_MESSAGE",
    payload: userManagementSuccessMessage,
  };
};

export const ResetNewUserManagementSucessMessage =
  (): resetNewUserManagementSuccessMessage => {
    return {
      type: "RESET_NEW_USER_MANAGEMENT_SUCCESS_MESSAGE",
    };
  };

export const UpdateNewUserManagementErrorMessage = (
  userManagementErrorMessage: string
): updateNewUserManagementErrorMessage => {
  return {
    type: "UPDATE_NEW_USER_MANAGEMENT_ERROR_MESSAGE",
    payload: userManagementErrorMessage,
  };
};

export const ResetNewUserManagementErrorMessage =
  (): resetNewUserManagementErrorMessage => {
    return {
      type: "RESET_NEW_USER_MANAGEMENT_ERROR_MESSAGE",
    };
  };

export const UpdateAssignedDeviceUser = (
  assignedDevices: Array<string>
): updateAssignedDeviceUser => {
  return {
    type: "UPDATE_ASSIGNED_DEVICE_USER",
    payload: assignedDevices,
  };
};
export const UpdateEditNewUserManagementInfo = (data: userManagement) => {
  return {
    type: "EDIT_NEW_USER_MANAGEMENT_INFO",
    payload: data,
  };
};
export const ResetUpdatedUserManagement = (): resetUpdatedUserManagement => {
  return {
    type: "RESET_UPDATED_USER_MANAGEMENT",
  };
};
