import React from "react";
import { useSelector } from "react-redux";

export default function AssignedDevice() {
  const { assignedDeviceInfo } = useSelector((store: any) => store.device);

  return (
    <>
      <div className="flex gap-4 items-center">
        <div className="assignedDeviceImageContainer">
          <img
            src={
              assignedDeviceInfo?.deviceInfo?.image?.length
                ? assignedDeviceInfo?.deviceInfo?.image
                : "https://cabinmanage.com/images/app-icons/device-type-tower-tech.png"
            }
            alt="device"
            className="w-full h-full"
          />
        </div>
        <div className="">
          <div>
            <p className="assigned_device_name">
              {assignedDeviceInfo?.deviceInfo?.name
                ? assignedDeviceInfo?.deviceInfo?.name
                : "NA"}
            </p>
            <p className="assigned_model_name">
              {assignedDeviceInfo?.deviceInfo?.model_name
                ? assignedDeviceInfo?.deviceInfo?.model_name
                : "NA"}
            </p>
          </div>
        </div>
      </div>
      <div className="my-3">
        <p className="assignedDetails ">
          Assigned to:{" "}
          <span style={{ color: "#2047AB", textTransform: "uppercase" }}>
            {assignedDeviceInfo?.adminInfo?.role
              ? assignedDeviceInfo?.adminInfo?.role
              : "NA"}
          </span>
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <p className="assignedDetails underline">Details</p>
        <div className="flex flex-col">
          <p className="assignedTitle" style={{ backgroundColor: "#ecedf9 " }}>
            Name :{" "}
            <span>
              {assignedDeviceInfo?.adminInfo?.name
                ? assignedDeviceInfo?.adminInfo?.name
                : "NA"}
            </span>
          </p>
          <p className="assignedTitle">
            E-Mail :{" "}
            <span>
              {assignedDeviceInfo?.adminInfo?.email
                ? assignedDeviceInfo?.adminInfo?.email
                : "NA"}
            </span>
          </p>
          <p className="assignedTitle" style={{ backgroundColor: "#ecedf9 " }}>
            Phone Number :{" "}
            <span>
              {assignedDeviceInfo?.adminInfo?.phone
                ? assignedDeviceInfo?.adminInfo?.phone
                : "NA"}
            </span>
          </p>
          <p className="assignedTitle">
            Purchase Type : <span>NA</span>
          </p>{" "}
          <p className="assignedTitle" style={{ backgroundColor: "#ecedf9 " }}>
            Purchased Date : <span>NA</span>
          </p>
          <p className="assignedTitle">
            Staus :{" "}
            <span
              style={{
                color: assignedDeviceInfo?.deviceInfo?.active
                  ? "#3eeb2f"
                  : "#ff3333",
              }}
            >
              {assignedDeviceInfo?.deviceInfo?.active ? "Active" : "Inactive"}
            </span>
          </p>
          <p className="assignedTitle" style={{ backgroundColor: "#ecedf9 " }}>
            Assigned Sub-Users :{" "}
            <span>
              {assignedDeviceInfo?.assignUser?.length
                ? assignedDeviceInfo?.assignUser?.length
                : "NA"}
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
