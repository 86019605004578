import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { ClipLoader } from "react-spinners";
import Pagination from "react-js-pagination";
import useHistory from "../../../hooks/serviceHandler/History";
import Label from "../formInput/Label";
import { useSelector } from "react-redux";

const SearchFilter = [
  {
    id: "1",
    key: "",
    value: "all",
  },
  {
    id: "2",
    key: "oneDay",
    value: "Today",
  },
  {
    id: "3",
    key: "oneWeek",
    value: "This Week",
  },
  {
    id: "4",
    key: "oneMonth",
    value: "This Month",
  },
  {
    id: "5",
    key: "oneYear",
    value: "This Year",
  },
];

interface Column {
  id: "Device Name" | "Sensor" | "Date" | "Alert" | "Value" | "Description";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "Device Name", label: "Device Name", align: "right" },
  { id: "Sensor", label: " Sensor", align: "right" },
  { id: "Date", label: "Date", align: "right" },
  { id: "Alert", label: "Alert", align: "right" },
  { id: "Value", label: "Value", align: "right" },
  { id: "Description", label: "Description", align: "right" },
];

export function translateRawData(data: any) {
  if (typeof data === "object" && data != null) {
    return `${data?.lat?.toFixed(4)}° ${data?.lng?.toFixed(4)}°`;
  } else if (typeof data === "number") {
    return data.toFixed(2);
  } else if (typeof data === "boolean") {
    return data ? "Closed" : "Open";
  } else {
    return data;
  }
}
export default function AllHistoryData() {
  const { getAllHistory } = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [historyList, setHistoryList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const [search, setSearch] = React.useState("all");
  const { userInfo } = useSelector((store: any) => store.user);

  const onChangeTypeFilter = async (value: string) => {
    setSearch(value);
    await historyData(currentPage, value);
  };

  const handlePageChange = async (pageNumber: number) => {

    
    setCurrentPage(pageNumber);
    await historyData(pageNumber, search);
  };

  const historyData = async (currentPage : number, search: string) => {
    setIsLoading(true);
    const data = await getAllHistory(currentPage, search);
    
    setIsLoading(false);
    if (data) {
      if(data.rows) {
        setHistoryList(data.rows);
       
        
        setCount(data?.count);
      } else {
        setHistoryList(data);
        setCount(data?.count);
  
      }
    }
  };

  React.useEffect(() => {
    historyData(currentPage, search);
  }, []);
 

  return (
    <>
      <div className="registerFormContainer">
        <div className="flex flex-col md:flex-row items-stretch justify-between md:items-center mb-5">
          <p className="formHeading mb-0">History</p>
          <div className="flex justify-end gap-5">
            <div>
              <Label label="Select Log Type" required={false} />
              <select
                value={search}
                className="registerFormSelectInput"
                onChange={(e) => onChangeTypeFilter(e.target.value)}
              >
                {SearchFilter?.map((item, index) => {
                  return (
                    <option value={item.key} key={index}>
                      {item.value}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="w-full flex items-center justify-center">
            <ClipLoader
              color={"#2047ab"}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : historyList?.length ? (
          <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
            <TableContainer sx={{ minHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        // align={column.align}
                        style={{
                          backgroundColor: "#2047AB",
                          borderBottom: "none",
                        }}
                      >
                        <p className="tableText" style={{ color: "#ffffff" }}>
                          {column.label}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyList?.map((row: any, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <p
                            className="userTableData mb-2"
                            style={{ color: "#37DF1C" }}
                          >
                            {row?.deviceName
                              ? row?.deviceName
                              : "Unnamed Device"}
                          </p>
                          <p className="userTableData"> {row?.device_id}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="userTableData">{row?.sensorName}</p>
                          <p
                            className="cordinates mt-1"
                            style={{ fontFamily: "'Montserrat', sans-serif" }}
                          >{`${row.type}/${row.sensor_esn}`}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="userTableData">
                            {moment(row?.updatedAt).format(
                              "YYYY-MM-DD hh:mm:ss"
                            )}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p
                            className="userTableData"
                            style={{
                              color: row?.is_alert ? "#d0342c" : "",
                            }}
                          >
                            {row?.is_alert ? "Alert" : "Normal"}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <div className="flex justify-between items-center gap-2">
                            <p className="userTableData">
                              {" "}
                              {translateRawData(row?.data[0])}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <p
                            className="userTableData"
                            style={{
                              color: "#FF3737",
                              textTransform: "capitalize",
                            }}
                          >
                            {row?.description}
                          </p>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="mt-5">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={100}
                totalItemsCount={count}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </Paper>
        ) : (
          <p className="sensorName text-center mt-6">No Data Found</p>
        )}
      </div>
    </>
  );
}

export function mapLevel(row: any) {
  switch (row?.level) {
    case "H":
      return "High";
    case "L":
      return "Low";
    case "N":
    default:
      return "Normal";
  }
}
