import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { BsTrash, BsFillExclamationTriangleFill } from "react-icons/bs";
import { BiSolidEdit } from "react-icons/bi";
import useRoleServiceHandler from "../../../hooks/serviceHandler/roles";
import { role } from "../../../interface/reducer/role";
import component from "../../../constants/urls";
import { useNavigate } from "react-router-dom";
import {
  UpdateEditRoleInfo,
  UpdateNewRoleInfo,
} from "../../../redux/reducer/role";
import { IconButton, Tooltip } from "@mui/material";

interface Column {
  id: "S.No." | "Role type" | "Description" | "Action";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "S.No.", label: "S.No.", align: "right" },
  { id: "Role type", label: "Role type", align: "right" },
  { id: "Description", label: "Description", align: "right" },
  { id: "Action", label: "Action", align: "right" },
];

export default function RoleListingTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { GetAllRoleListServiceHandler, DeleteRoleServiceHandler } =
    useRoleServiceHandler();
  const { roleList } = useSelector((state: any) => state.role);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    GetAllRoleListServiceHandler();
  }, []);

  const handleEditBtnClick = (role: role) => {
    dispatch(UpdateEditRoleInfo({ ...role }));
    navigate(component.createRole.url);
  };
  const deleteRole = (id: string) => {
    DeleteRoleServiceHandler(id);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      <TableContainer sx={{ minHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  // align={column.align}
                  style={{ backgroundColor: "#2047AB", borderBottom: "none" }}
                >
                  <p className="tableText" style={{ color: "#ffffff" }}>
                    {column.label}
                  </p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {roleList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: role, index: number) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="left">
                      <p className="userTableData">{index + 1}</p>
                    </TableCell>
                    <TableCell align="left">
                      <p
                        className="userTableData"
                        style={{ textTransform: "capitalize" }}
                      >
                        {" "}
                        {row.name}
                      </p>
                    </TableCell>
                    <TableCell align="left">
                      <p className="userTableData">{row.description}</p>
                    </TableCell>
                    <TableCell align="left">
                      <div className="flex justify-start items-center gap-3">
                        <Tooltip title="Edit">
                          <IconButton onClick={() => handleEditBtnClick(row)}>
                            <BiSolidEdit
                              fontSize={20}
                              color="#4D535A"
                              className="cursor-pointer"
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton onClick={() => deleteRole(row.id)}>
                            <BsTrash
                              fontSize={20}
                              color="#ff0d0b"
                              className="cursor-pointer"
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={roleList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
