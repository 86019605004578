import React, { useCallback, useContext, useEffect } from "react";
import { GoogleMap } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { MarkerF } from "@react-google-maps/api";
import { device } from "../../interface/reducer/device";
import { useNavigate } from "react-router-dom";
import { UpdateSelectedDevice } from "../../redux/reducer/devices";
import component from "../../constants/urls";
import { ThemeContext } from "../../App";
import { getActivityState } from "../../constants/utils";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  .map-label {
    color: rgb(0 0 0 / 70%) !important;
    margin-top: -50px;
    font-size: 8px !important;
    background: white !important;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
    padding: 4px;
    white-space: pre;
    width: auto;
    // max-width: 120px;
    font-family: "Montserrat", sans-serif;
    text-overflow: ellipses;
    overflow: hidden;
    border-radius:4px
  }

  .map-label:first-line {
    color: rgb(0 0 0 / 85%) !important;
    font-size: 8px !important;
    font-family: "Montserrat", sans-serif;
  }
`;

const containerStyle = {
  width: "100%",
  height: "100%",
};

function DashboardMapView({ deviceList }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((store: any) => store.user);
  const isLoaded = useContext(ThemeContext);
  const [devicesWithCoords, setDevicesWithCoords] = useState([]);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [map, setMap] = useState(null);
  const [dragged, setDragged] = useState(false);

  const handleMapClick = (item: device) => {
    dispatch(UpdateSelectedDevice(item));
    if (item.purchasedStatus) {
      navigate(component.sensorListing.url);
    } else {
      if (userInfo?.role === "Admin") {
        navigate(component.registerDevice.url);
      } else {
        navigate(component.sensorListing.url);
      }
    }
  };

  useEffect(() => {
    if (!map || !deviceList) return;
    const devicesWithCoords = parseLocations(deviceList);
    const bounds = computeBounds(window.google.maps, devicesWithCoords);
    if (bounds === null) return;
    if (!dragged) {
      map.fitBounds(bounds, 70);
      setCenter(bounds.getCenter());
    }
    setDevicesWithCoords(devicesWithCoords);
  }, [deviceList, map, dragged]);

  const onMapLoad = useCallback((map) => setMap(map), []);

  return isLoaded ? (
    <>
      <GlobalStyles />
      <GoogleMap
        mapContainerStyle={containerStyle}
        onDrag={() => setDragged(true)}
        center={center}
        zoom={15}
        onLoad={onMapLoad}
      >
        {devicesWithCoords.map((device) => (
          <MarkerF
            onClick={() => handleMapClick(device)}
            icon={{
              url: getIcon(device),
            }}
            label={{
              text: `${device?.name || "Unknown"}\n${device?.id}`,
              className: "map-label",
            }}
            key={device?.id}
            position={device?.gps}
          />
        ))}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default DashboardMapView;

function computeBounds(maps, devicesWithCoords) {
  if (!maps || devicesWithCoords.length === 0) return null;
  return devicesWithCoords.reduce((bounds, device) => {
    return bounds.union(new maps.LatLngBounds(device.gps));
  }, new maps.LatLngBounds(devicesWithCoords[0].gps));
}

function parseLocations(devices: any) {
  return devices
    .filter((d: any) => "gps" in d)
    .filter((d: any) => typeof d.gps === "string")
    .filter((d: any) => d.gps.trim().length > 0)
    .map((d: any) => [d, parseGPS(d.gps)])
    .filter(([, gps]) => gps !== null)
    .map(([d, gps]) => ({ ...d, gps }));
}

function parseGPS(gps: any) {
  const parts = gps.split(" ");
  if (parts.length < 2) return null;
  const lat = parseFloat(parts[0]);
  const lng = parseFloat(parts[1]);
  if (Number.isNaN(lat) || Number.isNaN(lng)) return null;
  return { lat, lng };
}

function getIcon(device: any) {
  if (device.alerting) return alertingIcon;
  else if (device.active) return activeIcon;
  else if (!device.active) return inActiveIcon;
  // switch (
  //   getActivityState({
  //     alerting: device.alerting,
  //     armed: device.armed,
  //     lastMessageReceived: device.last_message_received,
  //   })
  // ) {
  //   case "alerting":
  //     return alertingIcon;
  //   case "armed":
  //     return armedIcon;
  //   case "ok":
  //     return okIcon;
  //   case "warning":
  //     return warningIcon;
  //   default:
  //     return defaultIcon;
  // }
}

function makeIcon(color: any) {
  const svg = `<?xml version="1.0" encoding="UTF-8"?>
  <svg fill="${color}" stroke="rgb(10 10 10)" stroke-width="3px" width="24px" height="24px" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
   <path d="m252.91 16.777c-86.523 0-156.67 70.129-156.67 156.69 0 19.508 3.7383 38.102 10.254 55.363h-0.027344s0.070312 0.19922 0.18359 0.59766l121.29 244.6c0.10156 0.027343 0.16406 0.066406 0.27734 0.09375 2.9219 9.6602 11.812 16.73 22.445 16.73 10.516 0 19.312-6.9258 22.328-16.445 0.25-0.11328 0.44922-0.23438 0.70312-0.34766l125.55-244.62c6.6562-17.363 10.355-36.23 10.355-55.965 0-86.555-70.152-156.69-156.69-156.69zm0 183.08c-25.387 0-45.973-20.57-45.973-45.977 0-25.391 20.586-45.973 45.973-45.973 25.398 0 45.988 20.586 45.988 45.973 0 25.41-20.59 45.977-45.988 45.977z"/>
  </svg>`;
  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

const alertingIcon = makeIcon("#FF3737");
const activeIcon = makeIcon("#3eeb2f");
const inActiveIcon = makeIcon("#cda210");
const okIcon = makeIcon("rgb(0 154 191)");
const warningIcon = makeIcon("#ffaf00");
const defaultIcon = makeIcon("rgb(210, 210, 210)");
