import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import noRecordFound from "../../../assets/images/no-record-found.png";
import { BsTrash, BsFillExclamationTriangleFill } from "react-icons/bs";
import { BiSolidEdit } from "react-icons/bi";
import unarchiveIcon from "../../../assets/images/icons/unarchiveIcon.svg";
import archiveIcon from "../../../assets/images/icons/archiveIcon.svg";
import useDevicesServiceHandler from "../../../hooks/serviceHandler/devices";
import { device } from "../../../interface/reducer/device";
import { userInfo } from "os";
import component from "../../../constants/urls";
import { Navigate, useNavigate } from "react-router-dom";
import { UpdateSelectedDevice } from "../../../redux/reducer/devices";

interface Column {
  id:
    | "Device Id"
    | "Model"
    | "Name"
    | "Status"
    | "Purchased Status"
    | "Action"
    | "Battery";
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "Device Id", label: "Device Id", align: "right" },
  { id: "Model", label: " Model", align: "right" },
  { id: "Name", label: "Name", align: "right" },
  { id: "Battery", label: "Battery", align: "right" },
  { id: "Purchased Status", label: "Purchased Status", align: "right" },
  { id: "Status", label: "Status", align: "right" },
  { id: "Action", label: "Action", align: "right", maxWidth: 150 },
];
const userColumns: readonly Column[] = [
  { id: "Device Id", label: "Device Id", align: "right" },
  { id: "Model", label: " Model", align: "right" },
  { id: "Name", label: "Name", align: "right" },
  { id: "Battery", label: "Battery", align: "right" },
  { id: "Status", label: "Status", align: "right" },
];

export default function ManageAllDevice({ deviceList, setDeviceList }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    GetDevicesListServiceHandler,
    // GetConnectedDeviceListServiceHandler,
    ChangeDeviceActiveStatusSeriveHandler,
    ChangeDeviceArchiveStatusServiceHandler,
  } = useDevicesServiceHandler();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { devicesList, delegatedDeviceList, selectedDevice } = useSelector(
    (store: any) => store.device
  );
  const { userInfo } = useSelector((store: any) => store.user);

  const handleRoute = (url: string) => {
    navigate(url);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    GetDevicesListServiceHandler();
    // GetConnectedDeviceListServiceHandler();
  }, []);

  const AllDeviceList = [...delegatedDeviceList, ...devicesList];

  const handleChangeDeviceStatus = async (item: device) => {
    await ChangeDeviceActiveStatusSeriveHandler(item.id);
    const newList = await GetDevicesListServiceHandler();
    setDeviceList([...newList]);
  };

  const handleChangeDeviceArchiveStatus = async (item: device) => {
    await ChangeDeviceArchiveStatusServiceHandler(item.id);
    const newList = await GetDevicesListServiceHandler();
    setDeviceList([...newList]);
  };

  const handleDeviceSensorList = (item: device) => {
    dispatch(UpdateSelectedDevice(item));
    if (item.purchasedStatus) {
      navigate(component.sensorListing.url);
    } else {
      if (userInfo?.role === "Admin") {
        navigate(component.registerDevice.url);
      } else {
        navigate(component.sensorListing.url);
      }
    }
  };

  

  return (
    <>
      {deviceList.length ? (
        <>
          {" "}
          <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
            <TableContainer sx={{ minHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {(userInfo.role === "Super Admin" ||
                      userInfo.role === "Admin") &&
                      columns.map((column) => (
                        <TableCell
                          key={column.id}
                          style={{
                            backgroundColor: "#2047AB",
                            borderBottom: "none",
                          }}
                        >
                          <p className="tableText" style={{ color: "#ffffff" }}>
                            {column.label}
                          </p>
                        </TableCell>
                      ))}
                    {userInfo.role === "User" &&
                      userColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          style={{
                            backgroundColor: "#2047AB",
                            borderBottom: "none",
                          }}
                        >
                          <p className="tableText" style={{ color: "#ffffff" }}>
                            {column.label}
                          </p>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deviceList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row: device, index: number) => {
                      return (
                        <TableRow key={index}>
                          {/* <TableCell align="left">
                            <p className="userTableData">{index + 1}</p>
                          </TableCell> */}
                          <TableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeviceSensorList(row)}
                          >
                            <p className="userTableData">{row?.id}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p className="userTableData">
                              {row?.model_name ? row.model_name : "NA"}
                            </p>
                          </TableCell>
                          <TableCell align="left">
                            <p className="userTableData">
                              {row?.name ? row.name : "NA"}
                            </p>
                          </TableCell>
                          <TableCell align="left">
                            <p className="userTableData">{`${
                              !row?.battery ? "0" : row?.battery
                            }%`}</p>
                          </TableCell>
                          {(userInfo.role === "Super Admin" ||
                            userInfo.role === "Admin") && (
                            <TableCell align="left" style={{ width: "200px" }}>
                              <p className="userTableData">
                                {row.purchasedStatus ? "True" : "False"}
                              </p>
                            </TableCell>
                          )}
                          <TableCell align="left">
                            <p
                              className="userTableData"
                              style={{
                                color: row.active ? "#3eeb2f" : "#d0342c",
                              }}
                            >
                              {row.active ? "Active" : "Inactive"}
                            </p>
                          </TableCell>
                          {(userInfo.role === "Super Admin" ||
                            userInfo.role === "Admin") && (
                            <TableCell align="left" style={{ width: "100px" }}>
                              <div className="flex justify-between items-center">
                                {/* <BiSolidEdit
                          fontSize={20}
                          color="#767676"
                          className="cursor-pointer"
                          onClick={() =>
                            handleRoute(component.registerDevice.url)
                          }
                        /> */}
                                {row.active ? (
                                  <BsFillExclamationTriangleFill
                                    fontSize={20}
                                    color="#3eeb2f"
                                    className="cursor-pointer"
                                    onClick={() =>
                                      handleChangeDeviceStatus(row)
                                    }
                                  />
                                ) : (
                                  <BsFillExclamationTriangleFill
                                    fontSize={20}
                                    color="#ed9418"
                                    className="cursor-pointer"
                                    onClick={() =>
                                      handleChangeDeviceStatus(row)
                                    }
                                  />
                                )}
                                {row.status === "ARCHIVED" ? (
                                  <img
                                    src={archiveIcon}
                                    alt="unarchive"
                                    className="iconSize"
                                    onClick={() =>
                                      handleChangeDeviceArchiveStatus(row)
                                    }
                                  />
                                ) : (
                                  <img
                                    src={unarchiveIcon}
                                    alt="unarchive"
                                    className="iconSize"
                                    onClick={() =>
                                      handleChangeDeviceArchiveStatus(row)
                                    }
                                  />
                                )}
                                {/* <BsTrash fontSize={20} color="#ff0d0b" /> */}
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={deviceList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <>
          <div className="no_record_found_image">
            <img src={noRecordFound} alt="" />
          </div>
        </>
      )}
    </>
  );
}
