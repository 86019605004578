import React from "react";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import Label from "../common/formInput/Label";
import { useDispatch, useSelector } from "react-redux";
import doneAnimation from "../../assets/animation/done.json";
import {
  ResetNewDeviceInfo,
  ResetUpdateNewDeviceFormError,
  UpdateNewDeviceInfo,
  newDeviceData,
} from "../../redux/reducer/devices";
import useRegisterFormValidation from "../../hooks/validations/Register";
import useNewDeviceRegisterFormValidation from "../../hooks/validations/RegisterDevice";
import useDevicesServiceHandler from "../../hooks/serviceHandler/devices";
import PaymentModal from "../common/modal/PaymentModal";
import ModalComponent from "../common/modal/Modal";
import {
  UpdateModalName,
  UpdateModalOpenStatus,
} from "../../redux/reducer/commonReducer";
import { useEffect, useState } from "react";
import { GetAllPriceListService } from "../../service/Stripe";
import useStripeServiceHandler from "../../hooks/serviceHandler/stripe";
import { priceData } from "../../interface/reducer/payment";
import {
  UpdatePaymentErrorMessage,
  UpdatePaymentSuccessMessage,
  UpdateSelectedPriceData,
} from "../../redux/reducer/stripe";
import ErrorMessage from "../common/alert/ErrorMessage";
import SuccessMessage from "../common/alert/SuccessMessage";
import { ClipLoader } from "react-spinners";

export default function RegisterDeviceAdmin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { CreateDeviceSubscriptionServiceHandler } = useStripeServiceHandler();

  const { newDevice, selectedDevice } = useSelector(
    (store: any) => store.device
  );
  const {
    priceList,
    selectedPriceData,
    paymentSuccessMessage,
    paymentErrorMessage,
  } = useSelector((store: any) => store.stripe);
  const { modalIsOpen, modalName } = useSelector((store: any) => store.common);
  const [intentId, setIntentId] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const { GetPriceDataListServiceHandler } = useStripeServiceHandler();
  const [loader, setLoader] = useState(false);

  const handleRoute = (url: string) => {
    navigate(url);
  };

  const handleSelectedPriceData = (item: priceData) => {
    dispatch(UpdateSelectedPriceData(item));
  };

  const submitBtnHandler = async (e: any) => {
    e.preventDefault();
    if (!Object.keys(selectedPriceData).length) {
      setError(true);
      setErrorMessage("Select Subscription");
    } else {
      setError(false);
      setErrorMessage("");
      setLoader(true);
      const data = {
        deviceId: newDevice?.deviceId
          ? newDevice?.deviceId
          : selectedDevice?.id,
        priceId: selectedPriceData?.id,
      };
      const res = await CreateDeviceSubscriptionServiceHandler(data);
      setLoader(false);
      setIntentId(res?.client_secrets);
      setSubscriptionId(res?.subscriptionId);
      dispatch(UpdateModalName("devicePayment"));
      dispatch(UpdateModalOpenStatus(true));
    }
  };
  const cancelRegistration = (e: any) => {
    e.preventDefault();
    navigate(component.devices.url);
    dispatch(UpdatePaymentSuccessMessage(""));
    dispatch(UpdatePaymentErrorMessage(""));
    dispatch(ResetNewDeviceInfo());
  };

  useEffect(() => {
    const deviceId = newDevice?.deviceId
      ? newDevice?.deviceId
      : selectedDevice?.id;
    GetPriceDataListServiceHandler(deviceId);
  }, []);

  return (
    <>
      <div className="screenContainerPadding">
        <p className="breadcrum">
          <span onClick={() => handleRoute(component.dashboard.url)}>
            Dashboard
          </span>{" "}
          / <span>Register Device</span>
        </p>
        <div className="registerFormContainer">
          <form action="">
            <p className="formHeading">Register Device</p>
            {(paymentErrorMessage || paymentSuccessMessage) && (
              <div className="mb-3">
                {paymentSuccessMessage ? (
                  <SuccessMessage msg={paymentSuccessMessage} />
                ) : paymentErrorMessage ? (
                  <ErrorMessage msg={paymentErrorMessage} />
                ) : (
                  ""
                )}
              </div>
            )}
            <div className="grid gap-5">
              <div className="flex flex-col">
                <Label label="Device ID" required={true} />
                <input
                  className="registerFormInput"
                  type="text"
                  name="deviceId"
                  id="deviceId"
                  placeholder="Enter the ID of the device you are registering."
                  defaultValue={
                    newDevice?.deviceId
                      ? newDevice?.deviceId
                      : selectedDevice?.id
                  }
                  disabled
                  style={{ opacity: "0.4" }}
                />
              </div>
              <div className="flex flex-col">
                <Label label="Subscription Choice" required={true} />
                <div className="flex flex-wrap gap-5">
                  {priceList
                    ?.filter((item: priceData) => item?.currency !== "usd")
                    .map((item: priceData, index: number) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleSelectedPriceData(item)}
                          className={
                            selectedPriceData?.id === item?.id
                              ? "pricing_tile_card selected"
                              : "pricing_tile_card"
                          }
                        >
                          <div className="flex justify-between items-center mb-3">
                            <p className="title">
                              {item?.recurring?.interval === "month"
                                ? "monthly"
                                : "yearly"}
                            </p>
                            <input
                              type="radio"
                              className="cursor-pointer"
                              name="subscription"
                              value={item?.unit_amount}
                              checked={selectedPriceData?.id === item?.id}
                            />
                          </div>
                          <p className="pricing_amount">
                            ${(item?.unit_amount / 100).toFixed(0)}
                            <span>/{item?.recurring?.interval}</span>
                          </p>
                        </div>
                      );
                    })}
                </div>
                {error && <p className="errorMessage">{errorMessage}</p>}
              </div>

              <div className="flex gap-5 justify-end items-center">
                <button
                  onClick={submitBtnHandler}
                  className="primaryButtonFilled"
                >
                  {loader ? (
                    <ClipLoader
                      color={"#ffffff"}
                      size={15}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    "Next"
                  )}
                </button>
                <button className="borderedButton" onClick={cancelRegistration}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {modalIsOpen && modalName === "devicePayment" && intentId ? (
        <ModalComponent heading="Payment">
          <PaymentModal
            intentId={intentId}
            deviceId={
              newDevice?.deviceId ? newDevice?.deviceId : selectedDevice?.id
            }
            subscriptionId={subscriptionId}
          />
        </ModalComponent>
      ) : (
        ""
      )}
    </>
  );
}
