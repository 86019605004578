import { AnalogLine } from "../../constants/conversion";
import SetPointSensorSettings from "./SetPointSensorSettings";

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 100,
    label: "100%",
  },
];

const AnalogLineSensorSettings = SetPointSensorSettings({
  convertFromSetPoint: AnalogLine.fromSetPoint,
  convertToSetPoint: AnalogLine.toSetPoint,
  marks,
  max: 100,
  min: 0,
  step: 1,
  precision: 2,
});

export default AnalogLineSensorSettings;
