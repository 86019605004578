import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import useFirmware from "../../hooks/serviceHandler/firmware";
import { useDispatch, useSelector } from "react-redux";
import { UpdateSelectedFolder } from "../../redux/reducer/firmware";
import { ClipLoader } from "react-spinners";
import folderImage from "../../assets/images/folderIcon.png";

export default function FirmwareFolder() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { GetFirmwareFolderListServiceHandler } = useFirmware();
  const { selectedFolder } = useSelector((store: any) => store.firmware);

  const [loader, setLoader] = useState(false);
  const [folderList, setFolderList] = useState([]);

  const showFolderImages = (item: any) => {
    dispatch(UpdateSelectedFolder(item));
    navigate(component.firmwareFolder.url + `/${selectedFolder?.folder}`);
  };

  const firmwareFolderData = async () => {
    setLoader(true);
    const data = await GetFirmwareFolderListServiceHandler();
    setLoader(false);
    setFolderList(data);
  };

  useEffect(() => {
    firmwareFolderData();
  }, []);

  return (
    <>
      <div className="registerFormContainer">
        <p className="formHeading">Picture Folders</p>

        {loader ? (
          <div className="w-full flex items-center justify-center">
            <ClipLoader
              color={"#2047ab"}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : folderList?.length !== 0 ? (
          <div className="grid grid-cols-6 gap-5 mb-5">
            {folderList?.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className="flex flex-col justify-center items-center"
                >
                  <div className="firmware_image_container">
                    <img
                      src={folderImage}
                      alt="folder"
                      onClick={() => showFolderImages(item)}
                    />
                  </div>
                  <p
                    className="device-type"
                    style={{ width: "90px", overflowWrap: "break-word" }}
                  >
                    {item?.folder}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <p className="sensorName text-center mt-6">No Data Found</p>
        )}
      </div>
    </>
  );
}
