import * as React from "react";
import { styled } from "@mui/material/styles";
// import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { UpdateNewRolePermission } from "../../redux/reducer/role";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function PermissionAccordian() {
  const dispatch = useDispatch();
  const { newRole } = useSelector((store: any) => store.role);
  const [expanded, setExpanded] = React.useState<string | false>("Dashboard");
  const { permissionList } = useSelector((store: any) => store.permission);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handlePermissionItemClick = (permissionId: string) => {
    if (!newRole?.permissions?.length) {
      dispatch(UpdateNewRolePermission([permissionId]));
    } else {
      const isPresent = newRole?.permissions?.findIndex(
        (permission: any) => permission === permissionId
      );
      if (isPresent !== -1) {
        const newPermissionList = newRole?.permissions?.filter(
          (permission: any) => permission !== permissionId
        );
        dispatch(UpdateNewRolePermission([...newPermissionList]));
      } else {
        dispatch(
          UpdateNewRolePermission([...newRole?.permissions, permissionId])
        );
      }
    }
  };

  const isChecked = (permissionId: any) => {
    if (!newRole?.permissions?.length) {
      return false;
    } else {
      const index = newRole?.permissions?.findIndex(
        (ele: any, index: number) => ele === permissionId
      );
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div>
      {Object.keys(permissionList).map((screenName: string, index) => {
        return (
          <Accordion
            key={index}
            expanded={expanded === screenName}
            onChange={handleChange(screenName)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <p className="permissionModuleName">{screenName}</p>
            </AccordionSummary>
            <AccordionDetails>
              {permissionList[screenName].map((item: any, index: number) => {
                return (
                  <div key={index}>
                    <input
                      type="checkbox"
                      name=""
                      id={item.id}
                      className="inputCheckbox"
                      checked={isChecked(item?.id)}
                      onChange={() => handlePermissionItemClick(item?.id)}
                    />
                    <label htmlFor={item.id} className="checkboxLabel">
                      {item.permission}
                    </label>
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
