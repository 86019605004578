import React, { useEffect } from "react";
import sensorIcon from "../assets/images/icons/locationbgCircle.svg";
import { BsTrash } from "react-icons/bs";
import useNotificationServiceHandler from "../hooks/serviceHandler/notification";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { sensor } from "../interface/reducer/sensor";
import { UpdateSelectedSensor } from "../redux/reducer/sensors";
import { UpdateDrawerExpand } from "../redux/reducer/commonReducer";
import DrawerComponent from "../components/common/drawer/DrawerComponent";
import SensorDetail from "../components/common/drawer/SensorDetail";
import { notification } from "../interface/reducer/notification";

export default function Notification() {
  const dispatch = useDispatch();
  const {
    GetAllNotificationDataServiceHandler,
    DeleteNotificationServiceHandler,
    UpdateNotificationStatusSeviceHandler,
  } = useNotificationServiceHandler();

  const { notificationList, notificationUnreadCount } = useSelector(
    (store: any) => store.notification
  );
  const { drawerExpand, modalIsOpen, modalName } = useSelector(
    (store: any) => store.common
  );
  const { selectedSensor } = useSelector((state: any) => state.sensor);

  const deleteNotification = async (id: string, e: any) => {
    e.stopPropagation();
    await DeleteNotificationServiceHandler(id);
  };

  const sensorDetail = (item: notification) => {
    UpdateNotificationStatusSeviceHandler(item?.id);
    // dispatch(UpdateSelectedSensor(sensorData));
    // GetDeviceSensroeDetailDataByIdServiceHandler();
    // dispatch(UpdateDrawerExpand(true));
  };

  useEffect(() => {
    GetAllNotificationDataServiceHandler();
  }, []);

  return (
    <>
      {drawerExpand && (
        <DrawerComponent direction={"right"}>
          <SensorDetail />
        </DrawerComponent>
      )}
      <div className="screenContainerPadding">
        <p className="breadcrum">
          <span>Notification</span>
        </p>
        <div className="registerFormContainer">
          <div className="flex flex-col md:flex-row items-stretch	 justify-between md:items-center mb-2">
            <p className="formHeading">
              Notification{" "}
              <span className="notification_total_count">
                ({notificationUnreadCount})
              </span>{" "}
            </p>
          </div>
          {!notificationList?.length ? (
            <p className="sensorName text-center">No Notification Found</p>
          ) : (
            <div className="flex flex-col gap-4">
              {notificationList?.map((item: any, index: number) => {
                return (
                  <div
                    className="notification_container"
                    key={index}
                    style={{ opacity: item?.status && "0.6" }}
                    onClick={() => sensorDetail(item)}
                  >
                    <BsTrash
                      className="delete_icon"
                      color="#767676"
                      onClick={(e) => deleteNotification(item?.id, e)}
                    />
                    <div className="notificiation_icon">
                      <img src={item?.sensorIcon} alt="" />
                      {/* <p className="sensor_name">{item?.sensorName}</p> */}
                    </div>
                    <div>
                      <div className="flex justify-between items-start w-full">
                        <p className="notification_device_name">
                          {item?.deviceName} <br />
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            {item?.sensorName}
                          </span>
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="notification_desc ">
                          {item?.notificationTitle}
                        </p>
                      </div>
                    </div>
                    <p className="notification_time">
                      {moment(item?.createdAt).fromNow()}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
