import React from "react";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import RoleListingTable from "../common/table/RoleListing";
import searchIcon from "../../assets/images/icons/searchIcon.svg";
import filterIcon from "../../assets/images/icons/filterIcon.svg";
import { useDispatch } from "react-redux";
import { ResetNewRoleInfo } from "../../redux/reducer/role";

export default function RoleListing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleRoute = (url: string) => {
    navigate(url);
  };

  const addNewRoleBtnClick = (url: string) => {
    dispatch(ResetNewRoleInfo());
    handleRoute(url);
  };

  return (
    <div className="screenContainerPadding">
      <div className="flex justify-between">
        <p className="breadcrum">
          <span onClick={() => handleRoute(component.dashboard.url)}>
            Dashboard
          </span>{" "}
          /<span> Role Listing</span>
        </p>
        <button
          onClick={() => addNewRoleBtnClick(component.createRole.url)}
          className="borderedButton"
        >
          Add New Role
        </button>
      </div>
      <div className="registerFormContainer">
        <div className="flex flex-col md:flex-row items-stretch justify-between md:items-center mb-5">
          <p className="formHeading">Role List</p>
          {/* <div className="flex justify-end gap-5">
            <div className="searchBox">
              <img src={searchIcon} alt="filter" className="icon" />
              <input
                type="text"
                name=""
                id=""
                placeholder="Search"
                className="searchInput"
              />
            </div>
            <div className="searchBox">
              <img src={filterIcon} alt="filter" className="icon" />
              <select className="searchInput">
                <option value="All Devices">All Devices</option>
                <option value="Active Devices">Admin</option>
                <option value="Alert Devices">User</option>
              </select>
            </div>
          </div> */}
        </div>
        <RoleListingTable />
      </div>
    </div>
  );
}
