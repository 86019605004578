import React from "react";
import "../../assets/css/dashboard.css";
import { MdBatteryAlert, MdBattery3Bar, MdBattery0Bar } from "react-icons/md";
import { FaMoneyCheck } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import locationIcon from "../../assets/images/icons/locationbgCircle.svg";
import tempratureIcon from "../../assets/images/icons/tempbgcircle.svg";
import humidityIcon from "../../assets/images/icons/humidbgCircle.svg";
import batteryIcon from "../../assets/images/icons/voltbgCircle.svg";
import linesIcon from "../../assets/images/icons/inputbgCircle.svg";
import tempAlertIcon from "../../assets/images/icons/tempAlert.svg";
import voltAlertIcon from "../../assets/images/icons/voltAlert.svg";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import { device } from "../../interface/reducer/device";
import { useDispatch, useSelector } from "react-redux";
import { UpdateSelectedDevice } from "../../redux/reducer/devices";

export default function DeviceCard(props: { data: device }) {
  const { data } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state: any) => state.user);

  const handleDeviceClick = (item: device) => {
    dispatch(UpdateSelectedDevice(item));
    if (item?.purchasedStatus) {
      navigate(component.sensorListing.url);
    } else {
      if (userInfo.role === "Admin") {
        navigate(component.registerDevice.url);
      } else {
        navigate(component.sensorListing.url);
      }
    }
  };

  return (
    <>
      <div
        className={
          data.alerting
            ? "deviceCard flex flex-col gap-4 cursor-pointer deviceAlert relative"
            : data.active
            ? "deviceCard flex flex-col gap-4 cursor-pointer deviceActive relative"
            : "deviceCard flex flex-col gap-4 cursor-pointer deviceInactive relative"
        }
        onClick={() => handleDeviceClick(data)}
      >
        <div className="flex justify-between items-center gap-1">
          {(userInfo.role === "Super Admin" || userInfo.role === "Admin") && (
            <div className="flex items-center">
              <p className="paid">purchased</p>
              {data?.purchasedStatus ? (
                <TiTick color="#3eeb2f" fontSize={18} />
              ) : (
                <RxCross2 color="#ff3333" fontSize={18} />
              )}
            </div>
          )}
          <div className="flex gap-1">
            <p className="small-text">{data.id}</p>
            {data?.battery === null ? (
              <MdBattery0Bar color="#767676" fontSize={20} />
            ) : data?.battery === "0" ? (
              <MdBatteryAlert color="#767676" fontSize={20} />
            ) : (
              <MdBattery3Bar color="#767676" fontSize={20} />
            )}
          </div>
        </div>
        <div className="flex gap-5">
          <div className="deviceImageContainer">
            {typeof data?.image === "object" ? (
              <img
                src={
                  "https://cabinmanage.com/images/app-icons/device-type-tower-tech.png"
                }
                alt="devce"
              />
            ) : (
              <img src={data?.image} alt="devce" />
            )}
          </div>
          <div className="flex flex-col justify-center">
            <p className="device-name mb-2.5">
              {data.name ? data.name : "Unnamed Device"}
            </p>
            <p className="device-type">{data.model_name}</p>
          </div>
        </div>
        <div>
          <div className="flex justify-between items-center mb-2">
            <p className="sensor-title">Sensors</p>
            <p className="view-all">View All</p>
          </div>
          <div className="flex justify-between flex-wrap lg:justify-between items-center">
            <img
              src={locationIcon}
              alt="location"
              className="deviceSensorIcon"
            />
            <img src={tempratureIcon} alt="temp" className="deviceSensorIcon" />
            <img
              src={humidityIcon}
              alt="humidity"
              className="deviceSensorIcon"
            />
            <img src={batteryIcon} alt="volt" className="deviceSensorIcon" />
            <img src={linesIcon} alt="location" className="deviceSensorIcon" />
          </div>
        </div>
      </div>
    </>
  );
}
