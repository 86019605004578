import { useEffect, useRef } from "react";

export default function useDebouncedEffect(fn: any, timeout: any, deps: any) {
  const timer = useRef(0);
  const first = useRef(true);
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (!first?.current) {
      timer.current = window.setTimeout(fn, timeout);
    }
    first.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout, ...deps]);
}
