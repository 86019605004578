import React, { useState } from "react";
import useFirmware from "../../hooks/serviceHandler/firmware";
import "../../assets/css/firmware.css";
import image from "../../assets/images/949980.png";

export const UploadAndDisplayImage = ({ getAllImages }: any) => {
  const { PostFirmwareImageServiceHandler } = useFirmware();
  const [selectedImage, setSelectedImage] = useState(null);

  const onSubmitBtnHandler = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("", selectedImage);
    await PostFirmwareImageServiceHandler(formData);
    getAllImages();
    setSelectedImage(null);
  };
  // console.log('selectedImage',selectedImage.type);

  return (
    <div className="uploadImageContainer">
      <h1 className="uploadHeading">Upload Image</h1>

      {selectedImage && (
        <div>
          {selectedImage?.type !== "application/macbinary" ? (
            <img width={"250px"} src={URL.createObjectURL(selectedImage)} />
          ) : (
            <img width={"150px"} src={image} />
          )}

          <br />
          <div className="firmUploadCancelBtn">
            <button
              onClick={onSubmitBtnHandler}
              className="primaryButtonFilled"
            >
              Upload
            </button>
            <button
              onClick={() => setSelectedImage(null)}
              className="primaryButtonFilled"
            >
              Cancel
            </button>
          </div>
          <br />
        </div>
      )}

      <input
        type="file"
        name="name"
        onChange={(event) => {
          setSelectedImage(event.target.files[0]);
        }}
      />
    </div>
  );
};
