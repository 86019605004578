// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  background-color: #ecedf9;
  padding: 10px 20px;
}
.modal-header .heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: start;
  color: var(--black);
  text-transform: uppercase;
}
.modal-body {
  padding: 20px;
}
.react-responsive-modal-closeButton:focus {
  outline: none !important;
}
.react-responsive-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.react-responsive-modal-modal {
  background: var(--white) !important;
  width: 100% !important;
  border-radius: 5px !important;
  max-width: 400px !important;
  padding: 0 !important;
}
.react-responsive-modal-closeButton {
  top: 10px !important;
  right: 10px !important;
}

@media screen and (min-width: 1024px) {
  .react-responsive-modal-modal {
    max-width: 800px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/modal/modal.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,aAAa;AACf;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,+CAA+C;AACjD;AACA;EACE,mCAAmC;EACnC,sBAAsB;EACtB,6BAA6B;EAC7B,2BAA2B;EAC3B,qBAAqB;AACvB;AACA;EACE,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA;EACE;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".modal-header {\n  background-color: #ecedf9;\n  padding: 10px 20px;\n}\n.modal-header .heading {\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 24px;\n  text-align: start;\n  color: var(--black);\n  text-transform: uppercase;\n}\n.modal-body {\n  padding: 20px;\n}\n.react-responsive-modal-closeButton:focus {\n  outline: none !important;\n}\n.react-responsive-modal-overlay {\n  background-color: rgba(0, 0, 0, 0.5) !important;\n}\n.react-responsive-modal-modal {\n  background: var(--white) !important;\n  width: 100% !important;\n  border-radius: 5px !important;\n  max-width: 400px !important;\n  padding: 0 !important;\n}\n.react-responsive-modal-closeButton {\n  top: 10px !important;\n  right: 10px !important;\n}\n\n@media screen and (min-width: 1024px) {\n  .react-responsive-modal-modal {\n    max-width: 800px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
