import { Humidity } from "../../constants/conversion";
import SetPointSensorSettings from "./SetPointSensorSettings";

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 100,
    label: "100%",
  },
];

const HumiditySensorSettings = SetPointSensorSettings({
  convertFromSetPoint: Humidity.fromSetPoint,
  convertToSetPoint: Humidity.toSetPoint,
  marks,
  max: 100,
  min: 0,
  step: 1,
});

export default HumiditySensorSettings;
