import React from "react";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import searchIcon from "../../assets/images/icons/searchIcon.svg";
import filterIcon from "../../assets/images/icons/filterIcon.svg";
import ArchiveDevice from "../common/table/ArchiveDevice";

export default function ArchiveDeviceList() {
  const navigate = useNavigate();

  const handleRoute = (url: string) => {
    navigate(url);
  };

  return (
    <div className="screenContainerPadding">
      <p className="breadcrum">
        <span onClick={() => handleRoute(component.dashboard.url)}>
          Dashboard
        </span>{" "}
        / <span>Archive Device List</span>
      </p>
      <div className="registerFormContainer">
        <div className="flex flex-col md:flex-row items-stretch justify-between md:items-center mb-5">
          <p className="formHeading">All Archive Devices</p>
          <div className="flex justify-end gap-5">
            {/* <div className="searchBox">
              <img src={searchIcon} alt="filter" className="icon" />
              <input
                type="text"
                name=""
                id=""
                placeholder="Search"
                className="searchInput"
              />
            </div> */}
            {/* <div className="searchBox">
              <img src={filterIcon} alt="filter" className="icon" />
              <select className="searchInput">
                <option value="All Devices">All Devices</option>
                <option value="Active Devices">Active Devices</option>
                <option value="Alert Devices">Alert Devices</option>
                <option value="Inactive Devices">Inactive Devices</option>
              </select>
            </div> */}
          </div>
        </div>
        <ArchiveDevice />
      </div>
    </div>
  );
}
