import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import {
  ResetNewRoleError,
  ResetNewRoleErrorMessage,
  ResetNewRoleInfo,
  ResetNewRoleSuccessMessgae,
  UpdateNewRoleErrorMessage,
  UpdateNewRoleSuccessMessage,
  UpdateRoleList,
} from "../../redux/reducer/role";
import {
  DeleteRoleService,
  GetAllPermissionsListService,
  GetAllRoleListService,
  RoleService,
  UpdateRoleService,
} from "../../service/role";
import { UpdatePermissionsList } from "../../redux/reducer/permissions";
import { ResetNewUserManagementErrorMessage } from "../../redux/reducer/userManagement";

const useRoleServiceHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { newRole } = useSelector((store: any) => store.role);
  const { userInfo } = useSelector((store: any) => store.user);

  const RoleServiceHandler = async () => {
    try {
      const res = await RoleService(newRole);
      const { msg } = res?.data;
      if (msg) {
        dispatch(UpdateNewRoleSuccessMessage(msg));
        setTimeout(() => {
          navigate(component.roleList.url);
          dispatch(ResetNewRoleSuccessMessgae());
          dispatch(ResetNewUserManagementErrorMessage());
        }, 1000);
      } else {
        dispatch(ResetNewRoleError());
        navigate(component.roleList.url);
        dispatch(ResetNewRoleInfo());
        dispatch(ResetNewRoleSuccessMessgae());
        dispatch(ResetNewUserManagementErrorMessage());
      }
    } catch (error: any) {
      dispatch(
        UpdateNewRoleErrorMessage(error?.response?.data?.error?.message)
      );
      setTimeout(() => {
        dispatch(ResetNewRoleError());
      }, 2000);
    }
  };

  const GetAllRoleListServiceHandler = async () => {
    try {
      const res = await GetAllRoleListService(userInfo.id);
      const { result } = res.data;
      if (result?.length) {
        dispatch(UpdateRoleList([...result]));
      } else {
        dispatch(UpdateRoleList([]));
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const GetAllPermissionsServiceHandler = async () => {
    try {
      const res = await GetAllPermissionsListService();
      const { result } = res.data;
      if (result) {
        dispatch(UpdatePermissionsList({ ...result }));
      } else {
        dispatch(UpdatePermissionsList({}));
      }
    } catch (error: any) {
      console.log(error, "error");
    }
  };

  const UpdateRoleServiceHandler = async () => {
    try {
      await UpdateRoleService(newRole);
      dispatch(UpdateNewRoleSuccessMessage("Role updated successfully !"));
      setTimeout(() => {
        dispatch(ResetNewRoleErrorMessage());
        dispatch(ResetNewRoleInfo());
        dispatch(ResetNewRoleSuccessMessgae());
        navigate(-1);
      }, 1000);
    } catch (error: any) {
      console.log(error, "error");
    }
  };
  const DeleteRoleServiceHandler = async (roleId: string) => {
    try {
      await DeleteRoleService(roleId);
      await GetAllRoleListServiceHandler();
    } catch (error: any) {
      console.log(error, "error");
    }
  };

  return {
    GetAllRoleListServiceHandler,
    RoleServiceHandler,
    GetAllPermissionsServiceHandler,
    UpdateRoleServiceHandler,
    DeleteRoleServiceHandler,
  };
};

export default useRoleServiceHandler;
