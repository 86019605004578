import React from "react";
import { useNavigate } from "react-router-dom";
import component from "../constants/urls";
import AddFirmware from "../components/firmware/AddFirmware";
import FirmwareImage from "./PicturesImage";

export default function FirmWare() {
  const navigate = useNavigate();

  const handleRoute = (url: string) => {
    navigate(url);
  };

  return (
    <div className="screenContainerPadding">
      <p className="breadcrum">
        <span onClick={() => handleRoute(component.dashboard.url)}>
          Dashboard
        </span>{" "}
        / <span>Firmware</span>
      </p>
      <div className="grid grid-cols-1 gap-5">
        <AddFirmware />
      </div>
    </div>
  );
}
