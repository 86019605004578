import React, { useEffect, useState } from "react";

const getSeconds = () => Math.round(new Date().getTime() / 1000);

export default function AutoRefresh({ time, onRefresh = () => {} }: any) {
  // Get the second this component was mounted.
  const [lastRefresh, setLastRefresh] = useState(getSeconds());

  let [timeLeft, setTimeLeft] = useState(time);

  useEffect(() => {}, [time]);

  // Every second update the time until refresh.
  useEffect(() => {
    const interval = setInterval(() => {
      const now = getSeconds();
      const diff = now - lastRefresh;
      if (diff >= time + 1) {
        onRefresh();
        setLastRefresh(getSeconds());
        setTimeLeft(time);
      } else {
        setTimeLeft(time - diff);
      }
    }, 500);
    return () => clearInterval(interval);
  }, [onRefresh, setLastRefresh, setTimeLeft, time, lastRefresh]);

  return (
    <p className="refreshingCount">
      Refreshing in <b>{timeLeft}</b>s
    </p>
  );
}
