import { Voltage } from "../../constants/conversion";
import SetPointSensorSettings from "./SetPointSensorSettings";

const marks = [
  {
    value: 0,
    label: "0V",
  },
  {
    value: 36,
    label: "36V",
  },
];

const VoltageSensorSettings = SetPointSensorSettings({
  convertFromSetPoint: Voltage.fromSetPoint,
  convertToSetPoint: Voltage.toSetPoint,
  marks,
  max: 36,
  min: 0,
  step: 1,
});

export default VoltageSensorSettings;
