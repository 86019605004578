import {
  GoogleMap,
  MarkerF,
  Polyline,
  PolylineF,
} from "@react-google-maps/api";
import React, { useContext } from "react";
import { ThemeContext } from "../../../App";
import alertIcon from "../../../assets/images/icons/alertLocation.svg";
import finalIcon from "../../../assets/images/icons/activeLocation.svg";
import circular from "../../../assets/images/circular.svg";

const containerStyle = {
  width: "100%",
  height: "100%",
};

export default function CordinatedMap(props: {
  gpsLocation: any;
  manualCtr: any;
}) {
  const isLoaded = useContext(ThemeContext);

  const locations = props?.gpsLocation;

  const calculateBounds = (locations: any) => {
    const bounds = new window.google.maps.LatLngBounds();
    locations?.forEach((location: any) => bounds.extend(location));
    return bounds;
  };

  const fitMapToBounds = (map: any, locations: any) => {
    const bounds = calculateBounds(locations);
    map.fitBounds(bounds);
  };

  return isLoaded ? (
    <React.StrictMode>
      {props?.gpsLocation?.length && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={props?.gpsLocation[props?.gpsLocation?.length - 1]}
          zoom={10}
          mapTypeId="terrain"
          options={
            props.manualCtr && {
              mapTypeControl: false, // Hide map type control (satellite view)
              fullscreenControl: false, // Hide fullscreen control
              draggable: false,
              zoomControl: false,
              scaleControl: false,
            }
          }
          // onLoad={(map) => fitMapToBounds(map, locations)}
        >
          <PolylineF
            path={props?.gpsLocation}
            options={{
              strokeColor: "#ff3333",
              strokeOpacity: 0.8,
              strokeWeight: -3,
            }}
          />
          {locations?.map((location: any, index: any) => (
            <MarkerF
              key={index}
              position={location}
              icon={{
                url: index === locations.length - 1 ? finalIcon : alertIcon,
                scaledSize:
                  index === locations.length - 1
                    ? new google.maps.Size(20, 20)
                    : new google.maps.Size(6, 6),
              }}
            ></MarkerF>
          ))}
        </GoogleMap>
      )}
    </React.StrictMode>
  ) : (
    <></>
  );
}
