import { TemperatureFahrenheit } from "../../constants/conversion";
import SetPointSensorSettings from "./SetPointSensorSettings";

const marks = [
  {
    value: -67,
    label: "-67℉",
  },
  {
    value: 257,
    label: "257℉",
  },
];

const TemperatureSensorSettings = SetPointSensorSettings({
  convertFromSetPoint: TemperatureFahrenheit.fromSetPoint,
  convertToSetPoint: TemperatureFahrenheit.toSetPoint,
  marks,
  max: 257,
  min: -67,
  step: 1,
  isTemperatureSensor: true,
});

export default TemperatureSensorSettings;
