import { useDispatch, useSelector } from "react-redux";
import { EMAIL_REGEX } from "../../constants/constant";
import {
  ResetForgetPassowrdFormError,
  ResetLoginFormError,
  UpdateForgetPasswordFormError,
  UpdateLoginFormError,
} from "../../redux/reducer/auth";

const useForgetPasswordValidation = () => {
  const dispatch = useDispatch();

  const { forgetPassword } = useSelector((store: any) => store.auth);

  const ForgetPasswordFormValidation = () => {
    if (forgetPassword?.email?.trim() === "") {
      dispatch(ResetForgetPassowrdFormError());
      dispatch(
        UpdateForgetPasswordFormError({ key: "email", value: "Required !" })
      );
      return false;
    } else if (!EMAIL_REGEX.test(forgetPassword?.email)) {
      dispatch(ResetForgetPassowrdFormError());
      dispatch(
        UpdateForgetPasswordFormError({
          key: "email",
          value: "Please enter a valid email.",
        })
      );
      return false;
    } else {
      dispatch(ResetForgetPassowrdFormError());
      return true;
    }
  };

  const OnChangeForgetPasswordFormValueValidation = ({
    id,
    value,
  }: {
    id: "email";
    value: string;
  }) => {
    if (id === "email" && value?.trim() === "") {
      dispatch(ResetForgetPassowrdFormError());
      dispatch(
        UpdateForgetPasswordFormError({ key: "email", value: "Required !" })
      );
      return false;
    } else if (id === "email" && !EMAIL_REGEX.test(value)) {
      dispatch(ResetForgetPassowrdFormError());
      dispatch(
        UpdateForgetPasswordFormError({
          key: "email",
          value: "Please enter a valid email.",
        })
      );
      return false;
    } else {
      dispatch(ResetForgetPassowrdFormError());
      return true;
    }
  };

  return {
    ForgetPasswordFormValidation,
    OnChangeForgetPasswordFormValueValidation,
  };
};

export default useForgetPasswordValidation;
