import { useSelector } from "react-redux";
import { GetHistoryAlertListService } from "../../service/history";
import swal from "sweetalert";

const useHistory = () => {
  const { userInfo } = useSelector((store: any) => store.user);

  const getAllHistory = async (currentPage: number, search: string) => {
    try {
     
      const userId = userInfo?.id
      const res = await GetHistoryAlertListService({userId, currentPage, search});
      return res?.data?.result;
    } catch (error) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  return {
    getAllHistory,
  };
};
export default useHistory;
