import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ManageAllDevice from "../components/common/table/ManageAllDevice";
import component from "../constants/urls";
import searchIcon from "../assets/images/icons/searchIcon.svg";
import filterIcon from "../assets/images/icons/filterIcon.svg";
import { useSelector } from "react-redux";
import { device } from "../interface/reducer/device";
import DeviceCard from "../components/dashboard/DeviceCard";
import noRecordFound from "../assets/images/no-record-found.png";

const deviceState = [
  {
    key: "all",
    value: "All Devices",
  },
  {
    key: "active",
    value: "Active Devices",
  },
  {
    key: "inactive",
    value: "Inactive Devices",
  },
  {
    key: "alert",
    value: "Alert Devices",
  },
];

export default function AllDeviceList() {
  const navigate = useNavigate();

  const [selectedState, setSelectedState] = useState("all");
  const [deviceList, setDeviceList] = useState<Array<device>>([]);
  const { devicesList } = useSelector((state: any) => state.device);

  const handleRoute = (url: string) => {
    navigate(url);
  };
  const GetFilteredDeviceList = () => {
    let newList = [];
    if (selectedState === "active") {
      newList = devicesList?.filter((device: device) => device.active);
    } else if (selectedState === "inactive") {
      newList = devicesList?.filter((device: device) => !device.active);
    } else if (selectedState === "alert") {
      newList = devicesList?.filter((device: device) => device.alerting);
    } else {
      newList = [...devicesList];
    }
    setDeviceList([...newList]);
  };

  useEffect(() => {
    GetFilteredDeviceList();
  }, [selectedState]);
  console.log('deviceState',deviceState);
  
  return (
    <>
      <div className="screenContainerPadding">
        <p className="breadcrum">
          <span onClick={() => handleRoute(component.dashboard.url)}>
            Dashboard
          </span>{" "}
          / <span>All Device</span>
        </p>
        <div className="registerFormContainer">
          <div className="flex flex-col md:flex-row items-stretch	justify-between md:items-center mb-5">
            <p className="formHeading">All Devices</p>
            <div className="flex justify-end gap-5">
              {/* <div className="searchBox">
                <img src={searchIcon} alt="filter" className="icon" />
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Search"
                  className="searchInput"
                />
              </div> */}
              <div className="searchBox">
                <img src={filterIcon} alt="filter" className="icon" />
                <select
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                  className="searchInput"
                >
                  {deviceState?.map((state) => (
                    <option
                      key={state.key}
                      value={state.key}
                      style={{
                        color:
                          selectedState === state.key
                            ? "black"
                            : "rgba(0,0,0,0.4)",
                      }}
                    >
                      {state.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* {!deviceList?.length ? (
            <div className="no_record_found_image">
              <img src={noRecordFound} alt="no-record" />
            </div>
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 pb-5">
              {deviceList?.map((item: device, index: number) => {
                return (
                  <div key={index}>
                    <DeviceCard data={item} />
                  </div>
                );
              })}
            </div>
          )} */}
          <ManageAllDevice
            deviceList={deviceList}
            setDeviceList={setDeviceList}
          />
        </div>
      </div>
    </>
  );
}
