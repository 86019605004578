import * as React from "react";
import "./sidebar.css";
import { RxDashboard } from "react-icons/rx";
import {
  MdOutlineDevices,
  MdOutlineHistory,
  MdOutlineInventory,
} from "react-icons/md";
import { ImFilePicture } from "react-icons/im";
import { FiBriefcase, FiUsers } from "react-icons/fi";
import { FaUserPlus } from "react-icons/fa";
import { BiSolidUser, BiHelpCircle } from "react-icons/bi";
import { BsDatabase } from "react-icons/bs";
import { RiAccountCircleFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import component from "../../../constants/urls";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useState } from "react";
import { VscSignOut } from "react-icons/vsc";
import useAuthServiceHandler from "../../../hooks/serviceHandler/Auth";
import logo from "../../../assets/images/cabin-tech-logo.svg";

export default function SidebarOld() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { LogoutServiceHandler } = useAuthServiceHandler();

  const { pathname } = location;

  const [userExpand, setUserExpand] = useState(false);
  const { sidebarExpand } = useSelector((state: any) => state.common);
  const { userInfo } = useSelector((state: any) => state.user);

  const handleLogout = () => {
    LogoutServiceHandler();
  };

  const handleRoute = (url: string) => {
    navigate(url);
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        {/* <img src={barIcon} alt="bar" /> */}
        <img
          src={logo}
          alt="logo"
          className="sidebarLogo"
          onClick={() => handleRoute(component.dashboard.url)}
        />
      </div>
      <div className="sidebar_body">
        <ul className="sidebar_ul">
          <li>
            <div
              className="sidebar_li"
              onClick={() => handleRoute(component.dashboard.url)}
            >
              <RxDashboard
                fontSize={18}
                color={
                  pathname === component.dashboard.url ? "#2047AB" : "#919191"
                }
                className="sidebarIcons"
              />
              <p
                className={
                  pathname === component.dashboard.url
                    ? "sidebar_item sidebar_item_active"
                    : "sidebar_item"
                }
              >
                Dashboard
              </p>
            </div>
          </li>
          {userInfo.role === "Super Admin" && (
            <li>
              <div
                className="sidebar_li"
                onClick={() => handleRoute(component.inventoryDeviceList.url)}
              >
                <MdOutlineInventory
                  fontSize={18}
                  color={
                    pathname === component.inventoryDeviceList.url
                      ? "#2047AB"
                      : "#919191"
                  }
                  className="sidebarIcons"
                />
                <p
                  className={
                    pathname === component.inventoryDeviceList.url
                      ? "sidebar_item sidebar_item_active"
                      : "sidebar_item"
                  }
                >
                  Device Inventory
                </p>
              </div>
            </li>
          )}
          <li>
            <div
              className="sidebar_li"
              onClick={() => handleRoute(component.devices.url)}
            >
              <MdOutlineDevices
                fontSize={18}
                color={
                  pathname === component.devices.url ? "#2047AB" : "#919191"
                }
                className="sidebarIcons"
              />
              <p
                className={
                  pathname === component.devices.url
                    ? "sidebar_item sidebar_item_active"
                    : "sidebar_item"
                }
              >
                Active Devices
              </p>
            </div>
          </li>
          {/* <li>
            <div
              className="sidebar_li"
              onClick={() => handleRoute(component.manageDevices.url)}
            >
              <PiTreeStructure
                fontSize={18}
                color={
                  pathname === component.manageDevices.url
                    ? "#2047AB"
                    : "#919191"
                }
                className="sidebarIcons"
              />
              <p
                className={
                  pathname === component.manageDevices.url
                    ? "sidebar_item sidebar_item_active"
                    : "sidebar_item"
                }
              >
                Manage Devices
              </p>
            </div>
          </li> */}
          {/* {userInfo.role === "Super Admin" && (
            <li>
              <div
                className="sidebar_li"
                onClick={() => handleRoute(component.archiveDevice.url)}
              >
                <PiArchiveBox
                  fontSize={18}
                  color={
                    pathname === component.archiveDevice.url
                      ? "#2047AB"
                      : "#919191"
                  }
                  className="sidebarIcons"
                />
                <p
                  className={
                    pathname === component.archiveDevice.url
                      ? "sidebar_item sidebar_item_active"
                      : "sidebar_item"
                  }
                >
                  Archive Devices
                </p>
              </div>
            </li>
          )} */}
          <li>
            <div
              className="sidebar_li"
              onClick={() => handleRoute(component.history.url)}
            >
              <MdOutlineHistory
                fontSize={18}
                color={
                  pathname === component.history.url ? "#2047AB" : "#919191"
                }
                className="sidebarIcons"
              />
              <p
                className={
                  pathname === component.history.url
                    ? "sidebar_item sidebar_item_active"
                    : "sidebar_item"
                }
              >
                History
              </p>
            </div>
          </li>

          {/* <li>
            <div
              className="sidebar_li"
              onClick={() => handleRoute(component.dashboard.url)}
            >
              <BiSolidUser
                fontSize={18}
                color={
                  pathname === component.userListing.url ||
                  pathname === component.roleList.url
                    ? "#2047AB"
                    : "#919191"
                }
                className="sidebarIcons"
              />
              <p
                className={
                  pathname === component.userListing.url ||
                  pathname === component.roleList.url
                    ? "sidebar_item sidebar_item_active"
                    : "sidebar_item"
                }
              >
                User Management
              </p>
            </div>
          </li> */}
          {(userInfo.role === "Super Admin" || userInfo.role === "Admin") && (
            <li>
              <div
                className="sidebar_li"
                onClick={() => setUserExpand(!userExpand)}
              >
                <BiSolidUser
                  color={
                    pathname === component.userListing.url ||
                    pathname === component.roleList.url
                      ? "#2047AB"
                      : "#919191"
                  }
                  className="sidebarIcons"
                />

                <p
                  className={
                    pathname === component.userListing.url ||
                    pathname === component.roleList.url
                      ? "sidebar_item sidebar_item_active"
                      : "sidebar_item"
                  }
                >
                  User Management
                </p>

                {userExpand && (
                  <RiArrowDropUpLine
                    fontSize={30}
                    color={
                      pathname === component.userListing.url
                        ? "#2047AB"
                        : "#919191"
                    }
                  />
                )}
                {!userExpand && (
                  <RiArrowDropDownLine
                    fontSize={30}
                    color={
                      pathname === component.userListing.url
                        ? "#2047AB"
                        : "#919191"
                    }
                  />
                )}
              </div>
              {userExpand && (
                <ul className="sidebar_ul_item">
                  {userInfo.role === "Super Admin" || userInfo.role === "Admin" ? (
                    <li
                      className="items"
                      onClick={() => handleRoute(component.roleList.url)}
                    >
                      <FaUserPlus
                        fontSize={14}
                        color={
                          pathname === component.roleList.url
                            ? "#2047AB"
                            : "#919191"
                        }
                      />
                      <p
                        className={
                          pathname === component.roleList.url
                            ? "activeClass"
                            : "inactiveClass"
                        }
                      >
                        {" "}
                        Roles
                      </p>
                    </li>
                  ) : ""}
                  <li
                    className="items"
                    onClick={() => handleRoute(component.userListing.url)}
                  >
                    <FiUsers
                      fontSize={14}
                      color={
                        pathname === component.userListing.url
                          ? "#2047AB"
                          : "#919191"
                      }
                    />
                    <p
                      className={
                        pathname === component.userListing.url
                          ? "activeClass"
                          : "inactiveClass"
                      }
                    >
                      {" "}
                      Users
                    </p>
                  </li>
                </ul>
              )}
            </li>
          )}

          {userInfo.role === "Super Admin" && (
            <li>
              <div
                className="sidebar_li"
                onClick={() => handleRoute(component.firmware.url)}
              >
                <FiBriefcase
                  fontSize={18}
                  color={
                    pathname === component.firmware.url ? "#2047AB" : "#919191"
                  }
                  className="sidebarIcons"
                />
                <p
                  className={
                    pathname === component.firmware.url
                      ? "sidebar_item sidebar_item_active"
                      : "sidebar_item"
                  }
                >
                  Firmware
                </p>
              </div>
            </li>
          )}
           {userInfo.role === "Super Admin" && (
            <li>
              <div
                className="sidebar_li"
                onClick={() => handleRoute(component.firmwareImage.url)}
              >
                <ImFilePicture fontSize={18}
                  color={
                    pathname === component.firmwareImage.url
                      ? "#2047AB"
                      : "#919191"
                  }
                  className="sidebarIcons"/>
                {/* <FiBriefcase
                  fontSize={18}
                  color={
                    pathname === component.firmwareFolder.url
                      ? "#2047AB"
                      : "#919191"
                  }
                  className="sidebarIcons"
                /> */}
                <p
                  className={
                    pathname === component.firmwareImage.url
                      ? "sidebar_item sidebar_item_active"
                      : "sidebar_item"
                  }
                >
                  Firmware Images
                </p>
              </div>
            </li>
          )}
          {userInfo.role === "Super Admin" && (
            <li>
              <div
                className="sidebar_li"
                onClick={() => handleRoute(component.firmwareFolder.url)}
              >
                <ImFilePicture fontSize={18}
                  color={
                    pathname === component.firmwareFolder.url
                      ? "#2047AB"
                      : "#919191"
                  }
                  className="sidebarIcons"/>
                {/* <FiBriefcase
                  fontSize={18}
                  color={
                    pathname === component.firmwareFolder.url
                      ? "#2047AB"
                      : "#919191"
                  }
                  className="sidebarIcons"
                /> */}
                <p
                  className={
                    pathname === component.firmwareFolder.url
                      ? "sidebar_item sidebar_item_active"
                      : "sidebar_item"
                  }
                >
                  Pictures
                </p>
              </div>
            </li>
          )}
          <li>
            <div
              className="sidebar_li"
              onClick={() => handleRoute(component.account.url)}
            >
              <RiAccountCircleFill
                fontSize={18}
                color={
                  pathname === component.account.url ? "#2047AB" : "#919191"
                }
              />
              <p
                className={
                  pathname === component.account.url
                    ? "sidebar_item sidebar_item_active"
                    : "sidebar_item"
                }
              >
                Account
              </p>
            </div>
          </li>
          {userInfo.role === "Super Admin" && (
            <li>
              <div
                className="sidebar_li"
                onClick={() => handleRoute(component.logs.url)}
              >
                <BsDatabase
                  fontSize={18}
                  color={
                    pathname === component.logs.url ? "#2047AB" : "#919191"
                  }
                  className="sidebarIcons"
                />
                <p
                  className={
                    pathname === component.logs.url
                      ? "sidebar_item sidebar_item_active"
                      : "sidebar_item"
                  }
                >
                  Logs
                </p>
              </div>
            </li>
          )}
          <li>
            <div
              className="sidebar_li"
              onClick={() =>
                openInNewTab(
                  "https://www.tracertechnologysystems.com/support/cabin-tech"
                )
              }
            >
              <BiHelpCircle
                fontSize={18}
                color={pathname === component.faq.url ? "#2047AB" : "#919191"}
                className="sidebarIcons"
              />
              <p
                className={
                  pathname === component.faq.url
                    ? "sidebar_item sidebar_item_active"
                    : "sidebar_item"
                }
              >
                Help / FAQs
              </p>
            </div>
          </li>
          <li>
            <div className="sidebar_li" onClick={handleLogout}>
              <VscSignOut
                fontSize={18}
                color={
                  pathname === component.logout.url ? "#2047AB" : "#919191"
                }
              />
              <p
                className={
                  pathname === component.logout.url
                    ? "sidebar_item sidebar_item_active"
                    : "sidebar_item"
                }
              >
                Logout
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
