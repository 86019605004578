import React from "react";
import styled from "styled-components";
import AutoRefresh from "./AutoRefresh";
import { Breadcrumbs } from "@mui/material";

const StyledBreadcrumbs = styled(Breadcrumbs)`
  a {
    color: #495057;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: start;
    margin-bottom: 30px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
  }

  li:last-of-type a {
    color: #495057;
  }
`;

export default function PageBreadcrumbs({
  autoRefresh = false,
  children,
  time,
  onRefresh,
}) {
  return (
    <div className="flex justify-between items-center">
      <StyledBreadcrumbs aria-label="breadcrumb">{children}</StyledBreadcrumbs>
      {autoRefresh && (
        <div>
          <AutoRefresh {...{ time, onRefresh }} />
        </div>
      )}
    </div>
  );
}
