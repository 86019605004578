import { Temperature } from "../../constants/conversion";
import SetPointSensorSettings from "./SetPointSensorSettings";

const marks = [
  {
    value: -55,
    label: "-55℃",
  },
  {
    value: 125,
    label: "125℃",
  },
];

const TemperatureSensorSettings = SetPointSensorSettings({
  convertFromSetPoint: Temperature.fromSetPoint,
  convertToSetPoint: Temperature.toSetPoint,
  marks,
  max: 125,
  min: -55,
  step: 1,
  isTemperatureSensor: true,
});

export default TemperatureSensorSettings;
