import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import archiveIcon from "../../../assets/images/icons/archiveIcon.svg";
import useDevicesServiceHandler from "../../../hooks/serviceHandler/devices";
import { device } from "../../../interface/reducer/device";
import unarchiveIcon from "../../../assets/images/icons/unarchiveIcon.svg";
import { UpdateArchiveDeviceList } from "../../../redux/reducer/devices";
import noRecordFound from "../../../assets/images/no-record-found.png";

interface Column {
  id: "S.No." | "Device Id" | "Model" | "Name" | "Status" | "Action";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "S.No.", label: "S.No.", align: "right" },
  { id: "Device Id", label: "Device Id", align: "right" },
  { id: "Model", label: " Model", align: "right" },
  { id: "Name", label: "Name", align: "right" },
  { id: "Status", label: "Status", align: "right" },
  { id: "Action", label: "Action", align: "right" },
];

interface Data {
  serialNumber: string;
  id: string;
  name: string;
  model: string;
  status: string;
  active: boolean;
  archive: boolean;
}

const rows = [
  {
    serialNumber: "1",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: false,
  },
  {
    serialNumber: "2",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: true,
  },
  {
    serialNumber: "3",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Inactive",
    active: false,
    archive: true,
  },
  {
    serialNumber: "4",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: true,
  },
  {
    serialNumber: "5",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: true,
  },
  {
    serialNumber: "6",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: true,
  },
  {
    serialNumber: "7",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: false,
  },
  {
    serialNumber: "8",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Inactive",
    active: false,
    archive: true,
  },
  {
    serialNumber: "9",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: true,
  },
  {
    serialNumber: "10",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: false,
  },
  {
    serialNumber: "11",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: true,
  },

  {
    serialNumber: "12",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Inactive",
    active: false,
    archive: false,
  },
  {
    serialNumber: "13",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Inactive",
    active: false,
    archive: true,
  },
  {
    serialNumber: "14",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: false,
  },
  {
    serialNumber: "15",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: true,
  },
  {
    serialNumber: "16",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: false,
  },
  {
    serialNumber: "17",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: true,
  },
  {
    serialNumber: "18",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: false,
  },
  {
    serialNumber: "19",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: true,
  },
  {
    serialNumber: "20",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: false,
  },
  {
    serialNumber: "21",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: true,
  },

  {
    serialNumber: "22",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: true,
  },
  {
    serialNumber: "23",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Inactive",
    active: false,
    archive: true,
  },
  {
    serialNumber: "24",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: true,
  },

  {
    serialNumber: "25",
    id: "89112233445566778899",
    name: "Tower Tech",
    model: "Ganymede Farms",
    status: "Active",
    active: true,
    archive: true,
  },
];

export default function ArchiveDevice() {
  const dispatch = useDispatch();

  const {
    ArchivedDevicesListServiceHandler,
    ChangeDeviceArchiveStatusServiceHandler,
  } = useDevicesServiceHandler();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { archiveDeviceList } = useSelector((store: any) => store.device);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleArchiveDevice = async (item: device) => {
    await ChangeDeviceArchiveStatusServiceHandler(item.id);
    const NewArchiveList = await ArchivedDevicesListServiceHandler();
    if (NewArchiveList === undefined) {
      dispatch(UpdateArchiveDeviceList([]));
    } else {
      dispatch(UpdateArchiveDeviceList([...NewArchiveList]));
    }
  };

  React.useEffect(() => {
    ArchivedDevicesListServiceHandler();
  }, []);

  return (
    <>
      {archiveDeviceList.length ? (
        <>
          <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
            <TableContainer sx={{ minHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        // align={column.align}
                        style={{
                          backgroundColor: "#2047AB",
                          borderBottom: "none",
                        }}
                      >
                        <p className="tableText" style={{ color: "#ffffff" }}>
                          {column.label}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {archiveDeviceList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row: device, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="left">
                            <p className="userTableData">{index + 1}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p className="userTableData">{row?.id}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p className="userTableData">
                              {row?.model_name ? row.model_name : "NA"}
                            </p>
                          </TableCell>
                          <TableCell align="left">
                            <p className="userTableData">
                              {row?.name ? row?.name : "NA"}
                            </p>
                          </TableCell>
                          <TableCell align="left">
                            <p
                              className="userTableData"
                              style={{
                                color: row?.active ? "#3eeb2f" : "#d0342c",
                              }}
                            >
                              {row.active ? "Active" : "Inactive"}
                            </p>
                          </TableCell>
                          <TableCell align="center">
                            <div className="flex justify-start items-center">
                              <img
                                src={unarchiveIcon}
                                alt="archive"
                                className="iconSize"
                                onClick={() => handleArchiveDevice(row)}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={archiveDeviceList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      ) : (
        <>
          <div
            style={{
              minHeight: "440px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="no_record_found_image">
              {" "}
              <img src={noRecordFound} alt="not_found" />
            </div>
          </div>
        </>
      )}
    </>
  );
}
