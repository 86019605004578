import { useDispatch, useSelector } from "react-redux";
import Label from "../components/common/formInput/Label";
import component from "../constants/urls";
import useAuthServiceHandler from "../hooks/serviceHandler/Auth";
import useRegisterFormValidation from "../hooks/validations/Register";
import { UpdateRegisterInputs } from "../redux/reducer/auth";
import SuccessMessage from "../components/common/alert/SuccessMessage";
import ErrorMessage from "../components/common/alert/ErrorMessage";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";
import { useState } from "react";
import logo from "../assets/images/cabin-tech-logo.svg";
import { ClipLoader } from "react-spinners";

export default function Register() {
  const dispatch = useDispatch();
  const { OnChangeRegistrationFormValueValidation, RegisterFormValidation } =
    useRegisterFormValidation();
  const { RegisterUserServiceHandler } = useAuthServiceHandler();

  const { register, registerFormError } = useSelector(
    (store: any) => store.auth
  );

  const { registerErrorMessage, registerSuccessMessage } = useSelector(
    (store: any) => store.common
  );
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (
    key:
      | "name"
      | "email"
      | "password"
      | "confirmPassword"
      | "phone"
      | "deviceId",
    value: string
  ) => {
    dispatch(UpdateRegisterInputs({ key, value }));
    OnChangeRegistrationFormValueValidation({ key, value });
  };

  const handleRegisterSubmit = async (e: any) => {
    e.preventDefault();
    const isValid = RegisterFormValidation();
    if (isValid) {
      setLoading(true);
      await RegisterUserServiceHandler();
      setLoading(false);
    }
  };

  return (
    <div className="registerContainer">
      <div className="logo mb-4">
        <img src={logo} alt="logo" />
      </div>
      <div className="loginContainer">
        <p className="login-heading">Create a AMAS Manage account</p>
        {(registerSuccessMessage || registerErrorMessage) && (
          <div className="mb-3">
            {registerSuccessMessage ? (
              <SuccessMessage msg={registerSuccessMessage} />
            ) : registerErrorMessage ? (
              <ErrorMessage msg={registerErrorMessage} />
            ) : (
              ""
            )}
          </div>
        )}

        <form action="" className="w-full grid gap-2 md:gap-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5">
            <div className="">
              <Label label="Name" required={true} />
              <input
                className="registerFormInput"
                type="text"
                name="name"
                id="name"
                value={register?.name}
                onChange={(e) => handleChange("name", e.target.value)}
              />
              <p className="errorMessage">{registerFormError?.name}</p>
            </div>
            <div className="">
              <Label label="Email" required={true} />
              <input
                className="registerFormInput"
                type="text"
                name="email"
                id="email"
                value={register?.email}
                onChange={(e) => handleChange("email", e.target.value)}
              />
              <p className="errorMessage">{registerFormError?.email}</p>
            </div>
            <div className="">
              <Label label="Phone Number" required={true} />
              <PhoneInput
                country={"us"}
                value={register?.phone}
                onChange={(phone) => handleChange("phone", `+${phone}`)}
                inputStyle={{
                  height: "40px",
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontWeight: "400",
                  border: "1px solid #ced4da",
                  width: "100%",
                  borderRadius: "0",
                  fontFamily: "Montserrat, sans-serif",
                }}
              />

              <p className="errorMessage">{registerFormError?.phone}</p>
            </div>
            <div className="">
              <Label label="Password" required={true} />
              <div className="relative">
                <input
                  className="registerFormInput"
                  type={showPassword ? "type" : "password"}
                  name="password"
                  id="password"
                  value={register?.password}
                  onChange={(e) => handleChange("password", e.target.value)}
                />
                <div
                  className="absolute top-0 right-0 flex justify-center items-center cursor-pointer"
                  style={{
                    height: "40px",
                    width: "40px",
                    border: "1px solid #ced4da",
                  }}
                >
                  {!showPassword ? (
                    <BiSolidHide
                      fontSize={20}
                      color="#495057"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <BiSolidShow
                      fontSize={20}
                      color="#495057"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </div>
              </div>

              <p className="errorMessage">{registerFormError?.password}</p>
            </div>
          </div>
          <div className="">
            <Label label="Device Id" required={true} />
            <input
              className="registerFormInput"
              type="text"
              name="deviceId"
              id="deviceId"
              value={register?.deviceId}
              onChange={(e) => handleChange("deviceId", e.target.value)}
            />
            <p className="errorMessage">{registerFormError?.deviceId}</p>
          </div>
          <div>
            {loading ? (
              <div className="mt-4 submitButton">
                <ClipLoader
                  color={"#ffffff"}
                  size={15}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <input
                type="submit"
                value="Create account"
                className="mt-4 submitButton"
                onClick={handleRegisterSubmit}
              />
            )}

            <p className="login-text-msg">
              Have an account?
              <span>
                {" "}
                <Link to={component.login.url}>Sign In</Link>
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}
