import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateNewUserManagementInfo } from "../../../redux/reducer/userManagement";
import useUserManagementValidation from "../../../hooks/validations/UserManagement";

function AddUserCheckbox({ label, value, id }) {
  const dispatch = useDispatch();

  const { OnChangeUserManagementFormValueValidation } =
    useUserManagementValidation();

  const [isCheck, setIsCheck] = useState(value);

  const { newUser } = useSelector((store: any) => store.userManagement);

  const handleChange = ({ key, value }: { key: string; value: boolean }) => {
    setIsCheck(!isCheck);
    dispatch(UpdateNewUserManagementInfo({ key: id, value }));
    OnChangeUserManagementFormValueValidation({ ...newUser, [key]: value });
  };

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        id={id}
        className="inputCheckbox"
        checked={isCheck}
        onChange={() => handleChange({ key: id, value: !isCheck })}
      />
      <label htmlFor={id} className="checkboxLabel">
        {label}
      </label>
    </div>
  );
}

export default AddUserCheckbox;
