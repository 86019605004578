import { Current } from "../../constants/conversion";
import SetPointSensorSettings from "./SetPointSensorSettings";

const marks = [
  {
    value: 0,
    label: "0A",
  },
  {
    value: 100,
    label: "100A",
  },
];

const CurrentSensorSettings = SetPointSensorSettings({
  convertFromSetPoint: Current.fromSetPoint,
  convertToSetPoint: Current.toSetPoint,
  marks,
  max: 100,
  min: 0,
  step: 1,
});

export default CurrentSensorSettings;
