import axios from "axios";
import {
  ChangePassword,
  ForgetPassword,
  LoginCred,
  newUserInfo,
} from "../interface/services/Auth";
import { getAuthHeader } from "../constants/constant";

export const RegisterUserService = async (userData: newUserInfo) => {
  const url = `${process.env.REACT_APP_URL}web/v1/register`;
  return axios.post(url, userData);
};

export const LoginService = async (loginCred: LoginCred) => {
  const url = `${process.env.REACT_APP_URL}web/v1/login`;
  return axios.post(url, loginCred);
};

export const ForgetPasswordService = (email: ForgetPassword) => {
  const url = `${process.env.REACT_APP_URL}web/v1/forgotPassword`;
  return axios.post(url, email);
};

export const ChangePasswordService = (
  userId: string,
  password: ChangePassword
) => {
  const url = `${process.env.REACT_APP_URL}web/v1/me/password/${userId}`;
  return axios.post(url, password);
};

export const UserInfoService = async (userId: string) => {
  const url = `${process.env.REACT_APP_URL}web/v1/me/${userId}`;
  return axios.get(url, getAuthHeader());
};

export const UpdateUserProfileService = (userId: string, data: newUserInfo) => {
  const url = `${process.env.REACT_APP_URL}web/v1/updateUser/${userId}`;
  return axios.patch(url, data, getAuthHeader());
};
export const LogoutService = (data: any) => {
  const url = `${process.env.REACT_APP_URL}v1/logout`;
  return axios.patch(url, data);
};
