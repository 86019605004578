import { useDispatch } from "react-redux";
import {
  GetAllLogsDataService,
  GetLogDetailDataService,
} from "../../service/logs";
import {
  UpdateLogCount,
  UpdateLogLoader,
  UpdateLogsList,
  UpdateSelectedLogData,
} from "../../redux/reducer/logs";
import swal from "sweetalert";

const useDeviceLogs = () => {
  const dispatch = useDispatch();

  const GetAllLogsDataServiceHandler = async (
    page: number,
    type: string,
    deviceId: string
  ) => {
    try {
      dispatch(UpdateLogLoader(true));
      const res = await GetAllLogsDataService(page, type, deviceId);
      dispatch(UpdateLogLoader(false));
      const { rows, count } = res.data.result;
      if (count) {
        dispatch(UpdateLogCount(count));
      }
      if (rows?.length) {
        dispatch(UpdateLogsList(rows));
      } else {
        dispatch(UpdateLogsList([]));
      }
    } catch (error) {
      dispatch(UpdateLogLoader(false));
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  const GetLogsDetailDataServiceHandler = async (
    logId: string,
    type: string
  ) => {
    try {
      const res = await GetLogDetailDataService(logId, type);
      const { result } = res?.data;
      if (result) {
        dispatch(UpdateSelectedLogData(result));
      }
    } catch (error) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };
  return {
    GetAllLogsDataServiceHandler,
    GetLogsDetailDataServiceHandler,
  };
};
export default useDeviceLogs;
