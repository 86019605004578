// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.box {
  position: relative;
  max-width: 600px;
  width: 90%;
  height: 400px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0,0,0,.1);
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: ' ';
  display: block;
  border: 5px solid #d0342c;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #d0342c;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/sensorCard.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;EACV,aAAa;EACb,gBAAgB;EAChB,mCAAmC;AACrC;;AAEA,WAAW;AACX;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,kBAAkB;AACpB;AACA;;EAEE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,eAAe;EACf,yBAAyB;EACzB,qCAAqC;EACrC,WAAW;EACX,mCAAmC;EACnC,qCAAqC;EACrC,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA,YAAY;AACZ;EACE,UAAU;EACV,WAAW;AACb;AACA;;EAEE,6BAA6B;EAC7B,8BAA8B;AAChC;AACA;EACE,MAAM;EACN,QAAQ;AACV;AACA;EACE,SAAS;EACT,OAAO;AACT;AACA;EACE,YAAY;EACZ,SAAS;EACT,yBAAyB;AAC3B","sourcesContent":["\n.box {\n  position: relative;\n  max-width: 600px;\n  width: 90%;\n  height: 400px;\n  background: #fff;\n  box-shadow: 0 0 15px rgba(0,0,0,.1);\n}\n\n/* common */\n.ribbon {\n  width: 150px;\n  height: 150px;\n  overflow: hidden;\n  position: absolute;\n}\n.ribbon::before,\n.ribbon::after {\n  position: absolute;\n  z-index: -1;\n  content: ' ';\n  display: block;\n  border: 5px solid #d0342c;\n}\n.ribbon span {\n  position: absolute;\n  display: block;\n  width: 225px;\n  padding: 15px 0;\n  background-color: #d0342c;\n  box-shadow: 0 5px 10px rgba(0,0,0,.1);\n  color: #fff;\n  font: 700 18px/1 'Lato', sans-serif;\n  text-shadow: 0 1px 1px rgba(0,0,0,.2);\n  text-transform: uppercase;\n  text-align: center;\n}\n\n/* top left*/\n.ribbon-top-left {\n  top: -10px;\n  left: -10px;\n}\n.ribbon-top-left::before,\n.ribbon-top-left::after {\n  border-top-color: transparent;\n  border-left-color: transparent;\n}\n.ribbon-top-left::before {\n  top: 0;\n  right: 0;\n}\n.ribbon-top-left::after {\n  bottom: 0;\n  left: 0;\n}\n.ribbon-top-left span {\n  right: -25px;\n  top: 30px;\n  transform: rotate(-45deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
