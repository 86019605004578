const initialState = {
  sidebarExpand: true,
  modalIsOpen: false,
  modalName: "",
  drawerExpand: false,
  drawerDirection: "",
  drawerName: "",
  isLoader: false,
  loginSuccessMessage: "",
  loginErrorMessage: "",
  registerSuccessMessage: "",
  registerErrorMessage: "",
  forgetPasswordSuccessMessage: "",
  forgetPasswordErrorMessage: "",
  changePasswordSuccessMessage: "",
  changePasswordErrorMessage: "",
  sendSuccessMessage: "",
  sendErrorMessage: "",
  isShimmer: false,
  rawSensorLoader: false,
  
};

interface updateSidebarStatus {
  type: "UPDATE_SIDEBAR_STATUS";
  payload: boolean;
}
interface updateModalStatus {
  type: "UPDATE_MODAL_STATUS";
  payload: boolean;
}
interface updateModalName {
  type: "UPDATE_MODAL_NAME";
  payload: string;
}
interface updateDrawerExpand {
  type: "UPDATE_DRAWER_EXPAND";
  payload: boolean;
}
interface updateDrawerDirection {
  type: "UPDATE_DRAWER_DIRECTION";
  payload: string;
}
interface updateLoaderState {
  type: "UPDATE_LOADER_STATE";
  payload: boolean;
}
interface updateLoginSuccessMessage {
  type: "UPDATE_LOGIN_SUCCESSS_MESSAGE";
  payload: string;
}
interface updateLoginErrorMessage {
  type: "UPDATE_LOGIN_ERROR_MESSAGE";
  payload: string;
}
interface updateRegisterSuccessMessage {
  type: "UPDATE_REGISTER_SUCCESSS_MESSAGE";
  payload: string;
}
interface updateRegisterErrorMessage {
  type: "UPDATE_REGISTER_ERROR_MESSAGE";
  payload: string;
}
interface updateforgetPasswordSuccessMessage {
  type: "UPDATE_FORGET_PASSWORD_SUCCESSS_MESSAGE";
  payload: string;
}
interface updateforgetPasswordErrorMessage {
  type: "UPDATE_FORGET_PASSWORD_ERROR_MESSAGE";
  payload: string;
}
interface resetRegisterErrorMessage {
  type: "RESET_REGISTER_ERROR_MESSAGE";
}
interface updateChangePasswordSuccessMessage {
  type: "CHANGE_PASSWORD_SUCCESS_MESSAGE";
  payload: string;
}
interface updateChanfePasswordErrorMessage {
  type: "CHANGE_PASSWORD_ERROR_MESSAGE";
  payload: string;
}
interface updateShimmerState {
  type: "UPDATE_SHIMMER_STATE";
  payload: boolean;
}
interface updateSendSuccessMessage {
  type: "UPDATE_SEND_SUCCESS_MESSAGE";
  payload: string;
}
interface updateSendErrorMessage {
  type: "UPDATE_SEND_ERROR_MESSAGE";
  payload: string;
}
interface updateRawSensorState {
  type: "UPDATE_RAW_SENSOR_STATE";
  payload: boolean;
}
interface updateDrawerName {
  type: "UPDATE_DRAWER_NAME";
  payload: string;
}
type action =
  | updateSidebarStatus
  | updateModalStatus
  | updateModalName
  | updateLoginSuccessMessage
  | updateLoginErrorMessage
  | updateRegisterSuccessMessage
  | updateRegisterErrorMessage
  | updateDrawerExpand
  | updateDrawerDirection
  | resetRegisterErrorMessage
  | updateLoaderState
  | updateforgetPasswordSuccessMessage
  | updateforgetPasswordErrorMessage
  | updateChangePasswordSuccessMessage
  | updateChanfePasswordErrorMessage
  | updateShimmerState
  | updateSendSuccessMessage
  | updateSendErrorMessage
  | updateRawSensorState
  | updateDrawerName;

const CommonReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case "UPDATE_SIDEBAR_STATUS":
      return {
        ...state,
        sidebarExpand: action.payload,
      };

    case "UPDATE_MODAL_STATUS":
      return {
        ...state,
        modalIsOpen: action.payload,
      };
    case "UPDATE_MODAL_NAME":
      return {
        ...state,
        modalName: action.payload,
      };
    case "UPDATE_DRAWER_EXPAND":
      return {
        ...state,
        drawerExpand: action.payload,
      };

    case "UPDATE_DRAWER_DIRECTION":
      return {
        ...state,
        drawerDirection: action.payload,
      };
    case "UPDATE_LOADER_STATE":
      return {
        ...state,
        isLoader: action.payload,
      };
    case "UPDATE_LOGIN_SUCCESSS_MESSAGE":
      return {
        ...state,
        loginSuccessMessage: action.payload,
      };

    case "UPDATE_LOGIN_ERROR_MESSAGE":
      return {
        ...state,
        loginErrorMessage: action.payload,
      };
    case "UPDATE_REGISTER_SUCCESSS_MESSAGE":
      return {
        ...state,
        registerSuccessMessage: action.payload,
      };

    case "UPDATE_REGISTER_ERROR_MESSAGE":
      return {
        ...state,
        registerErrorMessage: action.payload,
      };
    case "UPDATE_FORGET_PASSWORD_SUCCESSS_MESSAGE":
      return {
        ...state,
        forgetPasswordSuccessMessage: action.payload,
      };

    case "UPDATE_FORGET_PASSWORD_ERROR_MESSAGE":
      return {
        ...state,
        forgetPasswordErrorMessage: action.payload,
      };
    case "RESET_REGISTER_ERROR_MESSAGE": {
      return {
        ...state,
        registerErrorMessage: "",
      };
    }
    case "CHANGE_PASSWORD_SUCCESS_MESSAGE": {
      return {
        ...state,
        changePasswordSuccessMessage: action.payload,
      };
    }

    case "CHANGE_PASSWORD_ERROR_MESSAGE": {
      return {
        ...state,
        changePasswordErrorMessage: action.payload,
      };
    }
    case "UPDATE_SEND_SUCCESS_MESSAGE": {
      return {
        ...state,
        sendSuccessMessage: action.payload,
      };
    }

    case "UPDATE_SEND_ERROR_MESSAGE": {
      return {
        ...state,
        sendErrorMessage: action.payload,
      };
    }
    case "UPDATE_SHIMMER_STATE": {
      return {
        ...state,
        isShimmer: action.payload,
      };
    }
    case "UPDATE_RAW_SENSOR_STATE": {
      return {
        ...state,
        rawSensorLoader: action.payload,
      };
    }
    case "UPDATE_DRAWER_NAME": {
      return {
        ...state,
        drawerName: action.payload,
      };
    }
    default:
      return state;
  }
};
export default CommonReducer;

export const UpdateSidebarExpandStatus = (
  sidebarExpand: boolean
): updateSidebarStatus => {
  return {
    type: "UPDATE_SIDEBAR_STATUS",
    payload: sidebarExpand,
  };
};

export const UpdateModalOpenStatus = (
  modalIsOpen: boolean
): updateModalStatus => {
  return {
    type: "UPDATE_MODAL_STATUS",
    payload: modalIsOpen,
  };
};

export const UpdateModalName = (modalName: string): updateModalName => {
  return {
    type: "UPDATE_MODAL_NAME",
    payload: modalName,
  };
};

export const UpdateLoginSuccessMessage = (
  loginSuccessMessage: string
): updateLoginSuccessMessage => {
  return {
    type: "UPDATE_LOGIN_SUCCESSS_MESSAGE",
    payload: loginSuccessMessage,
  };
};

export const UpdateLoginErrorMessage = (
  loginErrorMessage: string
): updateLoginErrorMessage => {
  return {
    type: "UPDATE_LOGIN_ERROR_MESSAGE",
    payload: loginErrorMessage,
  };
};

export const UpdateRegisterSuccessMessage = (
  registerSuccessMessage: string
): updateRegisterSuccessMessage => {
  return {
    type: "UPDATE_REGISTER_SUCCESSS_MESSAGE",
    payload: registerSuccessMessage,
  };
};

export const UpdateRegisterErrorMessage = (
  registerErrorMessage: string
): updateRegisterErrorMessage => {
  return {
    type: "UPDATE_REGISTER_ERROR_MESSAGE",
    payload: registerErrorMessage,
  };
};
export const ResetRegisterErrorMessage = (): resetRegisterErrorMessage => {
  return {
    type: "RESET_REGISTER_ERROR_MESSAGE",
  };
};

export const UpdateDrawerExpand = (
  drawerExpand: boolean
): updateDrawerExpand => {
  return {
    type: "UPDATE_DRAWER_EXPAND",
    payload: drawerExpand,
  };
};

export const UpdateDrawerDirection = (
  drawerDirection: string
): updateDrawerDirection => {
  return {
    type: "UPDATE_DRAWER_DIRECTION",
    payload: drawerDirection,
  };
};
export const UpdateIsLoadingState = (isLoader: boolean): updateLoaderState => {
  return {
    type: "UPDATE_LOADER_STATE",
    payload: isLoader,
  };
};
export const UpdateForgetPasswordSuccessMessage = (
  forgetPasswordSuccessMessage: string
): updateforgetPasswordSuccessMessage => {
  return {
    type: "UPDATE_FORGET_PASSWORD_SUCCESSS_MESSAGE",
    payload: forgetPasswordSuccessMessage,
  };
};
export const UpdateForgetPasswordErrorMessage = (
  forgetPasswordErrorMessage: string
): updateforgetPasswordErrorMessage => {
  return {
    type: "UPDATE_FORGET_PASSWORD_ERROR_MESSAGE",
    payload: forgetPasswordErrorMessage,
  };
};

export const UpdateChangePasswordSuccessMessage = (
  changePasswordSuccessMessage: string
): updateChangePasswordSuccessMessage => {
  return {
    type: "CHANGE_PASSWORD_SUCCESS_MESSAGE",
    payload: changePasswordSuccessMessage,
  };
};

export const UpdateChangePasswordErrorMessage = (
  changePasswordErrorMessage: string
): updateChanfePasswordErrorMessage => {
  return {
    type: "CHANGE_PASSWORD_ERROR_MESSAGE",
    payload: changePasswordErrorMessage,
  };
};
export const UpdateSendSuccessMessage = (
  sendSuccessMessage: string
): updateSendSuccessMessage => {
  return {
    type: "UPDATE_SEND_SUCCESS_MESSAGE",
    payload: sendSuccessMessage,
  };
};

export const UpdateSendErrorMessage = (
  sendErrorMessage: string
): updateSendErrorMessage => {
  return {
    type: "UPDATE_SEND_ERROR_MESSAGE",
    payload: sendErrorMessage,
  };
};
export const UpdateShimmerState = (isShimmer: boolean): updateShimmerState => {
  return {
    type: "UPDATE_SHIMMER_STATE",
    payload: isShimmer,
  };
};
export const UpdateRawSensorLoader = (
  rawSensorLoader: boolean
): updateRawSensorState => {
  return {
    type: "UPDATE_RAW_SENSOR_STATE",
    payload: rawSensorLoader,
  };
};
export const UpdateDrawerName = (drawerName: string): updateDrawerName => {
  return {
    type: "UPDATE_DRAWER_NAME",
    payload: drawerName,
  };
};
