import { FormGroup, TextField } from "@mui/material";
import React from "react";
import Label from "../common/formInput/Label";

export default function SensorNameSetting({
  name,
  onNameChange = (text: string): any => {},
}) {
  return (
    <>
      <div>
        <Label label="Sensor Name" required={false} />
        <TextField
          fullWidth
          value={name}
          onChange={(e: any) => onNameChange(e.target.value)}
          className="sensorSettingInput"
        />
      </div>
    </>
  );
}
