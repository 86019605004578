// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageNotFoundContainer {
  padding: 40px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--white);
}
.errorText1 {
  font-size: 26px;
  font-weight: 600;
  line-height: 40px;
  color: var(--black);
  text-align: center;
}
.errorText2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #111111;
  text-align: center;
}
.homepageButton {
  padding: 10px 20px;
  width: max-content;
  color: #ffffff;
  background-color: var(--blue);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/pageNotFound/page404.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,6BAA6B;EAC7B,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".pageNotFoundContainer {\n  padding: 40px;\n  width: 100%;\n  margin: 0 auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background-color: var(--white);\n}\n.errorText1 {\n  font-size: 26px;\n  font-weight: 600;\n  line-height: 40px;\n  color: var(--black);\n  text-align: center;\n}\n.errorText2 {\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 18px;\n  color: #111111;\n  text-align: center;\n}\n.homepageButton {\n  padding: 10px 20px;\n  width: max-content;\n  color: #ffffff;\n  background-color: var(--blue);\n  border-radius: 4px;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
