import React, { useEffect, useState } from "react";
import firmware from "../../assets/images/locationSensorNew.svg";
import useFirmware from "../../hooks/serviceHandler/firmware";
import { useSelector } from "react-redux";
import { saveAs } from 'file-saver'

export default function FirmwarePreview(props: { imageData: any }) {
  const { GetImageDetailByIdServiceHandler } = useFirmware();


  const { selectedFolder } = useSelector((store: any) => store.firmware);
  const [data, setData] = useState({});
  // console.log('imageData',imageData?.imagePath);
  

  const getImageData = async () => {
    const res = await GetImageDetailByIdServiceHandler(
      "89148000001919240977",
      props.imageData?.image
    );
    setData(res);
  };


  useEffect(() => {
    getImageData();
  }, []);

  
  

  const downloadImage = () => {
    saveAs(`${props.imageData?.imagePath}`, `${props.imageData?.imagePath.split('/images/')[1]}`) // Put your image URL here.
  }

  return (
    <div className="flex flex-col items-center justify-center gap-5">
      <div className="w-40 h-full">
        <img src={props.imageData?.imagePath} alt="firmware" className="w-full h-full" />
      </div>
      <div className="flex justify-end w-full">
        <button onClick={downloadImage} className="primaryButtonFilled">Download</button>
      </div>
    </div>
  );
}
