import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import useDevicesServiceHandler from "../../hooks/serviceHandler/devices";
import useStripeServiceHandler from "../../hooks/serviceHandler/stripe";
import { ResetNewDeviceInfo } from "../../redux/reducer/devices";

export default function CheckoutPaymentForm({ deviceId, subscriptionId }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const { GetDeviceInfoByIdServiceHandler } = useDevicesServiceHandler();
  const { UpdatePaymentStatusServiceHandler } = useStripeServiceHandler();

  const [message, setMessage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [detailLoader, setDetailLoader] = useState(false);
  const { userInfo } = useSelector((store: any) => store.user);
  const { selectedPriceData } = useSelector((store: any) => store.stripe);
  const { selectedDevice, newDevice } = useSelector(
    (store: any) => store.device
  );

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSuccessfullPayment = async () => {
    const data = {
      email: userInfo?.email,
      deviceId: deviceId,
      amount: (selectedPriceData?.unit_amount / 100).toFixed(0),
      subscriptionId: subscriptionId,
      status: "paid",
    };
    await UpdatePaymentStatusServiceHandler(data);
    dispatch(ResetNewDeviceInfo());
    return window.location.origin;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js hasn't yet l oaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: await handleSuccessfullPayment(),
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    setIsLoading(false);
  };

  const paymentElementOptions: any = {
    layout: "tabs",
  };
  const deviceDetail = async () => {
    setDetailLoader(true);
    await GetDeviceInfoByIdServiceHandler(deviceId);
    setDetailLoader(false);
  };

  useEffect(() => {
    deviceDetail();
  }, []);

  return (
    <>
      {detailLoader ? (
        <div className="w-full flex items-center justify-center">
          <ClipLoader
            color={"#2047ab"}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="detail_summary">
          <p className="title">Summary</p>
          <div className="info">
            <p className="text1">Device Id</p>
            <p className="text2">
              {newDevice?.deviceId ? newDevice?.deviceId : selectedDevice?.id}
            </p>
          </div>
          <div className="info">
            <p className="text1">DEVICE NAME</p>
            <p className="text2">
              {selectedDevice?.name ? selectedDevice?.name : "Unnamed Device"}
            </p>
          </div>
          <div className="flex justify-start items-center">
            <div className="info w-6/12">
              <p className="text1">MODEL NAME</p>
              <p className="text2">{selectedDevice?.model_name}</p>
            </div>{" "}
            <div className="info w-6/12">
              <p className="text1">Subscription Choice</p>
              <p className="text2">{`$ ${(
                selectedPriceData?.unit_amount / 100
              ).toFixed(0)} ${selectedPriceData?.recurring?.interval}ly`}</p>
            </div>
          </div>
        </div>
      )}

      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button
          style={{ margin: "10px 10px 10px auto" }}
          className="primaryButtonFilled my-3"
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">
            {isLoading ? (
              <ClipLoader
                color={"#ffffff"}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              "Pay now"
            )}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </>
  );
}
