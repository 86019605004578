import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Page404 from "../components/common/pageNotFound/page404";
import Account from "../components/dashboard/Account";
import SensorListing from "../components/dashboard/SensorListing";
import component from "../constants/urls";
import Landing from "../pages/Landing";
import RegisterDevice from "../components/device/RegisterDevice";
import DevicesPage from "../pages/Devices";
import List from "../components/user/List";
import Add from "../components/user/Add";
import ManageDevices from "../pages/ManageDevices";
import FirmWare from "../pages/Firmware";
import ArchiveDeviceList from "../components/device/ArchiveDeviceList";
import RoleListing from "../components/user/RoleListing";
import AddRole from "../components/user/AddRole";
import InventoryDeviceList from "../components/device/InventoryDeviceList";
import { useSelector } from "react-redux";
import RegisterDeviceAdmin from "../components/device/RegisterDeviceAdmin";
import Notification from "../pages/Notification";
import AddDeviceInventory from "../components/device/AddDeviceInventory";
import HistoryAlert from "../pages/HistoryAlert";
import Logs from "../pages/Logs";
import FirmwareFolder from "../pages/PictureFolder";
import FirmwareImage from "../pages/PicturesImage";
import SensorDetailPage from "../components/sensor/SensorDetailsPage";
import PictureFolder from "../pages/PictureFolder";
import PicturesImage from "../pages/PicturesImage";
import FirmwareImages from "../pages/FirmwareImages";

export default function AuthRoute() {
  const { userInfo } = useSelector((store: any) => store.user);
  const { selectedFolder } = useSelector((store: any) => store.firmware);
  return (
    <Routes>
      <Route path={component.home.url} element={<Navigate to="dashboard" />} />
      <Route path={component.dashboard.url} element={<Landing />} />
      <Route path={component.devices.url} element={<DevicesPage />} />
      <Route path={component.history.url} element={<HistoryAlert />} />
      <Route path={component.account.url} element={<Account />} />
      <Route path={component.sensorListing.url} element={<SensorListing />} />
      {userInfo.role === "Super Admin" ? (
        <Route
          path={component.registerDevice.url}
          element={<RegisterDevice />}
        />
      ) : userInfo.role === "Admin" ? (
        <Route
          path={component.registerDevice.url}
          element={<RegisterDeviceAdmin />}
        />
      ) : (
        ""
      )}
      <Route path={component.firmware.url} element={<FirmWare />} />
      <Route path={component.userListing.url} element={<List />} />
      <Route path={component.createUser.url} element={<Add />} />
      <Route path={component.manageDevices.url} element={<ManageDevices />} />
      <Route path={component.roleList.url} element={<RoleListing />} />
      <Route path={component.createRole.url} element={<AddRole />} />
      <Route
        path={component.firmwareImage.url
        }
        element={<FirmwareImages />}
      />
      <Route path={component.firmwareFolder.url} element={<PictureFolder />} />
      <Route path={component.folder.url} element={<PicturesImage />} />
      <Route
        path={
          userInfo?.role === "Super Admin"
            ? component.inventoryDeviceList.url
            : component.totalDevices.url
        }
        element={<InventoryDeviceList />}
      />
      <Route
        path={component.archiveDevice.url}
        element={<ArchiveDeviceList />}
      />
      <Route path={component.notification.url} element={<Notification />} />
      <Route
        path={component.addDeviceInventory.url}
        element={<AddDeviceInventory />}
      />
      <Route path={component.logs.url} element={<Logs />} />
      <Route
        path={component.sensorDetailPage.url}
        element={<SensorDetailPage />}
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
