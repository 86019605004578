import * as React from "react";
import SensorSetting from "../../cards/SensorSetting";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../redux/store";
import { UpdateSelectedSensor } from "../../../redux/reducer/sensors";
import { sensor } from "../../../interface/reducer/sensor";

export default function SensorSettingModal() {
  const dispatch = useDispatch();
  const { selectedSensor } = useSelector((store: any) => store.sensor);
  const { selectedDevice } = useSelector((store: any) => store.device);
  const [offset, setOffset] = React.useState(selectedSensor?.manual_offset);

  return (
    <>
      <SensorSetting
        sensor={selectedSensor}
        onSensorChange={(sensor: sensor) =>
          dispatch(UpdateSelectedSensor(sensor))
        }
        offset={offset}
        onOffsetChange={(offset: any) => setOffset(offset)}
        supportsOutputLines={selectedDevice?.supports_output_lines}
        outputLineNames={[
          selectedDevice?.output_line_name_1,
          selectedDevice?.output_line_name_2,
          selectedDevice?.output_line_name_3,
          selectedDevice?.output_line_name_4,
          selectedDevice?.output_line_name_5,
          selectedDevice?.output_line_name_6,
          selectedDevice?.output_line_name_7,
          selectedDevice?.output_line_name_8,
        ]}
      />
    </>
  );
}
