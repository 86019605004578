import axios from "axios";
import { getAuthHeader, getAuthHeaderImg } from "../constants/constant";

export const GetFirmWareDataService = () => {
  const url = `${process.env.REACT_APP_URL}v1/Firmware_version`;
  return axios.get(url, getAuthHeader());
};
export const UpdateFirmwareDataService = (id: string, data: any) => {
  const url = `${process.env.REACT_APP_URL}v1/update/Firmware_version/${id}`;
  return axios.patch(url, data, getAuthHeader());
};

export const GetFirmwareFolderService = () => {
  const url = `${process.env.REACT_APP_URL}v1/picture/getFolder`;
  return axios.get(url);
};

export const GetFirmwareFolderImagesService = (folderName: string) => {
  const url = `${process.env.REACT_APP_URL}v1/pictures/${folderName}`;
  return axios.get(url);
};

export const PostFirmwareImageService = (formdata : any) => {
  const url = `${process.env.REACT_APP_URL}v1/firmware-post`;
  console.log(url, formdata);
  
  return axios.post(url, formdata, getAuthHeaderImg())
}
export const GetAllFirmwareImagesService = () => {
  const url = `${process.env.REACT_APP_URL}v1/get-firmware-images`;
  console.log('url-->', url);
  
  return axios.get(url,getAuthHeader());
};

///api/v1/delete-firmware-image/
export const DeleteFirmwareImagesService = (imageId: string) => {
  const url = `${process.env.REACT_APP_URL}v1/delete-firmware-image/${imageId}`
  console.log('url', url);
  return axios.put(url,{}, getAuthHeader())
  
};


export const GetImageDetailByIdService = (
  folderName: string,
  fileName: string
) => {
  const url = `${process.env.REACT_APP_URL}v1/pictures/${folderName}/${fileName}`;
  return axios.get(url);
};
