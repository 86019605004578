import React from "react";
import { useSelector } from "react-redux";

export default function LogsDetail() {
  const { selectedLogDetail } = useSelector((store: any) => store.log);
  const logsItemList = selectedLogDetail?.logsData?.split("\n");

  return (
    <div className="flex flex-col gap-10 p-8 historyDrawer">
      <div>
        <p className="historyAlertHeading mb-4">
          {selectedLogDetail.logType === "postStatus"
            ? "Device Status Logs"
            : selectedLogDetail.logType === "alert"
            ? "Device Alert Logs"
            : ""}
        </p>
        <div>
          <p className="history_device_name capitalize">
            Log Type - {selectedLogDetail?.logType}
          </p>
          <p className="history_device_name">
            Log Time - {selectedLogDetail?.time}
          </p>
        </div>
      </div>

      <div>
        <div className="flex-col gap-3">
          <div className=" flex justify-between items-start">
            <p className="history_device_name">
              {selectedLogDetail?.deviceName
                ? selectedLogDetail?.deviceName
                : "Unnamed Device"}
            </p>
            <p className="history_device_id">
              {selectedLogDetail?.deviceId ? selectedLogDetail?.deviceId : ""}
            </p>
          </div>
          <p className="history_device_model">
            {selectedLogDetail?.model ? selectedLogDetail?.model : "NA"}
          </p>
        </div>
        <div className="logs_data mt-5">
          {logsItemList?.map((item, index) => {
            return (
              <p className="block" key={index}>
                {item}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
}
