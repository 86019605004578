import { Button, CircularProgress } from "@mui/material";
import React from "react";

export default function SaveOrDiscardSensorSettings({
  onDiscardClick,
  onSaveClick,
  saving,
}) {
  return (
    <div className="flex gap-5 justify-end items-center">
      {saving ? (
        <CircularProgress />
      ) : (
        <>
          <Button
            disableElevation
            onClick={onDiscardClick}
            variant="contained"
            className="borderedButton"
          >
            Discard Changes
          </Button>

          <Button
            onClick={onSaveClick}
            disableElevation
            variant="contained"
            className="primaryButtonFilled"
          >
            Save Changes
          </Button>
        </>
      )}
    </div>
  );
}
