import {
  AddDeviceInventoryService,
  ChangeDeviceActiveStateSerice,
  DeleteDeviceService,
  FilterDeviceListService,
  GetArchiveDevicesListService,
  GetAssignedDeviceInfoService,
  // GetConnectedDeviceListService,
  GetDelegatedDevicesListService,
  GetDeviceByIdService,
  GetDevicesListService,
  RegisterNewDeviceService,
  SendDeviceMessageInfoService,
  ToggleArchiveStatusService,
  UpdateDeviceGPSService,
  UpdateDeviceSettingService,
  UploadDeviceCsvFieService,
} from "../../service/devices";
import { useDispatch, useSelector } from "react-redux";
// import { UpdateIsLoadingState } from "../../redux/reducers/common";
import {
  ResetNewDeviceInfo,
  ResetUpdateNewDeviceFormError,
  UpdateAddDeviceErrorMessage,
  UpdateAddDeviceSuccessMessage,
  UpdateArchiveDeviceList,
  UpdateAssignedDeviceInfo,
  UpdateDelegatedDeviceList,
  UpdateDeviceList,
  UpdateSelectedDevice,
} from "../../redux/reducer/devices";
import {
  AddDeviceParams,
  RegisterDeviceParams,
} from "../../interface/services/Device";
import { DeviceSetting } from "../../interface/reducer/device";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import {
  UpdateSendErrorMessage,
  UpdateSendSuccessMessage,
  UpdateShimmerState,
} from "../../redux/reducer/commonReducer";
import SendMessage from "../../components/device/SendMessage";
import { toast } from "react-toastify";
import swal from "sweetalert";

const useDevicesServiceHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((store: any) => store.user);
  const { selectedDevice, newDevice } = useSelector(
    (state: any) => state.device
  );

  const GetDevicesListServiceHandler = async () => {
    try {
      dispatch(UpdateShimmerState(true));
      const res = await GetDevicesListService(userInfo?.id);
      dispatch(UpdateShimmerState(false));
      const { count, rows } = res.data.result;

      if (rows?.length) {
        dispatch(UpdateDeviceList([...rows]));
        return rows;
      } else {
        dispatch(UpdateDeviceList([]));
      }
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
      // alert("Error while fetching the devices list !");
      dispatch(UpdateShimmerState(false));
    }
  };

  const GetDelegatedDevicesListServiceHandler = async () => {
    try {
      //   dispatch(UpdateIsLoadingState(true));
      const res = await GetDelegatedDevicesListService();
      //   dispatch(UpdateIsLoadingState(false));
      const { data } = res.data;
      if (data?.length) {
        dispatch(UpdateDeviceList([...data]));
      } else {
        dispatch(UpdateDeviceList([]));
      }
    } catch (error: any) {
      //   dispatch(UpdateIsLoadingState(false));
      //   Alert.alert("", "Error while fetching the devices list !");
    }
  };

  const DeleteDeviceServiceHandler = async () => {
    try {
      //   dispatch(UpdateIsLoadingState(true));
      await DeleteDeviceService(userInfo?.id, selectedDevice?.id);
      await GetDevicesListServiceHandler();
      //   dispatch(UpdateIsLoadingState(true));
      //   Alert.alert("", "Device Deleted Successfully !");
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
      //   dispatch(UpdateIsLoadingState(true));
      //   Alert.alert("", "Error while deleting this device !");
    }
  };

  const ArchivedDevicesListServiceHandler = async () => {
    try {
      const res = await GetArchiveDevicesListService();
      const { count, rows } = res.data.result;

      if (rows?.length) {
        dispatch(UpdateArchiveDeviceList([...rows]));
        return rows;
      } else {
        dispatch(UpdateArchiveDeviceList([]));
      }
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  const RegisterNewDeviceServiceHandler = async (
    data: RegisterDeviceParams
  ) => {
    try {
      await RegisterNewDeviceService(data);
      await GetDevicesListServiceHandler();
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  const GetDeviceInfoByIdServiceHandler = async (deviceId: string) => {
    try {
      const res = await GetDeviceByIdService(deviceId);

      const { result } = res.data;
      if (result) {
        dispatch(UpdateSelectedDevice(result));
      }
    } catch (error) {
      // Alert.alert("", "Unable to get the device information.");
    }
  };

  const ChangeDeviceActiveStatusSeriveHandler = async (deviceId: string) => {
    try {
      await ChangeDeviceActiveStateSerice(deviceId);
      await GetDevicesListServiceHandler();
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
      // swal(
      //   "Oops!",
      //   `${error?.response?.data?.error?.message} \n Token is expired`,
      //   "error"
      // );
    }
  };
  const ChangeDeviceArchiveStatusServiceHandler = async (deviceId: string) => {
    try {
      await ToggleArchiveStatusService(deviceId);
      await GetDevicesListServiceHandler();
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };
  const UpdateDeviceSettingServiceHandler = async (
    deviceSetting: DeviceSetting
  ) => {
    try {
      await UpdateDeviceSettingService(selectedDevice?.id, deviceSetting);
      // toast.success("The device has been updated.", { autoClose: 1000 });
      await GetDeviceInfoByIdServiceHandler(selectedDevice?.id);
      await GetDevicesListServiceHandler();
    } catch (error: any) {
      toast.success(error?.response?.data?.error?.message, { autoClose: 2000 });
    }
  };

  const UploaDeviceCsvFileServiceHandler = async (file: any) => {
    try {
      const res = await UploadDeviceCsvFieService(file);
      const { result } = res?.data;
      if (result) {
        dispatch(UpdateAddDeviceSuccessMessage("Devices Added Successfully"));
        setTimeout(() => {
          navigate(component.inventoryDeviceList.url);
          dispatch(ResetNewDeviceInfo());
          dispatch(UpdateAddDeviceSuccessMessage(""));
          dispatch(UpdateAddDeviceErrorMessage(""));
        }, 1000);
      } else {
        dispatch(ResetUpdateNewDeviceFormError());
        navigate(component.inventoryDeviceList.url);
        dispatch(ResetNewDeviceInfo());
        dispatch(UpdateAddDeviceSuccessMessage(""));
        dispatch(UpdateAddDeviceErrorMessage(""));
      }
      await GetDevicesListServiceHandler();
    } catch (error: any) {
      dispatch(
        UpdateAddDeviceErrorMessage(error?.response?.data?.error?.message)
      );
      setTimeout(() => {
        dispatch(UpdateAddDeviceSuccessMessage(""));
        dispatch(UpdateAddDeviceErrorMessage(""));
        dispatch(ResetUpdateNewDeviceFormError());
      }, 1000);
    }
  };

  const AddDeviceServiceHandler = async () => {
    const data: AddDeviceParams = {
      id: newDevice?.deviceId,
      subscriptionMonthly: newDevice?.subscriptionMonthly,
      subscriptionYearly: newDevice?.subscriptionYearly,
      model_definition: newDevice?.model_definition,
    };
    try {
      const res = await AddDeviceInventoryService(userInfo?.access_token, data);
      const { result, msg } = res?.data;
      if (result) {
        dispatch(UpdateAddDeviceSuccessMessage("Device Added Successfully"));
        setTimeout(() => {
          navigate(component.inventoryDeviceList.url);
          dispatch(ResetNewDeviceInfo());
          dispatch(UpdateAddDeviceSuccessMessage(""));
          dispatch(UpdateAddDeviceErrorMessage(""));
        }, 1000);
      } else {
        dispatch(ResetUpdateNewDeviceFormError());
        navigate(component.inventoryDeviceList.url);
        dispatch(ResetNewDeviceInfo());
        dispatch(UpdateAddDeviceSuccessMessage(""));
        dispatch(UpdateAddDeviceErrorMessage(""));
      }
    } catch (error: any) {
      dispatch(
        UpdateAddDeviceErrorMessage(error?.response?.data?.error?.message)
      );
      setTimeout(() => {
        dispatch(UpdateAddDeviceSuccessMessage(""));
        dispatch(UpdateAddDeviceErrorMessage(""));
        dispatch(ResetUpdateNewDeviceFormError());
      }, 1000);
    }
  };

  const GetAssignedDeviceInfoServiceHandler = async (deviceId: string) => {
    try {
      const res = await GetAssignedDeviceInfoService(deviceId);
      const { result } = res?.data;
      if (result) {
        dispatch(UpdateAssignedDeviceInfo(result));
      }
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  const UpdateSendDeviceMessageServiceHandler = async (
    deviceId: string,
    Data: any
  ) => {
    try {
      const res = await SendDeviceMessageInfoService(deviceId, Data);
      const { result } = res?.data;
      if (result) {
        dispatch(UpdateSendSuccessMessage("Message Sent"));
        setTimeout(() => {
          dispatch(UpdateSendSuccessMessage(""));
          dispatch(UpdateSendErrorMessage(""));
        }, 2000);
      }
    } catch (error: any) {
      dispatch(UpdateSendErrorMessage(error?.response?.data?.error?.message));
      setTimeout(() => {
        dispatch(UpdateSendSuccessMessage(""));
        dispatch(UpdateSendErrorMessage(""));
      }, 2000);
    }
  };
  const UpdateDeviceGpsServiceHandler = async (
    deviceId: string,
    settings: any
  ) => {
    try {
      await UpdateDeviceGPSService(deviceId, settings);
      toast.success("Device location has been updated. !");
      await GetDeviceInfoByIdServiceHandler(deviceId);
      await GetDevicesListServiceHandler();
    } catch (error) {
      console.log(error, "error");
      toast.error("Sorry, an error occurred updating the device location.");
    }
  };
  const GetFilteredDeviceListServiceHandler = async (query: any) => {
    try {
      const res = await FilterDeviceListService(query);
      const { rows } = res?.data?.result;
      if (rows?.length) {
        dispatch(UpdateDeviceList([...rows]));
        return rows;
      } else {
        dispatch(UpdateDeviceList([]));
      }
    } catch (error) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  return {
    GetDevicesListServiceHandler,
    DeleteDeviceServiceHandler,
    GetDelegatedDevicesListServiceHandler,
    ArchivedDevicesListServiceHandler,
    RegisterNewDeviceServiceHandler,
    ChangeDeviceActiveStatusSeriveHandler,
    GetDeviceInfoByIdServiceHandler,
    ChangeDeviceArchiveStatusServiceHandler,
    UpdateDeviceSettingServiceHandler,
    UploaDeviceCsvFileServiceHandler,
    AddDeviceServiceHandler,
    GetAssignedDeviceInfoServiceHandler,
    UpdateSendDeviceMessageServiceHandler,
    UpdateDeviceGpsServiceHandler,
    GetFilteredDeviceListServiceHandler,
  };
};

export default useDevicesServiceHandler;
