import React from "react";
import component from "../../constants/urls";
import Label from "../common/formInput/Label";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import useAuthServiceHandler from "../../hooks/serviceHandler/Auth";
import useForgetPasswordValidation from "../../hooks/validations/ForgetPassword";
import { useDispatch, useSelector } from "react-redux";
import {
  ResetForgetPassowrdFormError,
  ResetForgetPasswordInputs,
  UpdateForgetPasswordInputs,
} from "../../redux/reducer/auth";
import SuccessMessage from "../common/alert/SuccessMessage";
import ErrorMessage from "../common/alert/ErrorMessage";
import {
  UpdateForgetPasswordErrorMessage,
  UpdateForgetPasswordSuccessMessage,
} from "../../redux/reducer/commonReducer";
import logo from "../../assets/images/cabin-tech-logo.svg";

export default function ForgetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { ForgetPasswordServiceHandler } = useAuthServiceHandler();
  const {
    ForgetPasswordFormValidation,
    OnChangeForgetPasswordFormValueValidation,
  } = useForgetPasswordValidation();

  const { forgetPassword, forgetPasswordFormError } = useSelector(
    (state: any) => state.auth
  );
  const { forgetPasswordSuccessMessage, forgetPasswordErrorMessage } =
    useSelector((state: any) => state.common);

  const handleRoute = (url: string) => {
    navigate(url);
    dispatch(ResetForgetPasswordInputs());
    dispatch(UpdateForgetPasswordSuccessMessage(""));
    dispatch(UpdateForgetPasswordErrorMessage(""));
    dispatch(ResetForgetPassowrdFormError());
  };

  const handleChange = (key: "email", value: string) => {
    dispatch(UpdateForgetPasswordInputs({ key, value }));
    OnChangeForgetPasswordFormValueValidation({ id: key, value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const isValid = ForgetPasswordFormValidation();
    if (isValid) {
      ForgetPasswordServiceHandler();
    }
  };

  return (
    <div className="authContainer">
      <div className="logo mb-4">
        <img src={logo} alt="logo" />
      </div>
      <div className="loginContainer">
        <p className="login-heading">Forget Password?</p>

        <form action="" className="w-full flex flex-col gap-5">
          {forgetPasswordSuccessMessage ? (
            <SuccessMessage msg={forgetPasswordSuccessMessage} />
          ) : forgetPasswordErrorMessage ? (
            <ErrorMessage msg={forgetPasswordErrorMessage} />
          ) : (
            ""
          )}
          <div className="">
            <Label label="Email" required={true} />
            <input
              className="registerFormInput"
              type="text"
              name="email"
              id="email"
              value={forgetPassword?.email}
              onChange={(e) => handleChange("email", e.target.value)}
            />
            <p className="errorMessage">{forgetPasswordFormError?.email}</p>
          </div>
          <div>
            <input
              type="submit"
              value="Submit"
              className="mt-4 submitButton"
              onClick={handleSubmit}
            />
          </div>
        </form>
        <p className="login-text-msg">
          <a
            onClick={() => handleRoute(component.login.url)}
            className="flex justify-center items-center gap-1"
          >
            <BiArrowBack fontSize={20} color="#2047ab" />{" "}
            <span>Go To Login Page</span>
          </a>
        </p>
      </div>
    </div>
  );
}
