import React from "react";
import Label from "../common/formInput/Label";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";

export default function DeviceInfoSettingCard({
  deviceId,
  audible,
  flashingButtonsEnabled,
  statusIndicatorEnabled,
  cameraEnabled,
  motionEnabled,
  name,
  armed,
  onNameChange,
  onArmedChange = (arg: any) => {},
  onAudibleChange,
  onFlashingButtonsEnabledChange,
  onStatusIndicatorEnabledChange,
  onCameraEnabledChange,
  onMotionEnabledChange,
  updatedAt,
  lastCheckIn,
}) {
  return (
    <>
      <div className="allDeviceCardContainer cardBoxShadow">
        <div className="flex justify-between items-center mb-4">
          <p className="deviceCardHeading mb-3">Settings</p>
          <button
            onClick={() => onArmedChange(!armed)}
            className={armed ? "dangerButton" : "secondaryButton"}
          >
            {armed ? "Disarm Device" : "Arm Device"}
          </button>
        </div>
        <div className="mb-3">
          <Label label="Device Name" required={false} />
          <form action="">
            <TextField
              placeholder="Enter Device Name"
              fullWidth
              value={name}
              onChange={(e) => onNameChange(e.target.value)}
              // className="inputFieldMsg"
            />
            {/* <input
              type="text"
              name="name"
              id="name"
              placeholder="Enter Device Name"
              className="inputFieldMsg"
              value={deviceInfoSetting?.name}
              onChange={handleChange}
            /> */}
          </form>
        </div>
        <FormControl component="fieldset">
          <Label label="Device Flags" required={false} />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={audible}
                  onChange={onAudibleChange}
                  name="audible"
                  color="primary"
                />
              }
              label="1"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={flashingButtonsEnabled}
                  onChange={onFlashingButtonsEnabledChange}
                  name="flashing_buttons_enabled"
                  color="primary"
                />
              }
              label="2"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={statusIndicatorEnabled}
                  onChange={onStatusIndicatorEnabledChange}
                  name="status_indicator_enabled"
                  color="primary"
                />
              }
              label="3"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={cameraEnabled}
                  onChange={onCameraEnabledChange}
                  name="camera_enabled"
                  color="primary"
                />
              }
              label="4"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={motionEnabled}
                  onChange={onMotionEnabledChange}
                  name="motion_enabled"
                  color="primary"
                />
              }
              label="5"
            />
          </FormGroup>
        </FormControl>
        {/* <div>
          <Label label="Device Flags" required={false} />
          <ul className="flex flex-col gap-2">
            <li>
              <CheckboxInput
                value={deviceInfoSetting?.ct_disable_audible}
                label="1"
                id={"ct_disable_audible"}
                deviceInfoSetting={deviceInfoSetting}
                setDeviceInfoSetting={setDeviceInfoSetting}
              />
            </li>
            <li>
              <CheckboxInput
                value={deviceInfoSetting?.ct_disable_flashing_buttons}
                label="2"
                id={"ct_disable_flashing_buttons"}
                deviceInfoSetting={deviceInfoSetting}
                setDeviceInfoSetting={setDeviceInfoSetting}
              />
            </li>
            <li>
              <CheckboxInput
                value={deviceInfoSetting?.ct_disable_status_indicator}
                label="3"
                id={"ct_disable_status_indicator"}
                deviceInfoSetting={deviceInfoSetting}
                setDeviceInfoSetting={setDeviceInfoSetting}
              />
            </li>
            <li>
              <CheckboxInput
                value={deviceInfoSetting?.ct_disable_camera}
                label="4"
                id={"ct_disable_camera"}
                deviceInfoSetting={deviceInfoSetting}
                setDeviceInfoSetting={setDeviceInfoSetting}
              />
            </li>
            <li>
              <CheckboxInput
                value={deviceInfoSetting?.ct_disable_motion_armed}
                label="5"
                id={"ct_disable_motion_armed"}
                deviceInfoSetting={deviceInfoSetting}
                setDeviceInfoSetting={setDeviceInfoSetting}
              />
            </li>
          </ul>
        </div> */}
      </div>
    </>
  );
}
