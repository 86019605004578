import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDevicesServiceHandler from "../../hooks/serviceHandler/devices";
import SuccessMessage from "../common/alert/SuccessMessage";
import ErrorMessage from "../common/alert/ErrorMessage";

export default function SendMessage() {
  const dispatch = useDispatch();

  const { UpdateSendDeviceMessageServiceHandler } = useDevicesServiceHandler();
  const { selectedDevice } = useSelector((store: any) => store.device);
  const { sendSuccessMessage, sendErrorMessage } = useSelector(
    (store: any) => store.common
  );
  const [messageData, setMessageData] = useState({
    address: selectedDevice?.address,
    message: "",
  });
  const [error, setError] = useState(false);

  const onChangeHandler = (e: any) => {
    const { name, value } = e.target;
    setMessageData({
      ...messageData,
      [name]: value,
    });
  };

  const handleMessageSubmit = async (e: any) => {
    e.preventDefault();
    if (messageData.address.length === 0) {
      setError(true);
    } else {
      setError(false);
      await UpdateSendDeviceMessageServiceHandler(
        selectedDevice?.id,
        messageData
      );
    }
  };

  return (
    <div className="allDeviceCardContainer cardBoxShadow">
      <div className="flex justify-between items-center mb-4">
        <p className="deviceCardHeading">Send Messages</p>
      </div>
      <div className="mb-3">
        {sendSuccessMessage ? (
          <SuccessMessage msg={sendSuccessMessage} />
        ) : sendErrorMessage ? (
          <ErrorMessage msg={sendErrorMessage} />
        ) : (
          ""
        )}
      </div>
      <div>
        <form action="" className="grid grid-cols-1 gap-5">
          <div>
            <input
              type="text"
              name="address"
              id="address"
              value={messageData?.address}
              placeholder="Address"
              className="inputFieldMsg"
              onChange={onChangeHandler}
            />
            {error && (
              <p className="errorMessage">{`Format as <IP>:<PORT> e.g. 192.168.1.1:8000`}</p>
            )}
          </div>
          <input
            type="text"
            name="message"
            id="message"
            placeholder="Message"
            value={messageData?.message}
            className="inputFieldMsg"
            onChange={onChangeHandler}
          />
          <button
            className="primaryButtonFilled mt-10"
            onClick={handleMessageSubmit}
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
}
