import { role } from "../../interface/reducer/role";

const initialState = {
  newRole: {
    name: "",
    description: "",
    permissions: [],
    sendNotification: false,
    sendEmail: false,
    sendSMS: false,
  },
  RoleFormError: {
    name: "",
    description: "",
    permissions: "",
    sendNotification: false,
    sendEmail: false,
    sendSMS: false,
  },
  roleList: [],
  newRoleSuccessMessage: "",
  newRoleErrorMessage: "",
};

export interface updateNewRolePermissions {
  type: "UPDATE_NEW_ROLE_PERMISSIONS";
  payload: Array<string>;
}

export interface newRoleData {
  key:
    | "name"
    | "description"
    | "permissions"
    | "sendNotification"
    | "sendEmail"
    | "sendSMS";
  value: string | boolean;
}

interface updateNewRoleInfo {
  type: "UPDATE_NEW_ROLE";
  payload: newRoleData;
}
interface resetNewRoleInfo {
  type: "RESET_NEW_ROLE_INFO";
}
interface updateNewRoleError {
  type: "UPDATE_NEW_DEVICE_FORM_ERROR";
  payload: newRoleData;
}
interface resetNewDeviceFormError {
  type: "RESET_NEW_DEVICE_FORM_ERROR";
}
interface updateRoleList {
  type: "UPDATE_ROLE_LIST";
  payload: Array<any>;
}
interface updateNewRoleSuccessMessage {
  type: "UPDATE_NEW_ROLE_SUCCESS_MESSAGE";
  payload: string;
}
interface resetNewRoleSuccessMessage {
  type: "RESET_NEW_ROLE_SUCCESS_MESSAGE";
}
interface updateNewRoleErrorMessage {
  type: "UPDATE_NEW_ROLE_ERROR_MESSAGE";
  payload: string;
}
interface resetNewRoleErrorMessage {
  type: "RESET_NEW_ROLE_ERROR_MESSAGE";
}

interface editNewRoleInfo {
  type: "EDIT_NEW_ROLE_INFO";
  payload: role;
}

type action =
  | updateNewRoleInfo
  | updateNewRoleError
  | resetNewDeviceFormError
  | updateRoleList
  | resetNewRoleInfo
  | updateNewRolePermissions
  | updateNewRoleSuccessMessage
  | resetNewRoleSuccessMessage
  | updateNewRoleErrorMessage
  | resetNewRoleErrorMessage
  | editNewRoleInfo;

const RoleReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case "UPDATE_NEW_ROLE": {
      return {
        ...state,
        newRole: {
          ...state.newRole,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case "RESET_NEW_ROLE_INFO": {
      return {
        ...state,
        newRole: {
          name: "",
          description: "",
          permissions: [],
          sendNotification: false,
          sendEmail: false,
          sendSMS: false,
        },
      };
    }
    case "UPDATE_NEW_DEVICE_FORM_ERROR": {
      return {
        ...state,
        RoleFormError: {
          ...state.RoleFormError,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case "RESET_NEW_DEVICE_FORM_ERROR": {
      return {
        ...state,
        RoleFormError: {
          name: "",
          description: "",
          permissions: "",
          sendNotification: false,
          sendEmail: false,
          sendSMS: false,
        },
      };
    }
    case "UPDATE_ROLE_LIST": {
      return {
        ...state,
        roleList: [...action.payload],
      };
    }
    case "UPDATE_NEW_ROLE_PERMISSIONS": {
      return {
        ...state,
        newRole: {
          ...state.newRole,
          permissions: [...action.payload],
        },
      };
    }
    case "UPDATE_NEW_ROLE_SUCCESS_MESSAGE": {
      return {
        ...state,
        newRoleSuccessMessage: action.payload,
      };
    }
    case "RESET_NEW_ROLE_SUCCESS_MESSAGE": {
      return {
        ...state,
        newRoleSuccessMessage: "",
      };
    }

    case "UPDATE_NEW_ROLE_ERROR_MESSAGE": {
      return {
        ...state,
        userManagementErrorMessage: action.payload,
      };
    }

    case "RESET_NEW_ROLE_ERROR_MESSAGE": {
      return {
        ...state,
        userManagementErrorMessage: "",
      };
    }

    case "EDIT_NEW_ROLE_INFO": {
      return {
        ...state,
        newRole: {
          ...action.payload,
          permissions: action.payload?.permissions?.map(
            (item: any) => item?.id
          ),
        },
      };
    }

    default:
      return state;
  }
};
export default RoleReducer;

export const UpdateNewRoleInfo = (data: newRoleData): updateNewRoleInfo => {
  return {
    type: "UPDATE_NEW_ROLE",
    payload: data,
  };
};
export const ResetNewRoleInfo = (): resetNewRoleInfo => {
  return {
    type: "RESET_NEW_ROLE_INFO",
  };
};
export const UpdateNewRoleFormError = (
  data: newRoleData
): updateNewRoleError => {
  return {
    type: "UPDATE_NEW_DEVICE_FORM_ERROR",
    payload: data,
  };
};

export const ResetNewRoleError = (): resetNewDeviceFormError => {
  return {
    type: "RESET_NEW_DEVICE_FORM_ERROR",
  };
};

export const UpdateRoleList = (roleList: Array<any>): updateRoleList => {
  return {
    type: "UPDATE_ROLE_LIST",
    payload: roleList,
  };
};
export const UpdateNewRolePermission = (
  permissions: Array<string>
): updateNewRolePermissions => {
  return {
    type: "UPDATE_NEW_ROLE_PERMISSIONS",
    payload: permissions,
  };
};

export const UpdateNewRoleSuccessMessage = (
  userManagementSuccessMessage: string
): updateNewRoleSuccessMessage => {
  return {
    type: "UPDATE_NEW_ROLE_SUCCESS_MESSAGE",
    payload: userManagementSuccessMessage,
  };
};
export const ResetNewRoleSuccessMessgae = (): resetNewRoleSuccessMessage => {
  return {
    type: "RESET_NEW_ROLE_SUCCESS_MESSAGE",
  };
};
export const UpdateNewRoleErrorMessage = (
  userManagementErrorMessage: string
): updateNewRoleErrorMessage => {
  return {
    type: "UPDATE_NEW_ROLE_ERROR_MESSAGE",
    payload: userManagementErrorMessage,
  };
};

export const ResetNewRoleErrorMessage = (): resetNewRoleErrorMessage => {
  return {
    type: "RESET_NEW_ROLE_ERROR_MESSAGE",
  };
};

export const UpdateEditRoleInfo = (role: role) => {
  return {
    type: "EDIT_NEW_ROLE_INFO",
    payload: role,
  };
};
