export const PHONE_REGEX = /^[6-9]\d{9}$/;
export const NAME_REGEX = /^[a-zA-Z\s'-]{1,50}$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[^\s]{8,}$/;
export const NUMBER_REGEX = /^\d+$/;
export const NUMBER_ONLY_REGEX = /^[0-9]+$/;
export const errorMessage = {
  required: "This field is required",
  mobileNumber: "Enter a valid mobile number",
  name: "Enter valid name",
  email: "Enter valid email",
  password: "Password must be 8 character",
};

// export const R_EMAIL =
//   /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const R_DEVICE_ID = /^[a-fA-F0-9]{20}$/;

export const GENERIC_ANALOG_SENSORS = [
  "07",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
];

export const setStorage = (key: string, jsonObj: any) => {
  localStorage.setItem(key, JSON.stringify(jsonObj));
};

export const getStorage = (keyName: string) => {
  const item = localStorage.getItem(keyName);
  return JSON.parse(item);
};

export const getAuthHeader = () => {
  const token = getStorage("token");
  const header = {
    headers: {
      
      "x-auth-token": token,
    },
  };
  return header;
};
export const getAuthHeaderImg = () => {
  const token = getStorage("token");
  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": token,
    },
  };
  return header;
};
