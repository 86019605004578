import { combineReducers } from "redux";
import AuthReducer from "./auth";
import CommonReducer from "./commonReducer";
import DeviceReducer from "./devices";
import RoleReducer from "./role";
import SensorReducer from "./sensors";
import UserReducer from "./user";
import UserManagement from "./userManagement";
import PermissionReducer from "./permissions";
import StripeReducer from "./stripe";
import FirmwareReducer from "./firmware";
import NotificationReducer from "./notification";
import LogsReducer from "./logs";

const appReducer = combineReducers({
  auth: AuthReducer,
  common: CommonReducer,
  user: UserReducer,
  device: DeviceReducer,
  sensor: SensorReducer,
  role: RoleReducer,
  userManagement: UserManagement,
  permission: PermissionReducer,
  stripe: StripeReducer,
  firmware: FirmwareReducer,
  notification: NotificationReducer,
  log: LogsReducer,
});

export const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};
