import React, { useEffect, useState } from "react";
import {
  UpdateModalName,
  UpdateModalOpenStatus,
} from "../../redux/reducer/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import useFirmware from "../../hooks/serviceHandler/firmware";
import { ClipLoader } from "react-spinners";
import { Data } from "@react-google-maps/api";

export default function Images(props: { setImageData: any }) {
  const dispatch = useDispatch();
  const { GetFirmwareFolderImagesServiceHandler } = useFirmware();
  const { selectedFolder } = useSelector((store: any) => store.firmware);
  const [imageList, setImageList] = useState([]);
  
  
  const [loader, setLoader] = useState(false);

  const openModal = (modalName: string, data: any, e: any) => {
    
    
    e.preventDefault();
    props?.setImageData(data);
    dispatch(UpdateModalName(modalName));
    dispatch(UpdateModalOpenStatus(true));
  };

  const getAllImages = async () => {
    setLoader(true);
    const data = await GetFirmwareFolderImagesServiceHandler(
      selectedFolder?.folder
    );
    setLoader(false);
    setImageList(data);
  };

  useEffect(() => {
    getAllImages();
  }, []);
  
  
  return (
    <>
      <div className="registerFormContainer">
        <p className="formHeading">Firmware Images</p>
        {loader ? (
          <div className="w-full flex items-center justify-center">
            <ClipLoader
              color={"#2047ab"}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : imageList?.length !== 0 ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-5 mb-5">
            {imageList?.map((item: any, index: any) => {
           
              return (
                <div
                  key={index}
                  className="firmware_image_container"
                  onClick={(e: any) => openModal("firmwareModal", item, e)}
                >
                  <img
                    src={item?.imagePath}
                    alt={item?.image}
                    className="w-full h-full"
                  />
                  <p>{item?.image.split('.')[0]}</p>
                </div>
              );
            })}
          </div>
        ) : (
          <p className="sensorName text-center mt-6">No Data Found</p>
        )}
      </div>
    </>
  );
}
