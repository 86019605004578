import { useSelector } from "react-redux";
import SensorMap from "../map/SensorMap";

export default function SensorLocationModal() {
  const { selectedSensor } = useSelector((store: any) => store.sensor);
  return (
    <>
      <div className="w-full h-80	">
        <SensorMap sensorData={selectedSensor} />
      </div>
    </>
  );
}
