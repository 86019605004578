import { PiDevicesFill } from "react-icons/pi";
import { BiSolidUser } from "react-icons/bi";
import component from "../../constants/urls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { device } from "../../interface/reducer/device";

export default function DashboardCountCards({
  setSelectedState,
  selectedState,
}: any) {
  const navigate = useNavigate();
  const { devicesList } = useSelector((store: any) => store.device);

  const { userInfo } = useSelector((store: any) => store.user);

  const handleRoute = (url: string) => {
    navigate(url);
  };

  const data = [
    {
      title: "Total Devices",
      count:
        userInfo?.role === "Super Admin"
          ? devicesList?.length
          : devicesList?.filter(
              (device: device) => device?.status !== "ARCHIVED"
            )?.length,
      color: "#2047AB",
      id: "201",
      key: "all",
    },
    {
      title: "Active Devices",
      count:
        userInfo?.role === "Super Admin"
          ? devicesList?.filter((device: device) => device.active)?.length
          : devicesList?.filter(
              (device: device) => device.active && device?.status !== "ARCHIVED"
            )?.length,
      color: "#79cfa6",
      id: "202",
      key: "active",
    },
    {
      title: "Inactive Devices",
      count:
        userInfo?.role === "Super Admin"
          ? devicesList?.filter((device: device) => !device.active)?.length
          : devicesList?.filter(
              (device: device) =>
                !device.active && device?.status !== "ARCHIVED"
            )?.length,
      color: "#ECAE0F",
      id: "203",
      key: "inactive",
    },
    {
      title: "Alert Devices",
      count:
        userInfo?.role === "Super Admin"
          ? devicesList?.filter((device: device) => device.alerting)?.length
          : devicesList?.filter(
              (device: device) =>
                device.alerting && device?.status !== "ARCHIVED"
            )?.length,
      color: "#ee6e6c",
      id: "204",
      key: "alert",
    },
  ];
  return (
    <>
      <div className="grid grid-cols-2 xl:grid-cols-4 gap-5 cursor-pointer">
        {data?.map((item) => (
          <div
            style={{ border: item?.key === selectedState && "1px solid #000" }}
            key={item.id}
            className="countCardContainer cardBoxShadow items-center"
            onClick={() => setSelectedState(item.key)}
          >
            <PiDevicesFill fontSize={30} color={item.color} />
            <div className="flex items-center gap-2">
              <p className="count-text">{item.count}</p>
              <p className="device-category">{item?.title}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
