import { useDispatch } from "react-redux";
import {
  DeleteFirmwareImagesService,
  GetAllFirmwareImagesService,
  GetFirmWareDataService,
  GetFirmwareFolderImagesService,
  GetFirmwareFolderService,
  GetImageDetailByIdService,
  PostFirmwareImageService,
  UpdateFirmwareDataService,
} from "../../service/firmware";
import {
  UpdateFirmwareData,
  UpdateFirmwareDataList,
  UpdateFirmwareErrorMessage,
  UpdateFirmwareSuccessMessage,
  firmwareData,
} from "../../redux/reducer/firmware";
import swal from "sweetalert";

const useFirmware = () => {
  const dispatch = useDispatch();

  const GetFirmwareServiceHandler = async () => {
    try {
      const res = await GetFirmWareDataService();
      const { result } = res.data;
      if (result) {
        const { id, firmware_version } = result;
        dispatch(UpdateFirmwareDataList({ id, firmware_version }));
      }
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  const UpdateFirmwareDataServiceHandler = async (
    id: string,
    firmware: string
  ) => {
    try {
      const data = {
        firmware_version: firmware,
      };
      await UpdateFirmwareDataService(id, data);
      dispatch(UpdateFirmwareSuccessMessage("Firmware Updated Successfully!."));
      GetFirmwareServiceHandler();
      setTimeout(() => {
        dispatch(UpdateFirmwareSuccessMessage(""));
      }, 2000);
    } catch (error) {
      dispatch(
        UpdateFirmwareErrorMessage(error?.response?.data?.error?.message)
      );
      setTimeout(() => {
        dispatch(UpdateFirmwareErrorMessage(""));
      }, 2000);
    }
  };

  const GetFirmwareFolderListServiceHandler = async () => {
    try {
      const res = await GetFirmwareFolderService();
      const { result } = res.data;
      return result;
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  const GetFirmwareFolderImagesServiceHandler = async (folderName: string) => {
    try {
      const res = await GetFirmwareFolderImagesService(folderName);
      const { result } = res?.data;
      console.log('result',result);
      
      return result;
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  const PostFirmwareImageServiceHandler = async(formdata : any) => {
    try {
      const res = await PostFirmwareImageService(formdata);
      const {result} = res?.data;
      
      
      if(result) {
        swal({
          icon: 'success',
          title: 'Firmware Image Uploaded Successfully'
        })
      }
      
    } catch (error : any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  }
  const GetAllFirmwareImagesServiceHandler = async () => {
    try {
      const res = await GetAllFirmwareImagesService();
      const { data } = res?.data.result;
      return data;
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };
  const DeleteFirmwareImagesServiceHandler = async (imageId: string) => {
    try {
       
      const res = await DeleteFirmwareImagesService(imageId);
      const { result } = res?.data; 
      if(result) {
        swal({
          icon: 'success',
          title: result.message
        })
      }
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };



  const GetImageDetailByIdServiceHandler = async (
    folderName: string,
    fileName: string
  ) => {
    try {
      const res = await GetImageDetailByIdService(folderName, fileName);
      const { result } = res?.data;
      return result;
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  return {
    GetFirmwareServiceHandler,
    UpdateFirmwareDataServiceHandler,
    GetFirmwareFolderListServiceHandler,
    GetFirmwareFolderImagesServiceHandler,
    GetImageDetailByIdServiceHandler,
    GetAllFirmwareImagesServiceHandler,
    PostFirmwareImageServiceHandler,
    DeleteFirmwareImagesServiceHandler
  };
};
export default useFirmware;
