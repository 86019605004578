import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import useDevicesServiceHandler from "../../hooks/serviceHandler/devices";
import { useDispatch, useSelector } from "react-redux";
import { device } from "../../interface/reducer/device";
import { BsTrash, BsFillExclamationTriangleFill } from "react-icons/bs";
import { BiSolidEdit } from "react-icons/bi";
import useUserManagementServiceHandler from "../../hooks/serviceHandler/userManagement";
import { userManegementList } from "../../interface/reducer/userManagementList";
import { useNavigate } from "react-router-dom";
import component from "../../constants/urls";
import noRecordFound from "../../assets/images/no-record-found.png";
import { IconButton, Tooltip } from "@mui/material";
import {
  UpdateEditNewUserManagementInfo,
  UpdateNewUserManagementInfo,
} from "../../redux/reducer/userManagement";
import { ClipLoader } from "react-spinners";
import useRoleServiceHandler from "../../hooks/serviceHandler/roles";
import ModalComponent from "../common/modal/Modal";
import AssociationModal from "../common/modal/AsociationModal";
import {
  UpdateModalName,
  UpdateModalOpenStatus,
} from "../../redux/reducer/commonReducer";

interface Column {
  id: "S. No." | "Name" | "E-mail" | "Phone Number" | "Role" | "Action";
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "S. No.", label: "S. No.", align: "right" },
  { id: "Name", label: " Name", align: "right" },
  { id: "E-mail", label: "E-mail", align: "right" },
  { id: "Phone Number", label: "Phone Number", align: "right" },
  { id: "Role", label: "Role", align: "right" },
  { id: "Action", label: "Action", align: "right" },
];

export default function UserTable() {
  const { GetUserListServiceHandler, DeleteUserServiceHandler } =
    useUserManagementServiceHandler();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoader, setIsLoader] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userManagementList } = useSelector(
    (state: any) => state.userManagement
  );

  const { userInfo } = useSelector((state: any) => state.user);
  const { modalIsOpen, modalName } = useSelector((store: any) => store.common);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleUserDelete = async (row: userManegementList) => {
    await DeleteUserServiceHandler(row?.id);
  };
  const { GetAllRoleListServiceHandler } = useRoleServiceHandler();
  const { GetUserInfoByIdServiceHandler } = useUserManagementServiceHandler();
  const [item, setItem] = React.useState({});

  const handleEditBtnClick = (row: any) => {
    const {
      name,
      email,
      phone,
      role,
      sendEmail,
      sendNotification,
      sendSMS,
      createdBy,
      assignedDevices,
      id,
    } = row;
    const editedUserInfo = {
      name,
      email,
      phone,
      role,
      sendEmail,
      sendNotification,
      sendSMS,
      createdBy,
      assignedDevices,
      id,
    };
    dispatch(UpdateEditNewUserManagementInfo({ ...editedUserInfo }));

    navigate(component.createUser.url);
  };

  const UserData = async () => {
    setIsLoader(true);
    await GetUserListServiceHandler(userInfo?.id);
    setIsLoader(false);
  };

  React.useEffect(() => {
    UserData();
    GetAllRoleListServiceHandler();
  }, []);
  const openDetailModal = async (modalName: string, item: any) => {
    await GetUserInfoByIdServiceHandler(item.createdBy);
    setItem(item);
    dispatch(UpdateModalName(modalName));
    dispatch(UpdateModalOpenStatus(true));
  };

  return (
    <>
      {modalIsOpen && modalName === "userAssociationDetail" && (
        <ModalComponent heading="User Association Detail">
          <AssociationModal />
        </ModalComponent>
      )}
      {isLoader ? (
        <div className="w-full flex items-center justify-center">
          <ClipLoader
            color={"#2047ab"}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : userManagementList?.length ? (
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
          <TableContainer sx={{ minHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      // align={column.align}
                      style={{
                        backgroundColor: "#2047AB",
                        borderBottom: "none",
                      }}
                    >
                      <p className="tableText" style={{ color: "#ffffff" }}>
                        {column.label}
                      </p>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userManagementList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: userManegementList, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <p className="userTableData">{index + 1}</p>
                        </TableCell>
                        <TableCell
                          // onClick={() =>
                          //   openDetailModal("userAssociationDetail", row)
                          // }
                          align="left"
                        >
                          <p
                            className="userTableData"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.name}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="userTableData">{row.email}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p className="userTableData">
                            {row.phone ? row.phone : "NA"}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <p
                            className="userTableData"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.role}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <div className="flex justify-start items-center gap-3">
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() => handleEditBtnClick(row)}
                              >
                                <BiSolidEdit
                                  fontSize={20}
                                  color="#4D535A"
                                  className="cursor-pointer"
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton onClick={() => handleUserDelete(row)}>
                                <BsTrash
                                  fontSize={20}
                                  color="#ff0d0b"
                                  className="cursor-pointer"
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={userManagementList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <div className="no_record_found_image">
          <img src={noRecordFound} alt="" />
        </div>
      )}
    </>
  );
}
