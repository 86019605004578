import { useDispatch, useSelector } from "react-redux";
import {
  ChangeNotificationStatusService,
  DeleteNotificationService,
  GetNotificationAllDataService,
} from "../../service/notification";
import {
  UpdateNotificationData,
  UpdateNotificationUnreadCount,
} from "../../redux/reducer/notification";
import swal from "sweetalert";

const useNotificationServiceHandler = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store: any) => store.user);

  const GetAllNotificationDataServiceHandler = async () => {
    try {
      const res = await GetNotificationAllDataService(userInfo.id);
      const { rows, count } = res?.data?.result;
      dispatch(UpdateNotificationUnreadCount(count));
      if (rows) {
        dispatch(UpdateNotificationData(rows));
      } else {
        dispatch(UpdateNotificationData([]));
      }
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };
  const DeleteNotificationServiceHandler = async (id: string) => {
    try {
      await DeleteNotificationService(id);
      await GetAllNotificationDataServiceHandler();
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };
  const UpdateNotificationStatusSeviceHandler = async (id: string) => {
    try {
      await ChangeNotificationStatusService(id);
      await GetAllNotificationDataServiceHandler();
    } catch (error: any) {
      swal({
        icon: "error",
        title: `${error?.response?.data?.error?.message}`,
        text: `${
          error?.response?.data?.error?.message === "Invalid Token" &&
          "Token is Expired please login again"
        }`,
      });
    }
  };

  return {
    GetAllNotificationDataServiceHandler,
    DeleteNotificationServiceHandler,
    UpdateNotificationStatusSeviceHandler,
  };
};

export default useNotificationServiceHandler;
